import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SearchCard } from 'modules/searchcard';
const CEU = "CEU";


export default class EventCard extends PureComponent {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    eventData: PropTypes.object.isRequired,
    learnMoreLabel: PropTypes.object,
    formattedRange: PropTypes.string,
    event: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.ceu = false;

    this.props.eventData.tags.map((tag) => {
      if (tag.toUpperCase() === CEU) {
        this.ceu = true;
      }
    });

    this.renderDates = this.renderDates.bind(this);
  }

  renderDates() {
    const { startDate, endDate, formattedRange } = this.props;

    if (formattedRange) {
        return <p>{formattedRange}</p>
    }
    
    if (startDate === endDate) {
      return <p>{startDate}</p>
    }

    return <p>{startDate} - {endDate}</p>
  }

  openLinkNewWindow(url){
    window.open(url, "_blank")
  }

  render() {
    const { eventData, learnMoreLabel } = this.props;
    const { event } = this.props;
    const props = {
        ...this.props,
        ...event,
        event: event,
        locations: eventData.location,
        tabletBreak: (this.props.tabletBreak !== undefined) ? this.props.tabletBreak : true,
        hideDescription: true,
    }
    return (
        <SearchCard {...props} />
    )
  }
}
