//Internal
import { getSitecoreApiHost, getSitecoreApiKey } from '../../../AppGlobals';
import { Environment } from '../../../Environment';
import UserUtility from 'utils/userutility';

// Local
import { MarketInterface } from '../reducers/interface';
import MarketAPIClass from '../api/markets';
import userId from './localUserId';
import { toggleDrawerState } from './toggleDrawer';
import { summarizeActivity } from '../helpers/helpers';
import { analytics } from '../helpers/analytics';
import { handleError } from '../helpers/errorHandlers';

let MarketAPI = new MarketAPIClass();

const fetchMarketSuccess = (market: MarketInterface) => ({
    type: 'FETCH_MARKET_SUCCESS',
    plan: {
        market
    }
});

const fetchMarketError = (error: any) => ({
    type: 'FETCH_MARKET_ERROR',
    error
});

const fetchPlanLoading = () => ({
    type: 'FETCH_PLAN_LOADING'
});

const fetchPlanSuccess = (plan: any) => ({
    type: 'FETCH_PLAN_SUCCESS',
    plan: {
        ...plan,
        isRegistered: true,
        loaded: true
    }
});

const fetchPlanError = (error: any) => ({
    type: 'FETCH_PLAN_ERROR',
    loaded: true,
    error
});

const setNotRegistered = () => ({
    type: 'NOT_REGISTERED_SUCCESS',
});

export const fetchPlan = (marketId: number, dispatch: any, callback?: Function) => {
    dispatch(fetchPlanLoading())
    fetch(`${getSitecoreApiHost()}/imc-api/market-planner/v1/market/${marketId}?sc_apikey=${getSitecoreApiKey()}${Environment.isHot ? `&contactAltId=${userId}` : ``}`)
    .then(response => {
        if (!response.ok) {
            handleError(
                response,
                (Environment.isHot) ? () => dispatch(toggleDrawerState(true)) : () => {
                    if (localStorage?.getItem('isAuthenticated') === 'true') {
                        localStorage.setItem('isAuthenticated', 'signingIn')
                        UserUtility.redirectToLogin()
                    } else {
                        localStorage.setItem('isAuthenticated', 'false')
                        window.location.reload();
                    }
                }
            )
            if (response.status === 404 || response.status === 500) {
                console.log(`Setting Not Registered`);
                dispatch(setNotRegistered());
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    })
    .then(data => {
        let state = data[0];
        const plan = {
            ...state,
            activitySummary: summarizeActivity(state.showrooms),
            loading: [],
        };
        // the business does not want to use the displayName anymore :|
        if (plan?.market?.name) plan.market.displayName = plan.market.name;
        console.log(`Plan fetched:`, plan);
        dispatch(fetchPlanSuccess(plan));
        if (callback) callback(state.contactAltId);
    })
    .catch(error => {
        console.log('Dispatching Error:', error);
        dispatch(fetchPlanError(error));
    });
}

export const fetchMarketPlan = (channel: string) => {
    return async (dispatch: any) => {
        try {
            MarketAPI.getCurrentMarket(channel)
                .then((market: MarketInterface) => {
                    dispatch(fetchMarketSuccess({
                        marketId: market.marketId,
                        displayName: market.marketName || market.displayName,
                        channelName: channel,
                        from: market.startDate || market.from,
                        to: market.endDate || market.to,
                    }));
                    fetchPlan(market.marketId, dispatch, (contact_alt_id: string) => {
                        analytics().track('Plan + Plan Loaded', {
                            contact_alt_id: contact_alt_id,
                            market_id: `${market.marketId}`,
                            market_name: market.displayName,
                        });
                    });
                })
                .catch(error => {
                    dispatch(fetchPlanError(error));
                    console.error('Error:', error);
                });
        } catch (error) {
            dispatch(fetchPlanError(error));
            console.log(`Error fetching plan: ${error}`);
        }
    };
};

export const fetchMarket = (channel: string) => {
    return async (dispatch: any) => {
        try {
            MarketAPI.getCurrentMarket(channel)
                .then((market: MarketInterface) => {
                    dispatch(fetchMarketSuccess({
                        marketId: market.marketId,
                        displayName: market.marketName || market.displayName,
                        channelName: channel,
                        from: market.startDate || market.from,
                        to: market.endDate || market.to,
                    }));
                })
                .catch(error => {
                    dispatch(fetchMarketError(error));
                    console.error('Error:', error);
                });
        } catch (error) {
            dispatch(fetchMarketError(error));
            console.log(`Error fetching plan: ${error}`);
        }
    };
}