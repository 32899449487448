import { isIOS, isAndroid } from 'react-device-detect';

export function capitalizeEveryWord(text: string): string {
    if (!text) return text; // Handle empty string or null
    const newText = text.toLocaleLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return newText;
}
export interface NavLink {
    field?: any;
    title: string;
    links?: NavLink[];
    dates?: string;
    description?: string;
    link?: string;
}

export interface Menu {
    title: string;
    links: NavLink[];
    menus?: Menu[];
}

export interface NavigationMenu {
    title?: string;
    menus: Menu[];
    links: NavLink[];

}

export interface MobileNavigationMenu {
    title: string;
    menus: NavigationMenu[];
    signIn?: NavLink;
    signOut?: NavLink;
    userLinks?: NavLink[];
    subItemsOnMobile?: boolean;
}

export function buildNavigationMenu(fields: any, rendering: any, isLoggedIn: boolean): MobileNavigationMenu {

    const mobileMenu: MobileNavigationMenu = {
        title: 'ANDMORE Market',
        menus: [],
    };

    let desktopNav = null;
    if (rendering && rendering.placeholders && rendering.placeholders['jss-top-header-bottom']) {
        desktopNav = rendering.placeholders['jss-top-header-bottom'].filter(function(item?: any) {
            return item.componentName === 'Header-Navigation-Component';
        })[0];
    }
    
    let companyNav = null;
    if (rendering && rendering.placeholders && rendering.placeholders['jss-top-header-top']) {
        let globalHeaderContainer = rendering.placeholders['jss-top-header-top'].filter(function(item?: any) {
            return item.componentName === 'Global-Header-Container';
        })[0];
    
        if (globalHeaderContainer && globalHeaderContainer.placeholders && globalHeaderContainer.placeholders['global-header--top-left']) {
            companyNav = globalHeaderContainer.placeholders['global-header--top-left'].filter(function(item?: any) {
                return item.componentName === 'Company-Navigation';
            })[0];
        }
    }

    let marketPlanNav = null;
    if (rendering && rendering.placeholders && rendering.placeholders['jss-top-header-top']) {
        let globalHeaderContainer = rendering.placeholders['jss-top-header-top'].filter(function(item?: any) {
            return item.componentName === 'Global-Header-Container';
        })[0];
    
        if (globalHeaderContainer && globalHeaderContainer.placeholders && globalHeaderContainer.placeholders['global-header--top-right']) {
            marketPlanNav = globalHeaderContainer.placeholders['global-header--top-right'].filter(function(item?: any) {
                return item.componentName === 'Market-Plan-Navigation';
            })[0];
        }
    }


    if (desktopNav) {
        if (desktopNav?.fields?.data?.model?.title?.data?.value) mobileMenu.title = desktopNav.fields.data.model.title.data.value;
        if (desktopNav?.fields?.data?.model?.subItemsOnMobile?.data?.value) mobileMenu.subItemsOnMobile = desktopNav.fields.data.model.subItemsOnMobile.data.value;
        mobileMenu.menus.push({
            menus: desktopNav.fields.data.model.navigation1st.map((outer: any, o: number) => {
                let lastValidSecondLevelItem: any = null;
                return {
                    title: outer.title.data.value,
                    links: outer.navigationColumn.map((column: any, c: number) => {
        
                        const processedSecondLevel = column.navigation2st.map((item: any, i: number) => {
                            const hasTitle = item.link.data.value.text?.trim();
                            const secondLevelItem = {
                                title: hasTitle ? item.link.data.value.text : null,
                                field: hasTitle ? item.link.data : null,
                                links: !mobileMenu.subItemsOnMobile ? [] : item.navigation3st?.map((subItem: any, s: number) => {
                                    return {
                                        title: subItem.link.data.value.text,
                                        field: subItem.link.data
                                    };
                                }).filter((subItem: any) => subItem?.title?.trim() && (subItem?.field?.value?.url || (subItem?.field?.value?.href && subItem?.field?.value?.href !== 'http://'))) || []
                            };
        
                            // If it has a title, update the last valid item tracker
                            if (hasTitle) {
                                lastValidSecondLevelItem = secondLevelItem;
                            } else if (lastValidSecondLevelItem) {
                                // Add third-level items to the last valid second-level item
                                if (mobileMenu.subItemsOnMobile) lastValidSecondLevelItem.links = lastValidSecondLevelItem.links.concat(secondLevelItem.links);
                            }
        
                            return hasTitle ? secondLevelItem : null; // Only return items with titles
                        }).filter((item: any) => item !== null); // Remove nulls (items without titles)
        
                        return processedSecondLevel;
                    }).reduce((acc: any, val: any) => acc.concat(val), []), // Flatten the array
                };
            }),
            links: [],
        });

        if (marketPlanNav) {
            if (marketPlanNav?.fields?.data?.model?.menus?.length > 0) {
                marketPlanNav.fields.data.model.menus.forEach((menu: any, m: number) => {
                    if (menu?.items?.length > 0) {
                        menu.items.forEach((item: any) => {
                            if (item?.type?.name == "Registration Link") {
                                mobileMenu.menus[0].links.push({
                                    title: item?.heading?.data?.value,
                                    field: item.link.data
                                });
                            }
                            if (item?.type?.name == "Sign In") {
                                mobileMenu.signIn = {
                                    title: item?.heading?.data?.value,
                                    field: item.link.data
                                };
                            }
                            if (item?.type?.name == "Sign Out") {
                                mobileMenu.signOut = {
                                    title: item?.heading?.data?.value,
                                    field: item.link.data
                                };
                            }
                            if (item?.type?.name == "User Link") {
                                let add = true;
                                if (!mobileMenu.userLinks) mobileMenu.userLinks = [];
                                if ((item?.link?.data?.value?.href?.includes('apple') && !isIOS) || (item?.link?.data?.value?.href?.includes('google') && !isAndroid)) {
                                    add = false;
                                }
                                if (isLoggedIn && item?.hideLoggedIn?.data?.value) add = false;
                                if (!isLoggedIn && item?.hideLoggedOut?.data?.value) add = false;
                                if (add) mobileMenu.userLinks.push({
                                    title: item?.heading?.data?.value,
                                    field: item.link.data
                                });
                            }
                        })
                    }
                });
            }
            
        }
    }

    if (companyNav) {
        mobileMenu.menus.push(
            {
                title: 'Markets',
                menus: companyNav.fields.data.model.cities.map((campus: any, c: number) => {
                    return {
                        title: capitalizeEveryWord(campus.heading.data.value),
                        menus: campus.columns.map((column: any, c: number) => {
                            return column.groups.map((group: any, g: number) => {
                                return {
                                    title: group.heading.data.value,
                                    links: group.items.map((item: any, i: number) => {
                                        return {
                                            title: item.heading.data.value,
                                            url: item.link.data.value.href,
                                            field: item.link.data,
                                            dates: (item.subheading?.data?.value) ? item.subheading.data.value : undefined,
                                            description: (item.description?.data?.value) ? item.description.data.value : undefined,
                                            link: (c == 0 ) ? 'Visit' : 'Learn More'
                                        }
                                    })
                                }
                            });
                        }).reduce((acc: any, val: any) => acc.concat(val), []),
                    }
                }),
                links: [],
            }
        );
    }


    return mobileMenu;
}
