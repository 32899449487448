import React, { Component } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import { createAppState } from 'modules/redux-app-state';
// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getDictionaryValue } from 'utils/dictionary';
// action imports
import * as dataActions from './actions/dataActions';
//reducers
import exhibitorSpecialsReducer from './reducers/exhibitorSpecialsReducer';
//Utils
import { exhibitorIsLine } from "utils/exhibitor";
import UserUtility from "utils/userutility";
import Link from "../Link";
/**
 * @property defaultProps
 * @type {{products: *, filters: *, selectedTab: *, resultsPerPage: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, productsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
    specials: PropTypes.array.isRequired,
    selectedTab: PropTypes.object,
    resultsPerPage: PropTypes.number.isRequired,
    pagenum: PropTypes.number.isRequired,
    //updateUrl: PropTypes.func.isRequired,
    dataActions: PropTypes.object,
    actions: PropTypes.object,
    spacialsCount: PropTypes.number,
    exhibitorLabels: PropTypes.object,
    filterErr: PropTypes.number,
    exhibitorName: PropTypes.string,
    filterKey: PropTypes.string,
    savedContent: PropTypes.object,
    location: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, products: {}, pagenum: number, resultsPerPage: number, filters: {},
 * dataActions: {fetchProducts: defaultProps.dataActions.fetchProducts},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, productsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
    lineDetail: false,
    pagenum: 1,
    resultsPerPage: 15,
    filters: {},
    dataActions: {
        fetchExhibitorSpecials: () => { },
    },
    productsCount: 0,
    exhibitorLabels: {
        specialsCount: 'Showing $1 Specials',
        specialQueryPrefix: 'for',
        seeAllSpecials: 'Click here to see all Specials',
    },
    filterErr: null,
    globalLabels: {
        pimCatalogImageUrl: 's3.amazonaws.com',
    },
    exhibitorName: '',
    filterKey: 'specials',
    savedContent: {},
    location: {},
};

//appState
const SpecialsState = createAppState();

/**
 * TabMyLists component
 * @constructor
 */
class DigitalShowroomSpecials extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        // const queryObj = qs.parse(this.props.router.location.search);
        this.state = {
            errors: [],
            carouselKey: 0,
            pageNum: 1,
            loading: false,
            multiSelectMode: false,
        }
        SpecialsState.reducerRegistry.register({ exhibitorSpecialsReducer });
        this.createSpecialElements = this.createSpecialElements.bind(this);
        this.renderSpecials = this.renderSpecials.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
        const { sitecoreContext } = this.props;
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
            this.exhibitId = sitecoreContext.exhibitor.id;
        }
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
            this.lineId = sitecoreContext.lineProduct.lineId;
        }
    }

    componentDidMount()
    {
        this.props.dataActions.fetchSpecials(this.exhibitId);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState !== this.state){
            this.props.dataActions.fetchSpecials(this.exhibitId);
        }
    }
        
    /**
     * creates individual line elements: TBD : move to a separate file
     * @param {object} special single line element
     * @returns {*} Rendered component
     */
    createSpecialElements(special) {
        const {  savedContent } = this.props;
        const lineDetailUrl = '/exhibitor/$1/line/$2';

        const lineDetail = exhibitorIsLine(special);
        const _url = (!!special.lineId) ?
            `${lineDetailUrl}`.replace('$1', special.exhibitorId).replace('$2', special.lineId)
                .replace('$3', special.uniqueId) : '';

        const mainType = lineDetail ? 'line' : 'exhibitor';
        const itemId = lineDetail ? special.lineId : special.exhibitorId;
        return (
            <div class="alternateRow">
                <div class="imc-specials-list--lineName">
                { special.lineName &&
                    <Link href={_url} className={"imc-link imc-type--title-6 imc-content--inline-block"}>{special.lineName}</Link>
                }
                { !special.lineName &&
                    <>{special.exhibitorName}</>
                }
                </div>
                <div class="imc-specials-list--description">{special.description}</div>
            </div>

        );
    }

    /**
     * @method constructor
     * @param {array} specials Incoming props
     * @returns {*} Components
     */
    renderSpecials(specials) {
        return (Object.keys(specials).length) ? Object.keys(specials).map(
            index => (
                this.createSpecialElements(specials[index])
            ),
        ) : '';
    }

    /**
     * No results content
     * @returns {*} JSX for no results
     */
    renderNoResults() {
        if (typeof window === 'undefined' || (typeof window != 'undefined' && !window.Sitecore) || !("currentTab" in this.props))
            return <></>;
        const label = (this?.props?.fields?.clickToProducts?.value) ? this.props.fields.clickToProducts.value : 'Click here to see all products';
        let query = '';
        if (typeof window != 'undefined' || (typeof window != 'undefined' && !window.Sitecore)) {
            query = qs.parse(window.location.search).q;
        }
        const type = (this.props.currentTab && this.props.currentTab.type) ? this.props.currentTab.type.value : '';
        return (
            <div className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
            imc-searchresults`}>
                <div className="imc-vr--large imc-content imc-stacked">
                    There are no matching search results
                    {(query !== '' && query !== undefined) ?
                        <span> for <strong>&quot;{query}&quot;</strong></span> : null
                    }
                </div>
                <div className="imc-vr--large imc-content imc-stacked">
                    <a
                        href={`/exhibitor/${this.exhibitId}/${type.toLowerCase()}`}
                        className="imc-link imc-link--alt-darkred imc-link--caret-after">
                        {label}
                    </a>
                </div>
            </div>
        );
    }

    /**
     * Renders the product results
     * @returns {*} Product results JSX
     */
    renderResults(specials) {
        const {
            tabData,
            sitecoreContext
        } = this.props;
        const specialsCount = this.props.count;
        const numberOfPages =  Math.ceil(parseInt(specialsCount,10) / this.props.currentTab.size.value);
        if (Object.keys(specials).length) {
            return (
                <div
                    className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                    imc-searchresults`}
                >
                    <div
                        className={`imc-gallery imc-gallery--align-flex-start imc-gallery--1-4
                        imc-fprod-cont imc-specials-list`}
                    >
                        {specials && specials.length>0 && specials[0].list ? this.renderSpecials(specials[0].list) : []}
                    </div>
                    <Pagination totalPages={numberOfPages} nonSearch />
                </div>
            );
        }
        return this.renderNoResults();
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            specials,
        } = this.props;
        return (
               <div >
                    <div className="imc-specials-list--header imc-type--body-4-ui imc-content--border-color-neutral-medium">
                        <div class="imc-specials-list--lineName">{getDictionaryValue("specialsTab.lineName", "Line Name")}</div>
                        <div class="imc-specials-list--description">{getDictionaryValue("specialsTab.listDescription", "Show Special Details")}
                            <div class="imc-specials-list--allspecials">
                                <Link href={getDictionaryValue("specialsTab.allSpecialsUrl", "/exhibitor/show-specials")} className={"imc-link imc-type--body-4-link imc-content--inline-block"}>{getDictionaryValue("specialsTab.allmarketspecials", "Go to All Market Show Specials")}</Link>
                            </div>
                        </div>
                    </div>
                    {specials &&
                        this.renderResults(specials)
                    }
                </div>

        );
    }
}


/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
    };
}

function mapStateToProps(state) {

    if (state.exhibitorSpecialsReducer)
      return {
        specials: state.exhibitorSpecialsReducer.specials,
        count: state.exhibitorSpecialsReducer.count,
        showLoading: state.exhibitorSpecialsReducer.showLoading,
        error: state.exhibitorSpecialsReducer.errCode,
      }
    else {
      return {
        specials: state.resultsUpToDate,
        count: state.count,
        showLoading: state.showLoading,
        error: state.errCode,
      };
    }
  }


DigitalShowroomSpecials.propTypes = propTypes;
DigitalShowroomSpecials.defaultProps = defaultProps;

// Export the react component
export default withSitecoreContext()(
    withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomSpecials))
);
