
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {newComponent, returnHeading, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'
import ContentSectionComponent from '../Content-Section-Component';

interface HeroFloatingImagesProps {
    fields?: any;
}

const HeroFloatingImages: React.FC<HeroFloatingImagesProps> = ({fields}) => {
    let backgroundSection = contentSectionComponentRendering();
    let gridWrapper = flexGridRendering();
    let contentSection = contentColumnSectionRendering(fields);
    let parenthesisColor = (fields?.accentColor?.value) ? fields.accentColor.value : "primary-medium";
    let leftImage = returnMediaSection(fields.leftImage, fields.videoFile, {}, fields.primaryCTA, fields.linkImage, fields.secondaryCTA, fields.linkImageSecondary, fields?.mute); 
    let rightImage = returnMediaSection(fields.rightImage, fields.videoFile, {}, fields.primaryCTA, fields.linkImage, fields.secondaryCTA, fields.linkImageSecondary, fields?.mute); 
    

    // ------------------------ //
    // build  content   section //
    // ------------------------ //
    fields.headingExtraClasses.unshift( {
        fields: {
            className: {
                value: "HEADING-1 andmore-width-desktop-67 andmore-width-tablet-67 andmore-width-mobile-100"
            }
        }
    });
    fields.secondaryHeadingExtraClasses.unshift( {
        fields: {
            className: {
                value: "HEADING-2 andmore-width-desktop-67 andmore-width-tablet-67 andmore-width-mobile-100"
            }
        }
    });
    contentSection = contentColumnSectionRendering(fields);
    contentSection.fields.extraClasses = {
        value: "CONTENT inner-center"
    }
    // ------------------------ //

    // ------------------------ //
    // build background section //
    // ------------------------ //
    backgroundSection.fields.backgroundColor = newComponent(fields.backgroundColor);
    backgroundSection.fields.backgroundImage = newComponent(fields.image);
    backgroundSection.fields.styles = {}
    if (fields?.videoFile?.value?.href) {
        backgroundSection.fields.videoFile = {
            value: {
                src: fields.videoFile.value.href,
                description: fields.videoFile.value.description
            }
        };
    }
    if (fields?.backgroundColor?.value || fields?.backgroundOpacity?.value || fields?.backgroundOverride?.value) {
        backgroundSection.fields.backgroundColor.value += ' imc-hero__overlay';
        if (fields?.backgroundColor?.value) {
            backgroundSection.fields.backgroundColor.value += ` imc-hero__overlay--${fields.backgroundColor.value}`;
        }
        if (fields?.backgroundOpacity?.value) {
            backgroundSection.fields.styles["--opacity"] = `${fields.backgroundOpacity.value}%`;
        }
        if (fields?.backgroundOverride?.value) {
            backgroundSection.fields.styles["--overlay"] = `${fields.backgroundOverride.value}`;
        }
    }
    if (fields.minHeight?.length > 0) {
        fields.minHeight.forEach((height?: any) => {
            if (height.fields?.className?.value) {
                if (height.fields.className.value.includes("CLASS-")) {
                    backgroundSection.fields.backgroundColor.value += ` imc-hero__min-height${height.fields.className.value.split("CLASS")[1]}`;
                } else {
                    backgroundSection.fields.styles["minHeight"] = `${height.fields.className.value}`;
                }
            }
        });
    }
    backgroundSection.fields.backgroundColor.value += ` am-hero-floating`;
    backgroundSection.fields.paddingTop.value = 'jumbo';
    backgroundSection.fields.paddingBottom.value = 'jumbo';
    backgroundSection.fields.paddingLeft.value = 'xlarge';
    backgroundSection.fields.paddingRight.value = 'xlarge';
    backgroundSection.fields.backgroundColor.value += ` am-hero`;
    backgroundSection.fields.extraClass.fields.className.value += " CONTENT inner-center";
    // ------------------------ //

    // ------------------------ //
    // -- build grid wrapper -- //
    // ------------------------ //
    gridWrapper.params = {
        "columnCount": "{\"className\":{\"value\":\"3\"}}",
        "totalColumns": "{\"className\":{\"value\":\"3\"}}",
        "padding": "{\"className\":{\"value\":\"imc-gallery--large-padded\"}}",
        "horizontalAlignment": "{\"className\":{\"value\":\"imc-gallery--justify-space-evenly\"}}",
        "verticalAlignment": "{\"className\":{\"value\":\"imc-gallery--align-flex-center\"}}",
        "mobileColumnCount": "{\"className\":{\"value\":\"1\"}}",
        "tabletColumnCount": "{\"className\":{\"value\":\"3\"}}",
        "extraClasses": "{\"classes\": [{\"className\":{\"value\":\" am-grow-2\"}},{\"className\":{\"value\":\" am-mobile-first-last-first-50-50\"}}]}"
    };
    // ------------------------ //

    gridWrapper.placeholders['imc-grid-column-one-placeholder'].push(leftImage);
    gridWrapper.placeholders['imc-grid-column-two-placeholder'].push(contentSection);
    gridWrapper.placeholders['imc-grid-column-three-placeholder'].push(rightImage);
    backgroundSection.placeholders['imc-jss-content-section'].push(gridWrapper);

    return (
        <ContentSectionComponent {...backgroundSection} rendering={backgroundSection} />
    
    );
};

export default HeroFloatingImages;
