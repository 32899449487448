// Third-Party
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {withSitecoreRouter } from "utils/withRouter"
import { withSitecoreContext,  } from '@sitecore-jss/sitecore-jss-react';
import qs from 'query-string';
import { encode } from 'utils/querystring';
import lodash from 'lodash';

// Internal
import { Environment } from '../../Environment';
import { showMarketPlan } from 'utils/general';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import TrackUserActivity from 'modules/trackUserActivity';

// Components
import { fetchMarket, fetchPlan, fetchMarketPlan, addToPlan, removeFromPlan, toggleDrawerState } from '../../modules/marketplan/actions/actions';
import { compareShowrooms, clearParsed } from '../../modules/marketplan/helpers/helpers';
import { analytics } from '../../modules/marketplan/helpers/analytics';
import Link from '../Link';
import UserMenu from './UserMenu';
import UserUtility from "../../utils/userutility/index";
import MarketPlan from './MarketPlan';

interface MarketPlanNavigationProps  {
    isMenu?: boolean;
    fields?: any;
    plan?: any;
    router: any;
    sitecoreContext: any;
    previousFocusOnExit?: boolean;
}

declare global {
    interface Window {
        segmentId: string;
    }
}

const MarketPlanNavigation: React.FC<MarketPlanNavigationProps> = ({fields, plan, sitecoreContext, isMenu, router, previousFocusOnExit}) => {
    const location = router.location;
    const showPlan = showMarketPlan({sitecoreContext});
    const [isLoggedIn, setLogin] = useState<boolean>(UserUtility.isLoggedIn());
    const user = ((Environment.isHot == undefined) || isLoggedIn) ? UserUtility.getUserData() : {
        firstName: "George",
        lastName: "Burdell"
    };
    const [isRegistered, setIsRegistered] = useState<boolean>();
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [previousLocation, setPreviousLocation] = useState<any>(null);
    const [previousAnalyticsLocation, setPreviousAnalyticsLocation] = useState<any>(null);
    const [unvisited, setunvisited] = useState<number>(0);
    const [isLoaded, setIsLoaded] = useState<any>(null);
    const renderMenu = (isMenu || isLoggedIn) ? true : false;
    const drawerRef = useRef<HTMLDivElement>(null);
    const prevFocusedElementRef = useRef<HTMLElement | null>(null);

    const dispatch = useDispatch<any>();

    useEffect(() => {

        const handleStorageChange = (event: StorageEvent) => {
            if (showPlan && event.key === 'isAuthenticated' && ((event.newValue === 'true' && isLoggedIn === false) || (event.newValue === 'false' && isLoggedIn === true))) {
                console.log('You have been signed in or signed out from another tab.');
                window.location.reload();
            }
        };

        if (showPlan) {
            if (isLoggedIn) {
                dispatch(fetchMarketPlan(sitecoreContext.site.name));
            } else {
                dispatch(fetchMarket(sitecoreContext.site.name));
            }
        }

        if (isLoggedIn && sitecoreContext?.jsssite?.segmentId) {
            window.segmentId = sitecoreContext.jsssite.segmentId;
        }

        window.addEventListener('storage', handleStorageChange);
    
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };

    }, []);

    useEffect(() => {
        let trackerActive = false;

        const tracker = TrackUserActivity(() => {
            fetchPlan(plan.market.marketId, dispatch);
        }, 60000);

        if ((plan.isRegistered || isRegistered) && !trackerActive) {
            trackerActive = true;
            tracker.startTracking();
        }

        return () => {
            tracker.stopTracking();
        }

    }, [plan, isRegistered, dispatch]);

    useEffect(() => {
        if (isLoggedIn && (plan?.Showrooms?.filter((showroom: any) => !showroom.LastVisited).length > 0)) {
            setunvisited(plan?.Showrooms?.filter((showroom: any) => !showroom.LastVisited).length);
        } else {
            setunvisited(0);
        }

        if (showPlan && plan?.loaded) {
            if (isLoggedIn) {
                if (localStorage.getItem('isAuthenticated') !== 'true') {
                    localStorage.setItem('isAuthenticated', 'true');
                    analytics().identify(`${plan.contactAltId}`, {
                        contactAltId: plan.contactAltId,
                        market_id: plan.market.marketId,
                    });
                }
            } else {
                if (localStorage.getItem('isAuthenticated') !== 'false') {
                    localStorage.setItem('isAuthenticated', 'false');
                    // analytics.track('Signed Out', {
                    //     market_id: plan.market.marketId,
                    // });
                }
            };
        }

        setIsLoaded(plan.loaded);
        setIsRegistered(plan.isRegistered)
    }, [plan]);

    useEffect(() => {
        if (isLoaded) {
            const hasQueryChanged = previousLocation?.search !== router?.location?.search;
            if (router?.location?.search && hasQueryChanged) {
                let parsed = qs.parse(router?.location.search);

                if ((parsed?.exhibitorId) && isLoggedIn) {
                    if (plan.isRegistered) {
                        const item = getActionItem(parsed);
                        const lineGuid = ((parsed.lineGuid) ? (Array.isArray(parsed.lineGuid)) ? parsed.lineGuid[0] : parsed.lineGuid : undefined) ?? undefined;
                        const action = parsed.action;
                        parsed  = clearParsed(parsed);
                        
                        router.navigate({search: encode(parsed, false, false)});
                        if (action == `add`) {
                            const onPlan =  plan.showrooms.some((showroom: any) =>  
                                compareShowrooms(showroom, item, lineGuid)
                            );
                            const alreadyLoading = plan.loading.some((showroom: any) =>
                                compareShowrooms(showroom, item, lineGuid)
                            );
                            if (!onPlan && !alreadyLoading) dispatch(addToPlan(item, {...plan}, lineGuid));
                        }
                    } else if (!plan.isDrawerOpen && (previousLocation != router?.location) && (router.location.search.includes('action=add') && !previousLocation.search.includes('action=add'))) {
                        toggleDrawer(null, true);
                    }
                } else {
                    if ((parsed?.exhibitorId) && !plan.isDrawerOpen && (previousLocation != router.location)) toggleDrawer(null, true);
                    if (isDrawerOpen && !plan.isDrawerOpen) {
                        parsed = clearParsed(parsed);
                        router.navigate({search: encode(parsed, false, false)});
                    }
                }
            } else {
                let parsed = qs.parse(router?.location.search);
                if (isDrawerOpen && !plan.isDrawerOpen) {
                    parsed = clearParsed(parsed);
                    router.navigate({search: encode(parsed, false, false)});
                }
            }
        }
        if (isDrawerOpen !== plan.isDrawerOpen) setDrawerOpen(plan.isDrawerOpen);
    }, [router.location, previousLocation, plan, isDrawerOpen, isLoaded]);

    useEffect(() => {
        if (isLoaded && router.location && (!lodash.isEqual(router.location, previousLocation))) {
            setPreviousLocation({ ...router.location });
        }
    }, [router.location, isLoaded, previousLocation]);

    useEffect(() => {
        if (isLoaded && (UserUtility.isLoggedIn() || Environment.isHot)) {
            const currentLocation = `${window.location.pathname}${window.location.search}`;
            if (currentLocation !== previousAnalyticsLocation) {
                setPreviousAnalyticsLocation(currentLocation);
                analytics().page({
                    path: window.location.pathname,
                    url: window.location.href,
                    referrer: document.referrer,
                    search: window.location.search,
                    title: document.title,
                });
            }
          }
    }, [location, isLoaded])

    useEffect(() => {
        if (isDrawerOpen) {
            const focusedElement = document.activeElement as HTMLElement;
            if (focusedElement && focusedElement !== document.body) {
                prevFocusedElementRef.current = focusedElement;
            }
            if (drawerRef?.current) {
                drawerRef.current.focus();
            }
        } else {
            if (prevFocusedElementRef?.current) {
                prevFocusedElementRef.current.focus();
            }
        }
    }, [isDrawerOpen]);

    const toggleDrawer = (event: any, status: boolean) => {
        dispatch(toggleDrawerState(status));
    };

    const getActionItem = (item: any) => {
        const newItem = {
            showroomName: (item.lineGuid) ? item.shownBy : item.showroomName,
            shownBy: null,
            exhibitorId: item.exhibitorId,
            lastVisited: null,
            photosCount: 0,
            note: null,
            lines: [] as any[],
        }
        if (item.lineGuid) {
            newItem.lines = [{
                lineId: null,
                lineGuid: item.lineGuid,
                name: item.showroomName,
            }]
        }
        return newItem;
    };

    const userMenus = fields?.data?.model?.menus.map((menu?: any) => {
        return {
            ...menu,
            items: menu.items.filter((item?: any) => {
                let displayItem = true;
                if (item.hideLoggedOut?.data?.value === true && !isLoggedIn) {
                    displayItem = false;
                }
                if (item.hideLoggedIn?.data?.value === true && isLoggedIn) {
                    displayItem = false;
                }
                if (item.type?.name === "Sign In" && (isLoggedIn || !showPlan)) {
                    displayItem = false;
                }
                if (item.type?.name === "Sign Out" && (!isLoggedIn || !showPlan)) {
                    displayItem = false;
                }
                return displayItem;
            })
        };
    });

    const buttons = fields?.data?.model?.menus.map((menu?: any) => {
        return {
            ...menu,
            items: menu.items.filter((item?: any) => {
                return (item.type?.name === "Registration Link");
            })
        };
    });

    if (!userMenus) {
        return (<></>);
    }
    return (
        <>
            {(showPlan && plan.loaded) &&
            <div className="top-nav-plan">
                <DropdownMenu
                    buttonClassName={`top-nav-bg-button imc-button--atmarket-primary`}
                    key={`top-nav-plan`}
                    label={`Market Plan`}
                    chevron={false}
                    icon={`juniper-on-plan`}
                    color={`svg-white`}
                    notifications={(unvisited > 0) ? unvisited : undefined}
                    isDrawer={true}
                    loading={!plan.loaded}
                    clickHandler={toggleDrawer}
                    openState={plan.isDrawerOpen}
                    hidden={!isRegistered}
                >
                    <MarketPlan fields={fields} toggleDrawer={toggleDrawer} drawerRef={drawerRef} />
                </DropdownMenu>
            </div>}
            {(!showPlan || (!plan?.isRegistered && plan?.loaded && buttons.length > 0)) &&
                buttons.map((menu?: any, m?: any) => {
                    return menu.items.map((item?: any, index?: any) => {
                        return (
                            <Link key={`button-item-${index}`}
                                className="imc-button top-nav-bg-button top-nav-bg-button-reg imc-button--atmarket-secondary"
                                field={item?.link?.data}
                                href={item?.link?.data?.value?.href}
                                title={item?.link?.data?.value?.text}>
                                <span>{item.heading?.data?.value}</span>
                            </Link>
                        );
                    });
                })
            }
            {(userMenus.length > 0 && plan?.loaded) &&
                (userMenus.map((menu?: any, m?: any) => {
                    return (
                        <div className={`top-nav-user`} key={`user-menu-${m}`}>
                            {!renderMenu &&
                                menu.items.map((item?: any, index?: any) => {
                                    return (<>
                                        {item.type?.name && item.type.name == "User Link" && 
                                        <Link key={`user-item-${index}`} className="imc-button top-nav-button top-nav-user-button" field={item.link.data} href={item.link.data.value.href} title={item.link.data.value.text}>
                                            <span>{item.heading.data.value}</span>
                                        </Link>}
                                        {(item.type?.name == "Sign In") && 
                                        <button onClick={(event) => UserUtility.redirectToLogin()} title={item.link.data.value.text} className="imc-button top-nav-button top-nav-user-button">
                                            <span className="top-nav-user-heading">{item.heading.data.value}</span>
                                        </button>}
                                        {(item.type?.name == "Sign Out") && 
                                        <a key={`user-item-${index}`} href={item.link.data.value.href} title={item.link.data.value.text} className="imc-button top-nav-button top-nav-user-button">
                                            <span>{item.heading.data.value}</span>
                                        </a>}
                                    </>);
                                })
                            }
                            {renderMenu &&
                            <DropdownMenu
                                buttonClassName={`imc-button imc-button--no-padding top-nav-bg-button top-nav-bg-button-reg imc-button--atmarket-secondary aspect-ratio-1-1 imc-section--margin-left-small imc-section--margin-right-large andmore-bold top-nav-user-menu-button`}
                                key={`top-nav-plan`}
                                label={isLoggedIn ? `${(user.firstName) ? user.firstName[0] : ''}${(user.lastName[0]) ? user.lastName[0] : ''}` : `SIGN IN`}
                                chevron={isLoggedIn ? false : true}
                                icon={isLoggedIn ? `` : ``}
                                color={`svg-white svg-blue-hover`}
                            >
                                <UserMenu items={menu.items} />
                            </DropdownMenu>}
                        </div>
                    );
                }))
            }
            {(showPlan && isLoggedIn && userMenus.length > 0 && !plan?.loaded) && 
            <div className={`top-nav-user`} key={`user-menu`}>
                <DropdownMenu
                    buttonClassName={`imc-content--pointer-events-none imc-button imc-button--no-padding top-nav-bg-button top-nav-bg-button-reg imc-button--atmarket-secondary aspect-ratio-1-1 imc-section--margin-left-small imc-section--margin-right-large andmore-bold top-nav-user-menu-button`}
                    key={`top-nav-plan`}
                    label={``}
                    chevron={false}
                    icon={`loading-full`}
                    color={`svg-black`}
                >
                </DropdownMenu>
            </div>}
        </>
    );
}

function mapStateToProps(state?: any) {
    return {
        ...state,
        plan: {
            ...state.marketPlanReducer
        },
    };
}
export default connect(mapStateToProps)(withSitecoreContext()(withSitecoreRouter(MarketPlanNavigation)));
