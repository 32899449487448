export function getTotalPreviousSiblingsHeight(element: Element): number {
    let totalHeight = 0;
    let prevSibling = element.previousSibling;

    while (prevSibling) {
        if (prevSibling.nodeType === 1) {
            totalHeight += (prevSibling as HTMLElement).offsetHeight;
        }
        prevSibling = prevSibling.previousSibling;
    }

    return totalHeight;
}

interface TopMatter {
    campusHeight: number;
    campusOffset: number;
    headerHeight: number;
    navHeight: number;
    currentScrollTop: number;
    minimumScrollUp: number;
    minimumScrollDown: number;
    stickyHeaderMinScroll: boolean;
    stickyNavMinScroll: boolean;
    stickyNavTop: number;
}

export function getTopMatter(): TopMatter {
    const campusHeight = (document?.getElementsByClassName('top-nav-wrapper')?.length > 0) ? document.getElementsByClassName('top-nav-wrapper')[0].getBoundingClientRect().height : 0;
    const campusOffset = (document?.getElementsByClassName('top-nav-wrapper')?.length > 0) ? document.getElementsByClassName('top-nav-wrapper')[0].getBoundingClientRect().top : 0;
    const headerHeight = (document?.getElementsByClassName('imc-header__container-wrapper')?.length > 0) ? document.getElementsByClassName('imc-header__container-wrapper')[0].getBoundingClientRect().height : 0;
    const headerOffset = (document?.getElementsByClassName('imc-header__container-wrapper')?.length > 0) ? document.getElementsByClassName('imc-header__container-wrapper')[0].getBoundingClientRect().top : 0;
    const navHeight = (document?.getElementsByClassName('imc-navigation-container')?.length > 0) ? document.getElementsByClassName('imc-navigation-container')[0].getBoundingClientRect().height : 0;
    return {
        campusHeight: campusHeight,
        campusOffset: campusOffset,
        headerHeight: headerHeight,
        navHeight: navHeight,
        currentScrollTop: window.scrollY || document.documentElement.scrollTop,
        minimumScrollUp: 10,
        minimumScrollDown: 0,
        stickyHeaderMinScroll: window.scrollY == 0 || window.scrollY >= campusHeight,
        stickyNavMinScroll: (window.scrollY > 0 && headerOffset == 0) ? true : false,
        stickyNavTop: headerHeight - navHeight,
    }
}

export function padContent(scrollBarWidth: number, classNames: string[] = [], scrollY?: number, isSearch?: boolean) {
    let scrollPosition = (scrollY) ? scrollY : window.scrollY;
    let windowWidth = window.innerWidth;
    let overlayScrollBar = document.getElementsByClassName(`imc-modal--overlay-scrollbar`)[0];
    document.body.classList.add(`imc-navigation-mobile--body-open${(isSearch) ? `-search` : ``}`);
    let elements = [
        document.body,
        document.getElementsByClassName('top-nav')[0],
        document.getElementsByClassName('imc-header')[0],
        document.getElementsByClassName('imc-header__container imc-header__nav-mobile')[0],
        (windowWidth < 992) ? document.getElementsByClassName('imc-header__nav')[0] : document.getElementsByClassName('imc-header__nav nav-scroll')[0],
        (windowWidth < 992) ? document.getElementsByClassName('imc-header__container-wrapper  header-sticky')[0] : null,
    ];
    classNames.forEach((className) => {
        document.querySelectorAll(className).forEach((element: any) => {
            elements.push(element);
        });
    });
    elements.forEach((el) => {
        if (el) {
            let element = <HTMLElement> el;
            element.style.removeProperty('padding-right');
            let paddingRight = window.getComputedStyle(element, null).getPropertyValue('padding-right');
            element.style.paddingRight = `calc(${paddingRight} + ${scrollBarWidth}px)`;
            element.style.marginRight = `-${scrollBarWidth}px`;
            element.style.maxWidth = `none`;
        }
    });
    if (overlayScrollBar) {
        let element = <HTMLElement> overlayScrollBar;
        element.style.width = `${scrollBarWidth}px`;
    };
    window.scrollTo({top: scrollPosition, behavior: 'instant'});
}

export function unPadContent(classNames: string[] = [], isSearch?: boolean) {
    document.body.classList.remove(`imc-navigation-mobile--body-open${(isSearch) ? `-search` : ``}`);
    let overlayScrollBar = document.getElementsByClassName('imc-modal--overlay-scrollbar')[0];
    let windowWidth = window.innerWidth;
    let elements = [
        document.body,
        document.getElementsByClassName('top-nav')[0],
        document.getElementsByClassName('imc-header')[0],
        document.getElementsByClassName('imc-header__container imc-header__nav-mobile')[0],
        (windowWidth < 992) ? document.getElementsByClassName('imc-header__nav')[0] : document.getElementsByClassName('imc-header__nav nav-scroll')[0],
        (windowWidth < 992) ? document.getElementsByClassName('imc-header__container-wrapper  header-sticky')[0] : null,
    ];
    classNames.forEach((className) => {
        document.querySelectorAll(className).forEach((element) => {
            elements.push(element);
        });
    });
    elements.forEach((el) => {
        if (el) {
            let element = <HTMLElement> el;
            element.style.removeProperty('padding-right');
            element.style.removeProperty('margin-right');
            element.style.removeProperty('max-width');
        }
    });
    if (overlayScrollBar) {
        let element = <HTMLElement> overlayScrollBar;
        element.style.removeProperty('width');
    };
}