import React from 'react';
import Link from '../Link'
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';


interface UserMenuProps {
  items: any;
}

const renderUserInfo = (user: any) => {
    const initials = (user && user.firstName && user.lastName) ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}` : ``;
    const name = (user && user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : ``;
    const email = (user && user.email) ? user.email : ``;
    const company = (user && user.company) ? user.company : ``;
    return (
        <li key={`username`} role="username">
            <div className={`top-nav-user-userinfo imc-section--margin-bottom-mediumlarge`}>
                <div className={`imc-content--display-flex imc-content--display-flex-gap-mediumlarge imc-content--display-flex-center`}>
                    <div className={`aspect-ratio-1-1 imc-content--border-round imc-section--neutral-heaviest imc-section--padded-left-small imc-section--padded-right-small imc-section--padded-small imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-justify-center`}>
                        <span className="top-nav-user-heading imc-type--title-4 andmore-bold imc-type--color-basic-white">{initials}</span>
                    </div>
                    <div className={`imc-content--flex-grow-1`}>
                        <div><span className="top-nav-user-heading imc-type--title-4 andmore-bold top-nav-plan-empty-list-heading">{name}</span></div>
                        {(email) && <div><span className="imc-content--normal">{email}</span></div>}
                        {(company) && <div><span className="imc-content--normal">{company}</span></div>}
                    </div>
                </div>
            </div>
        </li> 
    );
};

const UserMenu: React.FC<UserMenuProps> = ({ items }) => {

    const isLoggedIn = ((Environment.isHot != undefined) || UserUtility.isLoggedIn());
    const user = ((Environment.isHot == undefined) && isLoggedIn) ? UserUtility.getUserData() : {
        firstName: "George",
        lastName: "Burdell",
        email: "gburdell@andmore.com",
        company: "ANDMORE",
    };
    const renderedItems = items;

    const renderUserItem = (item: any, index: number) => {
        return (
            <li key={index} role="menuitem">
                {item.type?.name == "User Link" && 
                <Link className="top-nav-user-link" field={item.link.data} href={item.link.data.value.href} title={item.link.data.value.text}>
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </Link>}
                {(item.type?.name == "Sign In") && 
                <button onClick={(event) => UserUtility.redirectToLogin()} title={item.link.data.value.text} className="top-nav-user-link">
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </button>}
                {(item.type?.name == "Sign Out") && 
                <a href={item.link.data.value.href} title={item.link.data.value.text} className="top-nav-user-link">
                    <span className="top-nav-user-heading">{item.heading.data.value}</span>
                </a>}
            </li>
        );
    };
    
    return (
        <div className="top-nav-dropdown-menu">
            <div className="top-nav-dropdown-grid">
                <div className="top-nav-column">
                    <ul role="menu" aria-label={`Dropdown Menu for User Login and Logout`}>
                        {(isLoggedIn) && renderUserInfo(user)}                       
                        {renderedItems.map((item: any, index: number) => renderUserItem(item, index))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserMenu;