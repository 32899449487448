// Library dependencies
import React, { ReactNode } from 'react';

// Module dependencies
import { ExpandCollapse } from 'modules/expandcollapse';

type FilterWrapperProps = {
    children?: ReactNode;
    filterExpanded: (isExpanded: boolean) => ReactNode;
    expanded?: boolean;
    displayName: string;
    expandedClass?: string;
    activeLabel?: string;
    alwaysExpanded?: boolean;
    isFeaturedFilter?: boolean;
};

const FilterWrapper: React.FC<FilterWrapperProps> = ({
    children,
    expanded = false,
    displayName,
    filterExpanded,
    expandedClass = '',
    activeLabel = '',
    alwaysExpanded = false,
    isFeaturedFilter = false,
}) => {
    return (
        <div className={`imc-filteritem ${(isFeaturedFilter) ? 'imc-section--padded-bottom-mediumlarge' : ''}`} data-xpath="filteritem.wrapper">
            <ExpandCollapse
                expanded={alwaysExpanded || expanded}
                expandedUpdated={(isExpanded:any) => filterExpanded(isExpanded)}
                additionalHeadingClass={`imc-filteritem__parent`}
                scrollToTop={false}
                heading={displayName}
                alwaysExpanded={isFeaturedFilter || alwaysExpanded}
            >
                {(!isFeaturedFilter) && <div>
                    <div className="imc-filteritem__tier1">
                        <h4 className={`imc-content--full-width imc-type--title-3-ui imc-type--color-neutral-heavy-medium ${expandedClass} ${(activeLabel) ? 'active' : ''}`} data-xpath="filteritem.heading">{displayName}</h4>
                    </div>
                </div>}
                <div>
                    <div className={`imc-filteritem__tier2 imc-content--display-flex imc-content--display-flex-gap-${(isFeaturedFilter) ? `smallmedium imc-section--padded-top-smallmedium` : `medium`} imc-content--display-flex-column`}>
                        {React.Children.map(children, (child) => child)}
                    </div>
                </div>
            </ExpandCollapse>
        </div>
    );
};

export default FilterWrapper;
