import React from 'react';
import ContentSectionComponent from '../Content-Section-Component';
import {newComponent, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'

// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicTwoColumnSectionProps {
    fields?: any;
};
// ---------------------- //

// ------------------------ //
// Basic Two Column Section //
// ------------------------ //
const BasicTwoColumnSection: React.FC<BasicTwoColumnSectionProps> = ({fields}) => {
    let grid = flexGridRendering();
    let section = contentSectionComponentRendering();
    let imageSection = contentSectionComponentRendering();
    let textSection = contentColumnSectionRendering(fields);
    let backgroundColor = (fields?.backgroundColor?.value) ? fields?.backgroundColor?.value : '';
    let accentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'primary-light';
    let secondaryAccentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'secondary-light';
    let primaryImageClasses = fields.imageWrapper.map((f?: any) => {
        f.fields.className.value = f.fields.className.value.replace("{{backgroundColor}}",`${backgroundColor}`).replace("{{accentColor}}",`${accentColor}`).replace("{{secondaryAccentColor}}",`${secondaryAccentColor}`);
        return f;
    });
    let wrapSection = (primaryImageClasses.map((f?: any) => { return f.fields.className.value}).join(" ").includes(" am-pare-offset")) ? false : true;
    if (fields.reverseColumns.value) grid.params.reverseMobile = "1";
    // ---------------------- //
    // build wrapping section //
    // ---------------------- //
    if (fields.backgroundOverride?.value) {
        if (!wrapSection) {
            textSection.fields.styles = {
                "backgroundColor": `${fields.backgroundOverride.value}`
            };
        } else {
            section.fields.styles = {
                "backgroundColor": `${fields.backgroundOverride.value}`
            };
        }
    }
    if (fields.backgroundColor?.value) {
        grid.params.padding = "{\"className\":{\"value\":\"imc-gallery--no-padded\"}}";
        textSection.fields.paddingTop.value = 'xlarge';
        textSection.fields.paddingBottom.value = 'xlarge';
        textSection.fields.paddingLeft.value = 'xlarge';
        textSection.fields.paddingRight.value = 'xlarge';
        if (!wrapSection) {
            textSection.fields.backgroundColor.value = ` imc-section--${(backgroundColor) ? `${backgroundColor}` : `basic-white`} am-wrapper am-content am-pare-offset`;
            section.fields.backgroundColor.value = ` `;
            grid.params.verticalAlignment = "{\"className\":{\"value\":\"imc-gallery--align-flex-stretch\"}}";
        } else {
            section.fields.backgroundColor.value = `${fields.backgroundColor.value}`;
        }
    } else if (!wrapSection) {
        textSection.fields.backgroundColor = {
            value: ` one-col-pare`
        };
        textSection.fields.paddingLeft.value = 'none';
        textSection.fields.paddingRight.value = 'none';
        grid.params.padding = "{\"className\":{\"value\":\"imc-gallery--xlarge-padded\"}}";
    }
    if (fields?.bodyClasses?.length > 0) textSection.fields.extraClass = {
        fields: {
            className: {
                value: fields.bodyClasses.map((f?: any) => {
                    return f.fields.className.value
                }).join(" ")
            }
        }
    };
    // ------------------- //
    //  build grid classes //
    // ------------------- //
    if (fields.extraClasses?.length > 0) {
        fields.extraClasses.forEach((c?: any) => {
            if (c.fields?.className?.value?.toLowerCase().includes("imc-gallery--")) {
                let v = c.fields?.className?.value?.toLowerCase();
                if (v.includes("1-1--tablet")) {
                    grid.params.tabletColumnCount = "{\"className\":{\"value\":\"1\"}}";
                } else if (v.includes("1-2--mobile")) {
                    grid.params.mobileColumnCount = "{\"className\":{\"value\":\"2\"}}";
                } else if (v.includes("padded")) {
                    grid.params.padding = `{\"className\":{\"value\":\"${v}\"}}`;
                } else if (parseInt(v.replace("imc-gallery--","").split("-")[0]) && parseInt(v.replace("imc-gallery--","").split("-")[0]) > 9) {
                    if (!v.includes("desktop") && !v.includes("tablet") && !v.includes("mobile")) {
                        grid.params.twoColumnWidths = `{\"className\":{\"value\":\"${v.replace("imc-gallery--","")}\"}}`;
                    } else {
                        if (grid?.params?.extraClasses && JSON.parse(grid?.params?.extraClasses)?.classes?.length > 0) {
                            grid.params.extraClasses = `{\"classes\": [{\"className\":{\"value\":\"${v}\"}}]}`
                        } else {
                            grid.params.extraClasses = `{\"classes\": [{\"className\":{\"value\":\"${v}\"}}]}`;
                        }
                    }
                }
            } else if (c?.fields?.className?.value) {
                section.fields.backgroundColor.value = `${section.fields.backgroundColor.value} ${c.fields.className.value}`;
            }
        });
    }
    imageSection = returnMediaSection(fields.image, fields.videoFile, primaryImageClasses, fields.primaryCTA, fields.linkImage, fields.secondaryCTA, fields.linkImageSecondary, fields?.mute); 
    if (fields?.styles) {
        imageSection.fields.styles = newComponent(fields?.styles);
    }
    grid.placeholders[`imc-grid-column-${(fields.reverseColumns.value) ? 'two' : 'one'}-placeholder`].push(imageSection);
    grid.placeholders[`imc-grid-column-${(fields.reverseColumns.value) ? 'one' : 'two'}-placeholder`].push(textSection);
    section.placeholders['imc-jss-content-section'].push(grid);
    return (
        <ContentSectionComponent {...section} rendering={section} />
    );
};
// ------------------------ //

export default BasicTwoColumnSection;
