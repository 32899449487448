import { getDictionaryValue } from 'utils/dictionary';

export const SEARCH_IDENTIFIER = 'search';
export const EXHIBIT_IDENTIFIER = 'exhibit';

export const LINE_IDENTIFIER = 'line';
export const PROD_IDENTIFIER = 'prod';

export const PRODUCTS_IDENTIFIER = 'products';
export const INFORMATION_IDENTIFIER = 'information';
export const EXHIBITORS_IDENTIFIER = 'exhibitors';

export const ARTICLES_IDENTIFIER = 'articles';
export const EVENTS_IDENTIFIER = 'events';
export const LINES_IDENTIFIER = 'lines';
export const CATALOGS_IDENTIFIER = 'catalogs';
export const SPECIALS_IDENTIFIER = 'specials';

export const EXHIBITOR_IDENTIFIER = 'exhibitor';
export const DIRECTORY_IDENTIFIER = 'directory';
export const EVENT_PAGE = 'plan/events/event-details?id=';
export const SEARCH_TYPES = [EXHIBITORS_IDENTIFIER, INFORMATION_IDENTIFIER, ARTICLES_IDENTIFIER, EVENTS_IDENTIFIER];

const LINE_PATH_IDENTIFIER = '/' + LINE_IDENTIFIER + '/';
const LINES_PATH_IDENTIFIER = '/' + LINES_IDENTIFIER + '/';


export function getCurrentPathProductsURL(currentPath) {
    return `${currentPath.replace(/\/$/, '')}/${PRODUCTS_IDENTIFIER}`;
}
export function getCurrentPathCatalogsURL(currentPath) {
    return `${currentPath.replace(/\/$/, '')}/${CATALOGS_IDENTIFIER}`;
}
export function getCurrentPathLinesURL(currentPath) {
    return `${currentPath.replace(/\/$/, '')}/${LINES_IDENTIFIER}`;
}
export function getSiteName(url) {
    let results;
    return (url && (results = url.trim().match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) ? results[1] : url);
}

/**
 * @method getExhibitorProductsURL
 * @returns {string} /exhibitor/[exhibitorId]/products
 */
export function getExhibitorProductsURL(exhibitorId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${PRODUCTS_IDENTIFIER}`;
}
/**
 * @method getExhibitorURL
 * @returns {string} /exhibitor/[exhibitorId]
 */
export function getExhibitorURL(exhibitorId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}`;
}
/**
 * @method getExhibitorLineURL
 * @returns {string} /exhibitor/[exhibitorId]/line/[lineId]
 */
export function getExhibitorLineURL(exhibitorId, lineId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${LINE_IDENTIFIER}/${lineId}`;
}
/**
 * @method getAllExhibitorLinesURL
 * @returns {string} /exhibitor/[exhibitorId]/lines
 */
export function getAllExhibitorLinesURL(exhibitorId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${LINES_IDENTIFIER}`;
}
/**
 * @method getAllExhibitorCatalogsURL
 * @returns {string} /exhibitor/[exhibitorId]/catalogs
 */
export function getAllExhibitorCatalogsURL(exhibitorId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${CATALOGS_IDENTIFIER}`;
}
/**
 * @method getExhibitorLineProductsURL
 * @returns {string} /exhibitor/[exhibitorId]/line/[lineId]/products
 */
export function getExhibitorLineProductsURL(exhibitorId, lineId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${LINE_IDENTIFIER}/${lineId}/${PRODUCTS_IDENTIFIER}`;
}
/**
 * @method getExhibitorLineProductURL
 * @returns {string} /exhibitor/[exhibitorId]/line/[lineId]/prod/[prodId]
 */
export function getExhibitorLineProductURL(exhibitorId, lineId, prodId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${LINE_IDENTIFIER}/${lineId}/${PROD_IDENTIFIER}/${prodId}`;
}
/**
 * @method getExhibitorProductsURL
 * @returns {string} /exhibitor/[exhibitorId]/prod/[prodId]
 */
export function getExhibitorProductURL(exhibitorId, prodId) {
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/${PROD_IDENTIFIER}/${prodId}`;
}

/**
 * @method getExhibitorCatalogURL
 * @returns {string} /pdfView?catalogId=[catalogId] future state: /exhibitor/[exhibitorId]/pdfView?catalogId=[catalogId]
 */
export function getExhibitorCatalogURL(exhibitorId, catalogId) {
    // return `/pdfView?catalogId=${catalogId}`;
    return `/${EXHIBITOR_IDENTIFIER}/${exhibitorId}/pdfView?catalogId=${catalogId}`;
}

/**
 * @method getEventProductsURL
 * @returns {string} /exhibitor/[exhibitorId]/prod/[prodId]
 */
export function getEventEvntURL(eventId) {
    return `/${EVENT_PAGE}${eventId}`;
}


export function productCategoriesToArray(categoriesTree) {

    let categories = [];

    if (typeof categoriesTree !== 'undefined' && categoriesTree) {
        categoriesTree.forEach(category => {
            const _category = category.category ? category.category : category;
            //if (!!+_category.checked) {
            categories.push({ name: _category.name, id: _category.id })
            //}
            categories = categories.concat(productCategoriesToArray(_category.subCategories));
        })
    }
    return categories;
}
/**
 * @method getExhibitorProductsURL
 * @returns {string} /exhibitor/[exhibitorId]/products
 */
export function getShopzioUrl(shopZioId) {
    return shopZioId;
}
export function exhibitorIsLine(exhibitor) {
    return (exhibitor && exhibitor.lineName) ? true : false;
}

export function locationIsLine(location) {
    return (location && location.pathname && (location.pathname.includes(LINE_PATH_IDENTIFIER) || location.pathname.includes(LINES_PATH_IDENTIFIER)) ? true : false);
}

export function getTitleName(exhibitor) {
    return exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName;
}


export function getParamsFromShowroomUrl(url) {
    let splitted = url.split('/');
    let result = {};
    if (splitted.length > 1)
        result.exhibitId = splitted[2];
    if (splitted.length > 3)
        result.lineId = splitted[4];
    if (splitted.length > 5)
        result.productId = splitted[6];
    return result;
}