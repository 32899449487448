import React, { Component } from 'react';
import qs from 'query-string';
import { AddToCalendar } from 'modules/addtocalendar'
import UserUtility from 'utils/userutility';
import {withSitecoreRouter} from 'utils/withRouter';
import { Loading } from 'modules/loading';
import { Image } from 'modules/image/index';
import { ReactComponent as Clock } from '../../assets/icons/las-vegas-market/Clock.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/las-vegas-market/Location.svg';
import EventsUtility from '../../utils/events/index.ts';
import ImcDataLayer from 'utils/datalayer';
import { formatConsistentDateRange } from 'utils/dates';

//modules
import Link from "../../components/Link";
import {getDetails} from '../../api/events';


class EventDetail extends Component {
  /**
    * @method constructor
    * @param {object} props Incoming props
    */
  constructor(props) {
    super(props);

    this.state = {
      event: {},
      isLoading: true,
    };
  }

  /**
   * Will get the list data when mounting
   */
  componentDidMount() {

    const pathParts = window.location.pathname.split('/');
    const eventId = pathParts[pathParts.length - 2];
    const channel = UserUtility.getChannelData().name;

    // fetch events
     getDetails(eventId, channel)
      .then(data => {
        if (!!data && data.length > 0) {
          const evt = data[0];
          ImcDataLayer.pushEventViewEvent(evt.title, evt.id, evt.eventType, evt.eventDate, evt.exhibitorId, evt.tags);
          this.setState({
            event: evt,
            isLoading: false
          });
        }
      }).catch(err => console.error(err.toString()))
  }

  getEventLocation(event) {
    var building, showroom;

    if (!event.location)
      return (<></>);
    return event.location.map(location => {
      let eventLocation = EventsUtility.getEventLocation(location);


      if (!location.showroom) {
        building = location; //just getting a string
      }
      else {
        building = location.type == "showroom" || location.showroom ? location.showroomBuilding : location.data;
        showroom = location.type == "showroom" || location.showroom ? location.showroom : null;
      }
      if (!eventLocation.Url)
        return (
          <div className='imc-eventdetail--location'>
            <LocationIcon className='imc-eventdetail--icon' />
            <p><span class="imc-heading imc-heading--quaternary imc-heading--gamma">{eventLocation.DisplayLocation}</span></p>
            
          </div>
        )
      else return (
        <div className='imc-eventdetail--location'>
          <LocationIcon className='imc-eventdetail--icon' />
          <Link
            href={eventLocation.Url}
            title={eventLocation.DisplayLocation} className="imc-link imc-eventdetail--time imc-link--hover-underline">
              <span className='imc-eventdetail--time'>{eventLocation.DisplayLocation}</span>
          </Link>
        </div>
      );
    });
  }

  openLinkNewWindow(url) {
    window.open(url, "_blank")
  }



  renderEventContent() {
    const { event } = this.state;
    return (
      <div className='imc-eventdetail--content-container'>
        <div className='am-grid imc-gallery imc-gallery--1-2 imc-gallery--1-1--tablet imc-gallery--1-1--mobile imc-gallery--xlarge-padded imc-gallery--align-flex-stretch imc-gallery imc-gallery--50-50'>
            

            {/* Image */}
            <div className='imc-gallery__item imc-eventdetail--image-container'>
                <Image
                    src={event.mainImage}
                    alt={event.title}
                    className="imc-eventdetail--image"
                />
            </div>

            {/* Text Content */}
            <div className='imc-gallery__item imc-eventdetail--text-container'>
                <div class="section-wrapper imc-section--">
                    <section class="imc-section imc-section--relative imc-content--center imc-section--neutral-light  imc-section--padded-top-xlarge imc-section--padded-bottom-xlarge imc-section--padded-left-xlarge imc-section--padded-right-xlarge">
                        <div className='imc-rich-text'>
                            {/* Date */}
                            <p className="imc-section--padded-bottom-xlarge">
                                <Clock className='imc-eventdetail--icon' />&nbsp;<span class="imc-content--bold">{`${formatConsistentDateRange({start: event.formattedDate, end: event.formattedEndDate})}`}</span>
                                <br />
                                <span>
                                    <span>{event.time ? event.time.startTime : ""}{` - `}{event.time ? event.time.endTime : ""}{` ${event.time.timezone ? event.time.timezone.title : (UserUtility.getUserData().timeZone ? UserUtility.getUserData().timeZone : "")}`}</span>
                                </span>
                                <br />
                                {/* Add to cal */}
                                <span className="imc-eventdetail--calendar">
                                    <AddToCalendar
                                    eventId={event.id}
                                    hasIcon={false}
                                    />
                                </span>
                            </p>

                            {/* Location */}
                            {this.getEventLocation(event)}

                            {/* Exhibitor */}
                            {event.exhibitorId && 
                                <div class="section-wrapper imc-section--">
                                    <section class="imc-section--padded-top-none imc-section--padded-bottom-small">
                                    <Link href={`/exhibitor/${event.exhibitorId}`} className={`imc-link imc-link--hover-underline`}>{event.exhibitorName}</Link>
                                    </section>
                                </div>}

                            {/* Event type */}
                            <p className='imc-eventdetail--event-type'>{event.eventType}</p>

                            {/* Actions */}
                            {/* {this.renderActionsBar(event)} */}

                            {/* CTA */}
                            {event.registrationLink &&
                            <div className="imc-section--padded-top-xlarge">
                                <Link
                                    href={event.registrationLink}
                                    className="imc-button imc-button--full-bleed-mobile imc-button--small imc-content--inline-block imc-section--padded-left-xxlarge imc-section--padded-right-xxlarge imc-type--color-basic-white"
                                    target="_blank">
                                    <span className='imc-type--color-basic-white'>{(event.ctaTitle) ? event.ctaTitle : "Register"}</span>
                                </Link>
                            </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
      </div>
    )
  }

  renderDescription() {
    const { event } = this.state;
    let summary;

    if (event.summary != null || event.summary == 'undefined') {
      summary = event.summary;
    } else {
      summary = '';
    }

    return (
        <>
        {summary ? (
            <div className='imc-eventdetail--user-content-container'>
                <div class="section-wrapper imc-section--">
                    <section class="imc-section imc-section--relative imc-section--padded-top-xlarge imc-section--padded-bottom-medium imc-section--padded-left-none imc-section--padded-right-none">
                        <h2 className="imc-content--kilo imc-content--bold">Details</h2>
                    </section>
                </div>
                <div class="section-wrapper imc-section--">
                    <section class="imc-section imc-section--relative imc-section--padded-top-none imc-section--padded-bottom-xlarge imc-section--padded-left-none imc-section--padded-right-none">
                        <p dangerouslySetInnerHTML={{ __html: summary }}></p>
                    </section>
                </div>
            </div>
        ) : null}
        </>
    )
  }

  render() {
    const { isLoading, event } = this.state;

    if (!isLoading) {
      const eventTime = (typeof event.time !== 'undefined' && event.time !== null) ? ` | ${event.time.startTime} - ${event.time.endTime}` : '';
      if (eventTime === '') {
        return
      }
      return (
        <div className='imc-eventdetail--root-container'>
          {/* Header */}
            <div class="imc-eventdetail--header">
                {(event.eventType) && <div class="section-wrapper imc-section--">
                    <section class="imc-section imc-section--relative imc-content--center imc-section-- imc-section--padded-top-xlarge imc-section--padded-bottom-none imc-section--padded-left-none imc-section--padded-right-none">
                        <div class="imc-rich-text">
                            <h3 class="imc-content--beta andmore-bold">{event.eventType}</h3>
                        </div>
                    </section>
                </div>}

                {(event.exhibitorId) && 
                <div class="section-wrapper imc-section--">
                    <section class="imc-section--padded-top-none imc-section--padded-bottom-small">
                    <Link href={`/exhibitor/${event.exhibitorId}`} className={`imc-link imc-link--hover-underline imc-content--alpha`}>{event.exhibitorName}</Link>
                    </section>
                </div>}

                <div class="section-wrapper imc-section--">
                    <section class="imc-section imc-section--relative imc-content--center imc-section--  imc-section--padded-top-medium imc-section--padded-bottom-xlarge imc-section--padded-left-none imc-section--padded-right-none">
                        <div class="imc-rich-text  imc-eventdetail--header">
                            <h1 class="imc-content--giga imc-content--bold">{event.title}</h1>
                        </div>
                    </section>
                </div>
            </div>

          {this.renderEventContent()}
          {this.renderDescription()}
        </div >
      )
    }
    if (isLoading) {
      return (
        <section class="imc-section"><Loading showLoading={this.props.showLoading} /></section>
      )
    }
  }

}

export default withSitecoreRouter(EventDetail);
