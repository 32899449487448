import React from 'react';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import Floor from './Floor';
 
const Building = (building: any, buildingIndex: number, refresh: number) => {
    return (
        <div key={`building-${buildingIndex}`} className={`imc-content--border-radius-4 imc-content--overflow-hidden`}>
            <DropdownMenu
                wrapperClass={``}
                buttonClassName={`building imc-content--display-flex-justify-left imc-heading--h2 imc-section--neutral-light imc-content--full-width imc-content--left imc-button--left imc-section--padded-bottom-xlarge imc-section--padded-top-xlarge`}
                chevron={false}
                key={`building-${building.title.value}-${refresh}`}
                label={`${building.title.value}`}
                width={24}
                height={24}
                previousFocusOnExit={true}
                isCollapsible={false}
                style={{zIndex: "1"}}
                initialState={true}
                closeOnScroll={false}
                closeOnBlur={false}
                leaveContent={true}
            >
                <div className={`grid-collapse`}>
                    <div className={`imc-section--padded-left-large imc-section--padded-right-large imc-section--neutral-light`}>
                        <div className={`imc-section--padded-top-none imc-section--padded-bottom-xlarge imc-content--display-flex-gap-xlarge imc-grid imc-grid-3 imc-grid-2--tablet imc-grid-1--mobile`}>
                            {building.floors.map((floor: any, floorIndex: number) => Floor(floor, building.title.value, buildingIndex, floorIndex))}
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </div>
    )
};

export default Building;