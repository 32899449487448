import React from 'react';
import { Placeholder, isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class GridComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!window.doBuild) {
      this.preloadWidgetScript();
    } else {
      window.doBuild();
    }
  }

  preloadWidgetScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.dataset.pinBuild = 'doBuild';
    script.src = '//assets.pinterest.com/js/pinit.js';
    document.body.appendChild(script);
  }

  render(){
    if (!this.props.fields) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    var columnCount = 2;
    if(this.props.fields.columnCount){
      columnCount = this.props.fields.columnCount.value;
    };
    var mainDivClass = "";
    var columnDivClass = "";

    switch (columnCount) {
        case 1:
            columnDivClass = 'imc-gallery--1-1 imc-gallery--1-1--tablet imc-gallery--1-1--mobile';
            break;
        case 2:
            columnDivClass = 'imc-gallery--1-2 imc-gallery--1-2--tablet imc-gallery--1-1--mobile';
            break;
        case 3:
            columnDivClass = 'imc-gallery--1-3 imc-gallery--1-2--tablet imc-gallery--1-1--mobile';
            break;
        case 4:
            columnDivClass = 'imc-gallery--1-4 imc-gallery--1-2--tablet imc-gallery--1-1--mobile';
            break;
        case 5:
            columnDivClass = 'imc-gallery--1-5 imc-gallery--1-3--tablet imc-gallery--1-2--mobile';
            break;
        case 6:
            columnDivClass = 'imc-gallery--1-6 imc-gallery--1-3--tablet imc-gallery--1-2--mobile';
            break;
        case 7:
            columnDivClass = 'imc-gallery--1-7 imc-gallery--1-4--tablet imc-gallery--1-2--mobile';
            break;
        case 8:
            columnDivClass = 'imc-gallery--1-8 imc-gallery--1-4--tablet imc-gallery--1-2--mobile';
            break;
        default:
            let tabletCount = Math.ceil(1.0 * columnCount / 2.0)
            let mobileCount = Math.ceil(1.0 * tabletColumnCount / 2.0)
            columnDivClass = `imc-gallery--1-${Math.max(2, columnCount)} imc-gallery--1-${tabletCount}--tablet imc-gallery--1-${mobileCount}--mobile`;
            break
    }

    if (this.props.fields.extraClass) {
        columnDivClass += `  ${this.props.fields.extraClass.fields.className.value}`;
    }
    return(
      
        <div className={`${mainDivClass}  ${columnDivClass}`}>
            <Placeholder currentTab={this.props.currentTab ? this.props.currentTab : ''} tabData={this.props.tabData ? this.props.tabData : ''} name="imc-grid-placeholder" rendering={this.props.rendering} />
        </div>
    );
  }
}

const _withSitecoreContext = withSitecoreContext()(
  GridComponent
);

export default _withSitecoreContext;
