// Packages
import React from 'react'
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
//Utils
import { getExhibitorURL, getExhibitorLineURL } from "utils/exhibitor";
//Modules
import { getBooth } from "utils/floorplan";
// Popup styles
import 'reactjs-popup/dist/index.css';
import Link from '../../Link';
import MarketPlanButton from 'modules/marketplan/components/MarketPlanButton';
import { showMarketPlan } from 'utils/general';

const noImg = process.env.PUBLIC_URL + '/assets/images/175-175.png';

const Line = ({ line, index, getActionItem, building, floor, sitecoreContext }) => {
  const generateURL = (line) => {
    if (line.lineId) {
        return getExhibitorLineURL((line.exhibitorId) ? line.exhibitorId : line.exhibiterId, line.lineId)
    }
    return getExhibitorURL(line.exhibiterId)
  }
  return (
    <div className="imc-gallery__item">
        <div className="imc-manufacturing-line">
            <div className={"imc-manufacturing-line-image-wrapper"} >
                <img
                    src={(line.directoryLogos) ? line.directoryLogos : (line.manufacturerLogoURL) ?  line.manufacturerLogoURL : noImg}
                    onError={(e) => { e.target.src = noImg }}
                />
            </div>
            <div className={`imc-manufacturing-line-title-wrapper imc-section--padded-left-medium imc-section--padded-right-medium ${(line.isNewToMarket == true || line.newToMarket == true) ? `imc-section--padded-bottom-largemedium imc-section--padded-top-largemedium` : `imc-section--padded-bottom-medium imc-section--padded-top-medium`}`}>
                <Link
                className="imc-type--title-5-link"
                href={generateURL(line)}
                >
                {line.name}
                </Link>
                {line?.matchedProductsCount > 0 && <p className='imc-type--body-3  imc-type--color-neutral-heavy-medium'>{(line.matchedProductsCount > 0) ? `${line.matchedProductsCount} Product${line.matchedProductsCount > 0 ? `s` : ``} Shown` : ``}</p>}
                {line.showRoomsDisplay?.length > 0 && <div className='imc-locations-flex'>
                    <span className='imc-icon-Location  imc-type--color-neutral-heavy-medium' />&nbsp;
                    {line.showRoomsDisplay.map((showRoom, i) => <p key={`showroom${index}${i}`} className='imc-type--body-3  imc-type--color-neutral-heavy-medium'>
                        <span>{getBooth(building, floor, showRoom, showRoom)} &nbsp;</span>
                    </p>)}
                </div>}
            </div>
            <div className={`imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-gap-small market-plan-button-wrapper`}>
                {(line.isNewToMarket == true || line.newToMarket == true) && (
                <div className={`new-to-market imc-section--padded-left-mediumsmall imc-section--padded-right-mediumsmall imc-section--padded-top-xxsmall imc-section--padded-bottom-xxsmall imc-content--zeta`}>
                    <span className={`imc-content--bold imc-content--zeta imc-type--color-neutral-heavy`}>New</span>
                </div>
                )}
                {showMarketPlan({sitecoreContext}) && <MarketPlanButton item={getActionItem(line)} lineGuid={line.lineId} compact={true} noDefaultWrapper={true} />}
            </div>
        </div>
    </div>
  )
}

function mapStateToProps(state) {
    if (state?.floorPlanReducer?.data?.building?.name && state?.floorPlanReducer?.data?.building.floor?.number) {
        return {
            building: state.floorPlanReducer.data.building.name.replace('Building ', ''),
            floor: state.floorPlanReducer.data.building.floor.number,
            sitecoreContext: state.sitecoreContext
        }
    }
}

export default connect(mapStateToProps)(withSitecoreContext()(Line));