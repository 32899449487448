import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import {withSitecoreRouter} from 'utils/withRouter';
import EventsUtility from '../../utils/events/index.ts';
//Actions

//Utils
import { uniqueid } from 'utils/uniqueid';
import { getDictionaryValue } from 'utils/dictionary/index';
import { updateUrl } from '../../utils/tagupdate/index.js';
import UserUtility from "utils/userutility";

//modules
import { CardTags } from 'modules/cardtags';
import Link from '../../components/Link/index.js';
import { formatConsistentDateRange } from 'utils/dates';

/**
 * Event Card Component
 * @param {object} props incoming props
 * @returns {XML} EventCard Component
 */
class SearchCard extends Component {
    /**
    * Constuctor
    @param {object} props incoming props
    */
    constructor(props) {
        super(props);
        this.renderCardTags = this.renderCardTags.bind(this);
        this.tagClick = this.tagClick.bind(this);

    }

    /**
 * Tag click event
 * @returns {JSX} Card tags
 */
    tagClick(event) {
        const { router, match, filterKey, tagKey } = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateUrl(tag, router, match, filterKey, tagKey);
    }


    renderCardTags() {
        const { destinationUrl, tag, tags } = this.props;
        const id = uniqueid('event-');
        return <CardTags
            className=""
            dataXpath="eventcard.tags"
            destinationUrl={destinationUrl}
            maxTagsToDisplay={2}
            tags={tag}
            id={id}
            clickEvent={this.tagClick}
            eventType={tags}
        />
    }

    /** Render Exhibitor Card
    * @returns {XML} elements to render
    */
    render() {
        const {
            title,
            teaserText,
            eventDate,
            formattedDate,
            formattedEndDate,
            locations,
            destinationUrl,
            time,
            startDateTime,
            endDateTime,
            smallImage,
            eventType,
            exhibitorId,
            exhibitorName,
            registrationLink,
            tabletBreak,
            hideDescription,
            onlyShowStartDate,
            router = true,
        } = this.props;
        const { event } = this.props;
        const formattedRange = (onlyShowStartDate) ? formattedDate : (event && event.formattedDate && event.formattedEndDate) ? formatConsistentDateRange({
            start: event.formattedDate,
            end: event.formattedEndDate,
        }) : '';

        const timezone = (event?.time?.timezone) ? event.time.timezone.title : (UserUtility.getUserData().timeZone ? UserUtility.getUserData().timeZone : "");
        return (
            <div
                className="imc-line imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-medium"
            >
                <div className="imc-content--border-1 imc-content--border-radius-4 imc-content--border-color-neutral-medium imc-content--overflow-hidden">
                    <div className={`imc-content--full-width am-grid imc-gallery imc-gallery--1-1--mobile ${(smallImage) ? `imc-gallery--1-${(tabletBreak) ? `1` : `2`}--tablet imc-gallery--1-2 imc-gallery--33-66` : `mc-gallery--1-1--tablet imc-gallery--1-1`} imc-gallery--align-flex-start imc-gallery--justify-left imc-gallery--no-padded`}>
                        {(smallImage) && <div className="imc-gallery__item imc-content--center-mobile">
                            <div className="imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large imc-section--padded-bottom-large">
                                <Link router={router} href={destinationUrl}><img className="event-card--image" src={smallImage} alt={title}/></Link>
                            </div>
                        </div>}
                        <div className="imc-gallery__item imc-content--left">
                            <div className="imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large imc-section--padded-bottom-large imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-small">
                                <div className="">
                                    <div className="imc-content--display-flex imc-content--display-flex-wrap imc-content--display-flex-gap-small item imc-content--full-width">
                                        <div className="imc-content--display-flex-grow-1 imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-xsmall">
                                            <Link router={router} href={destinationUrl}>
                                                <p className={'event-card--title imc-link--hover-underline normal-line-height'}>{title}</p>
                                            </Link>
                                            {(exhibitorId && exhibitorName) &&
                                                <div className="imc-type--title-2-uil  imc-content--delta">
                                                    {getDictionaryValue('at', '')}<Link
                                                        router={router}
                                                        className={'imc-button--text-only imc-content--delta imc-content--normal'}
                                                        href={destinationUrl}
                                                    >{exhibitorName}</Link>
                                                </div>
                                            }
                                        </div>
                                        {eventType &&
                                            <p className="imc-type--title-2-ui"
                                            style={{textTransform: 'capitalize'}}>{eventType}</p>
                                        }
                                    </div>
                                </div>
                                {(time && formattedRange) &&
                                    <div className="imc-type--title-2-ui">
                                        {formattedRange}{(!onlyShowStartDate) && <span>&nbsp;<br />&nbsp;<Moment format="hh:mm A">{startDateTime.replace("Z","")}</Moment>&nbsp;-&nbsp;<Moment format="hh:mm A" local>{endDateTime.replace("Z","")}</Moment>&nbsp;{timezone}</span>}
                                    </div>
                                }
                                {(locations && locations.length > 0) &&
                                    <div className="imc-type--title-2-ui">
                                        <span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center event-card--location-icon"></span>
                                        {
                                        `${EventsUtility.getEventLocation(locations[0]).DisplayLocation}`
                                        }
                                    </div>
                                }
                                {(!hideDescription) && <p className="imc-type--title-2-ui">{teaserText}</p>}
                                <div className="imc-content--display-flex imc-content--display-flex-justify-left imc-content--full-width imc-content--display-flex-gap-medium">
                                    <div className="imc-content--display-flex-grow-1 imc-content--display-flex imc-content--display-flex-center">
                                        <Link
                                            router={router}
                                            className={'imc-button--text-only imc-content--delta imc-content--nowrap'}
                                            href={destinationUrl}
                                        >{getDictionaryValue('learnMoreInformation', 'Learn More')}&nbsp;&rarr;</Link>
                                    </div>
                                    {(registrationLink) &&
                                        <div>
                                            <Link router={router} target='_blank' href={registrationLink} className="imc-button imc-content--delta imc-searchform--bar--button">{getDictionaryValue('register', 'Register')}</Link>
                                        </div>
                                    }
                                    <div className="imc-content--display-none">
                                        {this.renderCardTags()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const propTypes = {
    actions: PropTypes.object,
    destinationUrl: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    teaserText: PropTypes.string,
    itemType: PropTypes.string,
    eventDate: PropTypes.string,
    formattedDate: PropTypes.string,
    time: PropTypes.object,
    speakerNames: PropTypes.array,
    location: PropTypes.array,
    sponsor: PropTypes.string,
    id: PropTypes.string.isRequired,
    tag: PropTypes.array,
    labelEventSpeaker: PropTypes.string,
    labelEventSponsor: PropTypes.string,
    labelEventLocation: PropTypes.string,
    labelDetailsLink: PropTypes.string,
    labelMoreTags: PropTypes.string,
    labelProducts: PropTypes.object.isRequired,
    savedContent: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

const defaultProps = {
    labelEventSpeaker: 'SPEAKER',
    labelEventSponsor: 'SPONSOR',
    labelEventLocation: 'Location',
    labelDetailsLink: 'See Details',
    destinationUrl: '',
    itemType: 'Event',
    image: '',
    title: 'EVENT TITLE',
    teaserText: 'some teaser text',
    eventDate: 'Some Data',
    formattedDate: '',
    time: null,
    speakerNames: [],
    sponsor: null,
    location: [],
    tag: [],
    labelMoreTags: '+ More',
    actions: {
    },
    labelProducts: {},
    labels: {},
    savedContent: {},
    filterKey: 'events',
    tagKey: 'Topics',
    router: true,
};


SearchCard.propTypes = propTypes;
SearchCard.defaultProps = defaultProps;

// Export the react component
//export { EventCard as UnwrappedEventCard };
export default withSitecoreRouter(SearchCard);

