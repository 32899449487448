import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreRouter } from 'utils/withRouter';

import API from '../../api/exhibitor';
import AlphabetNavigation from './components/AlphabetNavigation';
import ExhibitorList from './components/ExhibitorList';
import exhibitors from 'modules/searchresults/routes/exhibitors';
import { encode, decode } from 'utils/querystring';

const ALL_CHARACTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'];

class ExhibitorAZDirectory extends PureComponent {
  static propTypes = {
    fields: PropTypes.object,
  }

  constructor(props) {
    super(props);

    if (!props) {
      if (isEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = {
      exhibitors: [],
      invalidCharacters: [],
      selectedCharacter: 'A',
      resultsCountString: '',
      loading: false,
      noData: false,
      pathname: '/exhibitor/directory',
    };
    this.api = new API();

    this.createResultsCountString = this.createResultsCountString.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.onChangeCharacter = this.onChangeCharacter.bind(this);
    this.onLoadExhibitors = this.onLoadExhibitors.bind(this);
    this.onLoadInvalidChars = this.onLoadInvalidChars.bind(this);
  }

  componentDidMount() {
    this.setState({ pathname: this.props.router.location.pathname });
    this.loadInitialData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCharacter } = this.state;
    if (this.props.router.location.pathname === this.state.pathname) {
        if (prevState.selectedCharacter !== selectedCharacter) {
          const searchParams = Object.assign({}, decode(window.location.search));
          searchParams.char = [selectedCharacter.replace('#', '%23')];
          this.props.router.navigate({search: encode(searchParams, false, false)});
          this.onLoadExhibitors(selectedCharacter);
        } else if  (prevProps.router.location != this.props.router.location) {
          const searchParams = Object.assign({}, decode(window.location.search));
          if (searchParams?.char?.length > 0 && searchParams?.char[0] != selectedCharacter) {
            this.onLoadExhibitors(searchParams.char[0]);
          } else if (searchParams.char === undefined) {
            this.setState({ selectedCharacter: 'A' });
          }
        }
    } else {
        const url = new URL(window.location.href);
        if (url?.char) {
            delete url.char;
            window.history.replaceState({}, '', url);
        }
    }
  }

  async loadInitialData() {
    const searchParams = Object.assign({}, decode(window.location.search));
    const char = (searchParams?.char?.length > 0) ? searchParams.char[0].toUpperCase() : undefined;
    await this.onLoadInvalidChars();
    if (this.state.noData) return;

    const firstViableChar = (char) ? char : ALL_CHARACTERS.find((char) => !this.state.invalidCharacters.includes(char)) || 'A';
    this.onChangeCharacter(firstViableChar)
    this.onLoadExhibitors(firstViableChar);
  }

  async onLoadInvalidChars() {
    const data = await this.api.getInvalidCharactersAzDirectory();
    let invalidCharacters = [];
    if (data?.invalidLetters) invalidCharacters = data.invalidLetters.map((char) => char.toUpperCase());
    this.setState({ invalidCharacters })
    if (invalidCharacters.length === ALL_CHARACTERS.length) {
      this.setState({ noData: true })
    }
  }

  async onLoadExhibitors(selectedCharacter = this.state.selectedCharacter) {
    let exhibitorsData = [];

    this.setState({ loading: true })

    try {
      exhibitorsData = await this.api.getExhibitorsDirectoryList(selectedCharacter.replace('#', '%23'))
      exhibitorsData = exhibitorsData?.items.filter(exhibitor => exhibitor?.name !== null).sort((a, b) => {
        let fa = a?.name.toLowerCase(),
          fb = b?.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } catch (err) {
      console.log('Error', err)
    }
    const resultsCountString = this.createResultsCountString(exhibitorsData);
    if (selectedCharacter === this.state.selectedCharacter) this.setState({ exhibitors: exhibitorsData, resultsCountString, loading: false })
  }

  onChangeCharacter(selectedCharacter) {
    this.setState({ selectedCharacter })
  }

  createResultsCountString(exhibitors) {
    const { selectedCharacter } = this.state;
    return this.props.fields?.Matching?.value?.replace('{0}', exhibitors?.length).replace('{1}', selectedCharacter);
  }

  render() {
    const { fields } = this.props;
    const { exhibitors, invalidCharacters, selectedCharacter, resultsCountString, loading, noData } = this.state;

    return (
      <div className='az-directory--container'>
        <h1 class="imc-type--title-1">{fields?.Title?.value}</h1>
        <p className='az-directory--matching imc-type--body-3'>{resultsCountString}</p>
        <AlphabetNavigation invalidCharacters={invalidCharacters} selectedCharacter={selectedCharacter} onChangeCharacter={this.onChangeCharacter} loading={loading} />
        <ExhibitorList exhibitors={exhibitors} loading={loading} noData={noData} />
      </div >
    )
  }
};

export default withSitecoreRouter(ExhibitorAZDirectory);
