import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import qs from 'query-string';
import { encode, decode } from 'utils/querystring';
import { isMobileOnly } from "react-device-detect";

import { ReactComponent as LeftArrowButton } from '../../../assets/svg/common/LeftArrowCircle.svg';
import { ReactComponent as RightArrowButton } from '../../../assets/svg/common/RightArrowCircle.svg';
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';

// Module dependencies
import NewSearchItemThumb from 'modules/newSearchItemThumb/NewSearchItemThumb';

import { getBooth } from 'utils/floorplan';

import Link from '../../../components/Link';
import SvgIcon from 'modules/svgicon';

//actions
import * as multiSelectActions from '../../../components/Type-Ahead-Search/actions/multiSelectActions';

//Utils
import { getDictionaryValue } from 'utils/dictionary';
import { cleanObjKeys } from 'utils/cleanObjKeys';
import { getFloorPlanURL } from 'utils/floorplan';
import {
    getExhibitorProductsURL,
    getExhibitorLineProductsURL,
    getExhibitorCatalogURL,
    getExhibitorURL,
    getExhibitorLineURL,
    getExhibitorLineProductURL,
    getExhibitorProductURL,
    getAllExhibitorLinesURL,
    getAllExhibitorCatalogsURL
} from "utils/exhibitor";
import ImcDataLayer from "utils/datalayer";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMarketCtaShouldBeShown, showMarketPlan } from '../../../utils/general';

import MarketPlanButton from '../../marketplan/components/MarketPlanButton';

const LINE_TYPE = 'Line';
const propTypes = {
    actions: PropTypes.object,
    allowFavoriting: PropTypes.bool,
    title: PropTypes.string,
    products: PropTypes.array,
    catalogs: PropTypes.array,
    inSelectionState: PropTypes.bool,
    onMultiSelect: PropTypes.func,
    lease: PropTypes.array,
    siteUrlSlug: PropTypes.string,
    exhibitorId: PropTypes.any,
    labels: PropTypes.object,
    type: PropTypes.string,
    lineId: PropTypes.string,
    siteUrls: PropTypes.object,
    lineName: PropTypes.string,
    shownLabel: PropTypes.string,
    exhibitorName: PropTypes.string,
    fallBackImagePath: PropTypes.string,
    savedContent: PropTypes.object,
    productCount: PropTypes.number,
    labelFloorPlan: PropTypes.object,
    searchResultsLabels: PropTypes.object,
    exhibitorCount: PropTypes.object,
};

const defaultProps = {
    actions: {
    },
    allowFavoriting: false,
    title: 'Exhibitor Name',
    products: [],
    catalogs: [],
    inSelectionState: false,
    onMultiSelect: null,
    lease: [],
    siteUrlSlug: '',
    exhibitorId: '',
    labels: {
        account: {
            loginUrl: 'http://someurl.com',
        },
        exhibitor: {
            productDetailUrl: '/exhibitor/$1/prod/$2',
        },
    },
    type: 'exhibitor',
    lineId: '',
    siteUrls: {},
    lineName: null,
    shownLabel: 'Shown by ',
    exhibitorName: '',
    fallBackImagePath: '',
    savedContent: {},
    productCount: 0,
    labelFloorPlan: {},
    searchResultsLabels: {},
};

/**
 * @method ExhibitorCard
 * @description
 * Display Img, Title, details,etc provided in a card format
 */
class ExhibitorCard extends Component {
    /**
     * Constuctor
     @param {object} props incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            productSliceSize: 4,
            currentProductIndex: 0,
            logoError: false,
            heroError: false,
        }

        this.changeImageIndex = this.changeImageIndex.bind(this);
        this.getParams = this.getParams.bind(this);
        this.getProductCategoryFilters = this.getProductCategoryFilters.bind(this);
        this.getActionItem = this.getActionItem.bind(this);
        this.multiSelectChange = this.multiSelectChange.bind(this);
        this.updateMobileView = this.updateMobileView.bind(this);
        this.isMobileOrResponsiveMobile = this.isMobileOrResponsiveMobile.bind(this);

        //datalayer prodImpressions
        ImcDataLayer.PushProductImpressions(props.products.slice(0, this.state.productSliceSize));
    }



    updateMobileView() {
        let productSliceSize;

        if (document.documentElement.clientWidth < 481) {
            productSliceSize = 1;
        } else if (document.documentElement.clientWidth < 1095) {
            productSliceSize = 2;
        }
        else if (document.documentElement.clientWidth < 1294) {
            productSliceSize = 3;
        }
         else {
            productSliceSize = 4;
        }

        this.setState({ productSliceSize });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMobileView);
    }


    componentDidMount() {
        window.addEventListener('resize', this.updateMobileView);

       /* let productSliceSize;

        if (isMobileOnly) {
            productSliceSize = 1;
        } else if (isTablet) {
            productSliceSize = 2;
        } else if (document.documentElement.clientWidth < 1294) {
            productSliceSize = 3;
        } else {
            productSliceSize = 4;
        }
        this.setState({ productSliceSize });*/
        this.updateMobileView();



    }

    isMobileOrResponsiveMobile()
    {
        return document.documentElement.clientWidth < 1294;
    }

    componentDidUpdate(lastProps) {
        if (lastProps.addToListSuccess !== this.props.addToListSuccess) {
            if (this.props.addToListSuccess) {
                this.props.actions.multiSelectReset();
            }
        }
        if (lastProps.selectedItems !== this.props.selectedItems) {
            if (this.multiSelect !== null) {
                this.multiSelect.checked = this.props.selectedItems.some(e => e.id === this.multiSelect.id);
            }
        }
    }

    multiSelectChange() {
        const { exhibitorId, actions, type, lineId } = this.props;
        const actionItem = this.getActionItem();
        const selectionItemId = type === 'Line' ? lineId : exhibitorId;

        actions.itemChanged({ actionItem: actionItem, selected: this.multiSelect.checked, id: `SearchItemThumb${selectionItemId}` });
    }

    changeImageIndex(isIncrementing = true, arrayLength) {
        const { currentProductIndex } = this.state;
        let newProductIndex = currentProductIndex;
        let subtractor = isMobileOnly ? 0 : 1;

        isIncrementing ? newProductIndex++ : newProductIndex--;

        if (newProductIndex >= 0 && newProductIndex < arrayLength - subtractor) {
            this.setState({ currentProductIndex: newProductIndex })
        };
    }

    isLocalMarket(lease) {
        return (typeof window !== 'undefined' && 'channel' in lease && lease.channel.siteCode === window.channel.name);
    }
    getLocalMarket(exhibitor) {
        return exhibitor.activeLeases && exhibitor.activeLeases.filter(this.isLocalMarket);
    }

    getShowrooms(localLeases) {
        console.log(localLeases)
        let filterdShowroom = [];
        let added = []
        if (!!localLeases) {
            localLeases.map(function (lease, indexa) {
                return (lease.showrooms.map(function (showroom, index) {

                    if (!added.includes(showroom.showroom)) {
                        filterdShowroom.push(showroom);
                        added.push(showroom.showroom);
                    }
                })
                )
            })
        }
        return filterdShowroom;
    }

    /**
     * Gets the product filters to pass into the detail URL
     * @returns {*} Query param to append to the detail page link
     */
    getParams(detailUrl) {
        const searchQuery = cleanObjKeys(Object.assign({}, decode(location.search)));
        const page = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
        const linkPage = detailUrl.substring(detailUrl.lastIndexOf("/") + 1);
        let filters = '';
        const params = {};
        let hasParams = false;
        if (searchQuery.q) {
            params.q = searchQuery.q;
            hasParams = true;
        }
        if (searchQuery.exhibitor) {
            params.products = searchQuery.exhibitor;
            hasParams = true;
        }
        if (searchQuery[page] && searchQuery[page].length > 0) {
            filters = this.getProductCategoryFilters().join('&');
        }
        return (hasParams || filters.length > 0) ? `?${(hasParams) ? encode(params) : ``}${(filters.length > 0) ? `${(hasParams) ? `&` : ``}${linkPage}=${filters}` : ``}` : '';
    }

    /**
     * Take router location and return Product Category Filters
     * @param {*}  
     * @returns a list of strings
     */
    getProductCategoryFilters() {
        const searchQuery = cleanObjKeys(Object.assign({}, decode(location.search)));
        const page = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
        let filters = [];
        if (searchQuery[page] && searchQuery[page].length > 0) {
            searchQuery[page].forEach((filterGroup) => {
                filterGroup.split('&').forEach((filter) => {
                    if (filter.toLowerCase().indexOf('product') > -1 && filter.toLowerCase().indexOf('categories') > -1) {
                        filters.push(`${filter}`);
                    }
                });

            });
        }
        return filters;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getActionItem() {
        const { exhibitor } = this.props;
        return {
            showroomName: this.props.exhibitorName,
            exhibitorId: exhibitor.exhibitorId,
            lines: (exhibitor.type === "Line" || exhibitor.lineId) ? [{
                    name: this.props.lineName,
                    lineGuid: exhibitor.uniqueId
                }] : [],
            lastVisited: null,
            photosCount: 0,
        }
    }

    getExhibitorInitials(exhibitor) {
        return exhibitor?.title?.split(' ').map((word) => word[0]).join('').toUpperCase();
    }

    renderLogo(onlyInitials) {
        return (
            <Link href={this.props.type === 'Line' ? getExhibitorLineURL(this.props.exhibitorId, this.props.lineId) : getExhibitorURL(this.props.exhibitorId)} >
                {(!onlyInitials && this?.props?.exhibitor?.organizationLogo && !this.state.logoError)
                ? <img
                    src={this.props.exhibitor.organizationLogo}
                    alt="Exhibitor Logo"
                    className="imc-exhibitorcard__logo imc-section--basic-white"
                    onError={(e) => {
                        this.setState({ logoError: true });
                    }}
                    onLoad={(e) => {
                        this.setState({ logoError: false });
                    }}
                />
                : <div className="imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-space-evenly imc-exhibitorcard__logo imc-section--neutral-heaviest andmore-bold imc-type--color-basic-white">{this.getExhibitorInitials(this.props.exhibitor)}</div>}
            </Link>
        )
    }

    renderCardDetails(type, exhibitorUrl, exhibitorName, exhibitorCount, allLinesUrl) {
        return (
            <>
                {/* Shown By container */}
                {type === LINE_TYPE && <div className='imc-exhibitorcard--text-container-row'>
                    {/* Shown by if line */}
                    <p className="imc-exhibitorcard--subtext">
                        {getDictionaryValue("shownby", "Shown By")}
                        &nbsp;
                        <Link className='imc-exhibitorcard--link imc-link--hover-underline' href={exhibitorUrl}>{exhibitorName ? exhibitorName : 'Exhibitor Name Not Available'}</Link>
                    </p>
                </div>}
                {/* Lines Shown container */}
                {(exhibitorCount?.lineCount > 0 && type !== LINE_TYPE) && <div className='imc-exhibitorcard--text-container-row'>
                    <Link className='imc-exhibitorcard--link imc-link--hover-underline' href={allLinesUrl}>{getDictionaryValue("linesShown", `${exhibitorCount?.lineCount} Lines Shown`)}</Link>
                </div>}
            </>
        );
    }

    renderLocations(hasMobileCookie) {
        const { exhibitor } = this.props;
        let localLease;
        if (exhibitor.companyDetails) {
            localLease = this.getLocalMarket(exhibitor.companyDetails);
        }
        let filterdShowrooms = this.getShowrooms(localLease);
        return (<div className="imc-exhibitorcard--text-wrapper imc-content--display-flex-wrap">
            <p className="imc-exhibitorcard--subtext"><span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center"></span></p>
            {filterdShowrooms &&
                filterdShowrooms.map(function (showroom, index) {
                    return (
                        <div key={index}>
                            {!hasMobileCookie && <Link href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link imc-link--hover-underline'}>
                                {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom, showroom.showroomDisplayName)}
                            </Link>}
                            {hasMobileCookie && <a href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link imc-link--hover-underline'}>
                                {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom, showroom.showroomDisplayName)}
                            </a>}
                            {index + 1 < filterdShowrooms.length && <>,&nbsp;</>}
                        </div>
                    );
                })
            }
        </div>)
    }

    /** Render Exhibitor Card
     * @returns {XML} elements to render
     */
    render() {
        const {
            title,
            exhibitorId,
            type,
            lineId,
            exhibitorName,
            products,
            exhibitor,
            multiSelectMode,
            exhibitorCount,
            plan,
            showLogos,
            onlyInitials,
            showHeros,
            heroBeforeProducts
        } = this.props;
        const { currentProductIndex, productSliceSize } = this.state;
        const detailUrl = (type === 'Catalog') ? getAllExhibitorCatalogsURL(exhibitorId) : (type === 'Line') ? getExhibitorLineProductsURL(exhibitorId, lineId) : getExhibitorProductsURL(exhibitorId);
        const exhibitorUrl = getExhibitorURL(exhibitorId);
        const selectionItemId = type === 'Line' ? lineId : exhibitorId;
        const lineUrl = getExhibitorLineURL(exhibitorId, lineId);
        const allLinesUrl = getAllExhibitorLinesURL(exhibitorId);
        const catFav = this.getActionItem();
        const searchItems = [...products];
        const juniperMarketLink = exhibitor.shopZioLink || exhibitor.shopZioUrl;
        const showProducts = (searchItems.length > 0) && (!((showHeros) && (searchItems.length == 0 || heroBeforeProducts) && (exhibitor.heroImage && !this.state.heroError)));
        let hasMobileCookie = false;
        if (typeof document !== "undefined") {
            hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
        }

        let planState = plan.showrooms.filter((showroom) => {
            if (type === 'Line') {
                return showroom.exhibitorId == exhibitorId && showroom.id == lineId;
            } else  {
                return showroom.exhibitorId == exhibitorId;
            }
        });

        const breakpoints = {
            '0': {slidesPerView: 2, spaceBetween: 25},
            '600': {slidesPerView: 3, spaceBetween: 25},
            '768': {slidesPerView: 4, spaceBetween: 25},
            '1200': {slidesPerView: 5, spaceBetween: 25},
        };

        // const countFilter = this.props.filtersCount;
       const countFilters = this.getProductCategoryFilters()[0];
       const countFilter = this.getProductCategoryFilters().map(filter => filter.split(`|`).length).reduce((a, c) => a + c, 0);
        const searchQuery = cleanObjKeys(Object.assign({}, qs.parse(location.search)));
        const searchTerm = searchQuery?.q;

        return (
            <div
                className="imc-exhibitorcard imc-line imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-medium"
            >
                <div>
                    {/* Title Row Container */}
                    <div className="imc-gallery__item">
                        <div className={`imc-exhibitorcard-title-row ${(exhibitor.productCount > 0 || searchItems.length > 0) ? '' :'no-border-bottom'}`}>
                            {/* Multiselect Card */}
                            <div className="imc-content--display-flex imc-content--display-flex-gap-medium item imc-content--full-width">
                                {(showLogos) && this.renderLogo(onlyInitials)}
                                <div className={`imc-content--full-width`}>
                                    <div className="imc-content--display-flex imc-content--display-flex-gap-medium item imc-content--full-width">
                                        {multiSelectMode &&
                                            <div className="imc-padding--right--large">
                                                <input
                                                    className="multiSelectItem"
                                                    ref={(ref) => { this.multiSelect = ref }} type="checkbox"
                                                    id={`SearchItemThumb${selectionItemId}`}
                                                    onChange={this.multiSelectChange} />
                                            </div>
                                        }
                                        <div>
                                            <div className={`imc-content--display-flex imc-content--display-flex-gap-medium imc-content--display-flex-wrap imc-content--display-flex-row-gap-none`}>
                                                <Link href={type === 'Line' ? lineUrl : exhibitorUrl}>
                                                    <h2 className={'imc-exhibitorcard__exhibitorname imc-exhibitorcard--title-hover'}>{title}</h2>
                                                </Link>
                                                <div className={`imc-content--display-flex imc-breakpoint-display--hide-mobile`}>
                                                    {this.renderCardDetails(type, exhibitorUrl, exhibitorName, exhibitorCount, allLinesUrl)}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Action Icons */}
                                        {((exhibitor?.hasShowSpecials) || (exhibitor.isNewToMarket || exhibitor.newToMarket)) && <div className={`imc-content--display-flex imc-content--display-flex-row-gap-none imc-content--display-flex-grow-1 imc-content--display-flex-justify-left imc-content--display-flex-gap-medium imc-content--display-flex-center`}>
                                            {(exhibitor?.hasShowSpecials) && <Link
                                                className={`imc-link--hover-underline imc-content--nowrap imc-content--bold imc-type--color-brand-neutral-atmarket_tertiary imc-content--zeta-gamma`}
                                                href={type === 'Line' ? lineUrl : `${exhibitorUrl}/specials`}
                                            >
                                                <span><SvgIcon width={16} height={16} xlinkHref={'#juniper-starz-2'} className={`svg-grayblue `} useClassName={`svg-grayblue`} /></span>
                                                <span className={`imc-section--padded-left-xsmall imc-content--nowrap imc-breakpoint-display--hide-actual-tablet`}>{getDictionaryValue("showSpecialsinSearch", `Show Specials`)}</span>
                                            </Link>}
                                            {(exhibitor.isNewToMarket || exhibitor.newToMarket) && (
                                                <div className={`imc-exhibitorcard-title-row--tags-wrapper`}>
                                                    <span className={`imc-content--bold imc-type--color-brand-neutral-atmarket_tertiary new-to-market imc-section--padded-xsmall imc-section--padded-left-small imc-section--padded-right-small imc-content--zeta-gamma`}>New</span>
                                                </div>
                                            )}
                                        </div>}
                                    </div>
                                    {/* Location */}
                                    <div className="">
                                        <div className={`imc-content--display-flex imc-breakpoint-display--hide-desktop`}>
                                            {this.renderCardDetails(type, exhibitorUrl, exhibitorName, exhibitorCount, allLinesUrl)}
                                        </div>
                                        {this.renderLocations(hasMobileCookie)}
                                    </div>
                                </div>
                                {showMarketPlan(this.props) && <div className='imc-content--display-flex imc-content--display-flex-justify-right imc-exhibitorcard--text-container-col'>
                                    <div className="imc-exhibitorcard-actions-bar">
                                        <ul className={'actions-bar'}>
                                            <li className='imc-exhibitorcard--action imc-exhibitorcard--contact imc-exhibitorcard--contact--heart'>
                                                <MarketPlanButton
                                                    item={catFav}
                                                    lineGuid={(type === 'Line') ? lineId : null}
                                                    extraClassName={`imc-button--atmarket--icon-only--mobile`}
                                                    mobile={true}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Title Card */}

                {/* Location */}
                {/* <div className="imc-exhibitorcard--text-container-row imc-section--padded-left-large imc-section--padded-right-large">
                    <div className="imc-exhibitorcard--text-wrapper">
                        <p className="imc-exhibitorcard--subtext"><span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center"></span></p>
                        {filterdShowrooms &&
                            filterdShowrooms.map(function (showroom, index) {
                                return (
                                    <div key={index}>
                                        {!hasMobileCookie && <Link href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link imc-link--hover-underline'}>
                                            {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)}
                                        </Link>}
                                        {hasMobileCookie && <a href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link imc-link--hover-underline'}>
                                            {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)}
                                        </a>}
                                        {index + 1 < filterdShowrooms.length && <>,&nbsp;</>}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div> */}

                {/* Hero */}
                {(showHeros) && (searchItems.length == 0 || heroBeforeProducts) && (exhibitor.heroImage && !this.state.heroError) && (
                    <div className="imc-exhibitorcard--hero">
                        <Link href={type === 'Line' ? lineUrl : exhibitorUrl}>
                            <img
                                src={exhibitor.heroImage}
                                alt={exhibitorName}
                                className={`imc-button--round`}
                                onError={(e) => {
                                    this.setState({ heroError: true });
                                }}
                                onLoad={(e) => {
                                    this.setState({ heroError: false });
                                }} />
                        </Link>
                    </div>
                )}

                {/* Product Counts */}
                {/* remove type && type !== 'Catalog' to show catalog links (if API returns correct matchedProductsCount for Catalogs) */}
                {(showProducts && exhibitor.matchedProductsCount > 0 && type !== 'Catalog') &&
                <div className="imc-exhibitorcard--text-container-row imc-section--padded-left-large imc-section--padded-right-large">
                    <div className="imc-content--display-flex imc-content--display-flex-gap-small imc-exhibitorcard--text-wrapper">
                        <span>
                            <Link
                                href={`${detailUrl}${this.getParams(detailUrl)}`}
                                className='imc-exhibitorcard--link imc-link--hover-underline imc-content--center'
                            >
                                <span className="imc-content--zeta imc-content--delta-desktop">
                                    <span className='imc-content--bold'>{`${exhibitor.matchedProductsCount} `}</span>
                                    <span className=''>{`Matching ${(type === 'Catalog') ? `Catalog` : `Product`}${exhibitor.matchedProductsCount > 1 ? 's' : ''}`}</span>
                                </span>
                                {(countFilter > 0 || searchTerm) && (
                                    <span className="imc-content--zeta imc-content--delta-desktop"> for</span>)
                                }
                                {searchTerm && (
                                    <span className="imc-content--zeta imc-content--delta-desktop">
                                        <span className="imc-content--bold"> "{`${searchTerm}`}"</span>
                                    </span>
                                )}
                                {(countFilter > 0 && searchTerm) && (
                                    <span className="imc-content--zeta imc-content--delta-desktop">
                                        <span> and</span>
                                    </span>
                                )}
                                {countFilter > 0 && (
                                    <span className="imc-content--zeta imc-content--delta-desktop">
                                        <span className="imc-content--bold">{` ${countFilter} `}</span>
                                        <span>{` Category Filter${countFilter > 1 ? 's' : ''}`}</span>
                                    </span>
                                )}
                                <span className="imc-content--zeta imc-content--delta-desktop"> &rarr;</span>
                            </Link>
                        </span>
                        {/* <a
                            href={`${detailUrl}`}
                            className='imc-exhibitorcard--link imc-link--hover-underline imc-content--center'
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.router.navigate(detailUrl)
                            }}
                        >
                            <span className="imc-content--zeta imc-content--delta-desktop">{getDictionaryValue("matchingProducts", `${exhibitor.matchedProductsCount} Matching Products`)}</span>
                            <span className="imc-content--zeta imc-content--delta-desktop">View All Products &rarr;</span>
                        </a> */}
                    </div>
                </div>}

                {/* Images */}
                {(showProducts) && <div className="imc-section--padded-left-large imc-section--padded-right-large">
                        <div className={`imc-exhibitorcard-products-row imc-content--relative`}>
                            <Swiper
                                spaceBetween={25}
                                slidesPerView={1}
                                breakpoints={breakpoints}
                                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            >
                            {searchItems.map((item, ix) => {
                                            const { lineDetail } = this.props;
                                            let index = 2;
                                            let isLine = false;
                                            let productId = item.type == "Catalog" ? item.productId : item.uniqueId;//Catalogs are returning an empty uniqueId. remove after fix from BE
                                            if (lineDetail) {
                                                index = 4;
                                                isLine = true;
                                            }

                                            const catFav = {
                                                itemId: type.toLowerCase() == 'line' ? lineId : exhibitorId,
                                                itemType: this.capitalizeFirstLetter(type),
                                                label: item.exhibitorName,
                                                contentName: '',
                                                itemContents: [{
                                                    contentName: item.productTitle,
                                                    itemId: productId,
                                                    itemType: item.type.toLowerCase(),
                                                    deleted: true,
                                                }],
                                            };
                                            const imagePath = item.images && item.images.length > 0 ? encodeURI(item.images[0].fullPath) : null;
                                            const catalogUrl = getExhibitorCatalogURL(exhibitorId, productId);
                                            const productUrl = type === 'Line' ? getExhibitorLineProductURL(exhibitorId, lineId, productId) : getExhibitorProductURL(exhibitorId, productId);
                                            return (
                                                <SwiperSlide key={ix}>
                                                    <NewSearchItemThumb
                                                        key={item.uniqueId}
                                                        url={item.type == "Catalog" ? catalogUrl : productUrl}
                                                        image={imagePath}
                                                        newWindow={false}
                                                        showActionBar={true}
                                                        actionItem={catFav}
                                                        name={item.productTitle}
                                                        itemID={productId}
                                                        product={item}
                                                        isNew={item.newProductForMarket}
                                                        multiSelectMode={multiSelectMode}
                                                        style={ix==0?"grid-column-start: 2;":""}
                                                    />
                                                </SwiperSlide>
                                            )
                                        })}
                            </Swiper>
                            <div className="swiper-button-next"><LeftArrowButton /></div>
                            <div className="swiper-button-prev"><RightArrowButton /></div>
                        </div>
                </div>}

            </div>
        );
    }
}


function mapStateToProps(state) {
    let _state = {
        addToListSuccess: state.isSuccess,
        filtersCount: (state?.filterReducer?.filtersCount) || 0
    };
    if (state.multiSelectReducer) {
        _state.selectedItems = state.multiSelectReducer.selectedItems;
        _state.multiSelectMode = state.multiSelectReducer.multiSelectMode;
    }
    _state.plan = state.marketPlanReducer;
    return _state;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...multiSelectActions }, dispatch),
    };
}

ExhibitorCard.propTypes = propTypes;
ExhibitorCard.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withSitecoreRouter(ExhibitorCard)));
