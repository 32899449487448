
import React, { useEffect, useRef, useMemo } from 'react';
import SortSearchResults from '../../components/Sort-Search-Results';
import { encode, decode } from 'utils/querystring';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import SvgIcon from 'modules/svgicon';
import { getDictionaryValue } from 'utils/dictionary';



interface RenderSearchProps {
    searchTerm: string,
    handleSearch: (value: string) => void,
    sortLists: {
        type: string,
        options: {key: string, value: string}[]
    }[],
    renderCalendar: () => JSX.Element,
    filtersCount: number,
    router: any
}

export const RenderSearch: React.FC<RenderSearchProps> = React.memo(({searchTerm, handleSearch, sortLists: sortLists, renderCalendar, filtersCount, router}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [mobileExpanded, setMobileExpanded] = React.useState(false);

    const clearQuery = () => {
        let params = {}
        if (router) {
            router.navigate({search: encode(params, false, false)});
        };
    }

    const toggleDrawer = (event: React.SyntheticEvent, status: any) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setMobileExpanded(status);
    }

    const renderSortLists = (sortLists: any) => {
        if (!sortLists || sortLists.length == 0) return null;
        return(
            sortLists.map((sortList: any, s: number) => {
                if (sortList.options.length > 1) return <div key={'sort-list-${s}'} className={`imc-content--display-flex-grow-1`}><SortSearchResults selectClassName={`imc-content--width-max-100`} wrapperClassName={`imc-content--width-max-100`} options={sortList.options} label={sortList.type} /></div>
                return
            })
        )
    }

    return (
        <>
            <div className={`imc-content--full-width imc-content--display-flex imc-content--display-flex-wrap imc-content--display-flex-gap-medium section-wrapper imc-content--border-radius-8 imc-button--round imc-section imc-section--basic-white imc-section--padded-bottom-large imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large imc-content--drop-shadow-extra-light`}>
                <DropdownMenu
                    wrapperClass={`imc-content--display-none`}
                    key={`filter-menu-mobile`}
                    clickHandler={(event: React.SyntheticEvent, status: boolean) => {
                        toggleDrawer(event, status ? status : true);
                    }}
                    escapeHandler={(event: React.SyntheticEvent, status: boolean) => {
                        toggleDrawer(event, status ? status : false);
                    }}
                    isDrawer={true}
                    openState={mobileExpanded}
                    foriegnContent={true}
                    previousFocusOnExit={true}
                    closeOnScroll={false}
                >
                    <></>
                </DropdownMenu>
                <div className="imc-content--flex-grow imc-content--relative imc-content--display-flex imc-content--display-flex-gap-medium">
                    <div className="imc-content--flex-grow">
                        <input
                            className='imc-content--border-radius-4 imc-searchform--global imc-searchform--input imc-searchform--input'
                            type="text"
                            placeholder="Search vendors..."
                            onChange={(e) => handleSearch(e.target.value)}
                            ref={inputRef}
                            value={searchTerm}
                        />
                        <button
                            type="button"
                            disabled={false}
                            onClick={() => handleSearch('')}
                            aria-label={'Clear Search'}
                            className={`imc-searchform--button--clear`}
                        >
                            <svg
                                width="10"
                                height="10"
                                role="img"
                                aria-labelledby="clear-search-title clear-search-desc"
                            >
                                <title id="clear-search-title">{'Clear Search'}</title>
                                <desc id="clear-search-desc">{'Clear the Search Form'}</desc>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-close" />
                            </svg>
                        </button>
                        <button
                            className='imc-searchform--button--search'
                        >
                            <svg
                                width="16"
                                height="16"
                                role="img"
                                aria-labelledby="btn-search-title btn-search-desc"
                            >
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3" />
                            </svg>
                        </button>
                    </div>
                </div>
                {(sortLists.length > 0) &&
                    <div className="imc-content--full-width imc-breakpoint-display--mobile-only imc-content--display-flex imc-content--display-flex-wrap imc-content--display-flex-gap-medium">
                        <div className={`imc-content--flex-grow`}>
                            <button
                                className="imc-content--display-flex imc-content--display-flex-wrap imc-content--pointer imc-exhibitors-heading imc-filter__header imc-type--title-6"
                                onClick={(e) => toggleDrawer(e, !mobileExpanded)}
                            >
                                <div className="imc-content--z-1 imc-content--display-flex imc-content--display-flex-center">
                                    <SvgIcon width={16} height={16} xlinkHref={'#juniper-filter'} className={`svg-black `} useClassName={`svg-black`} />
                                    {getDictionaryValue('categoryFilters', 'Filters')}
                                </div>
                                {filtersCount > 0 ?
                                    <span className="imc-filter__header_count imc-section--padded-left-xlarge imc-section--padded-right-small">{`${filtersCount} ${getDictionaryValue('filtersSelected', 'Filters Selected')}`} </span> :
                                    <span className="imc-filter__header_nocount imc-section--padded-left-xlarge">{getDictionaryValue('noFiltersSelected', 'No Filters Selected')} </span>
                                }
                            </button>
                        </div>
                        <div className="">
                            <button
                                className='imc-searchform--bar--button'
                                onClick={() => clearQuery()}
                            >{'Reset'}</button>
                        </div>
                    </div>
                }
                {(sortLists.length > 0) && 
                    <div className={`imc-breakpoint-display--hide-mobile imc-content--flex-grow`}>
                        <div className={`imc-content--display-flex imc-content--display-flex-gap-large`}>
                            {renderSortLists(sortLists)}
                            <button
                                key={`clear-all-filters`}
                                className='imc-breakpoint-display--hide-mobile imc-searchform--bar--button'
                                onClick={() => clearQuery()}
                            >{'Reset'}</button>
                        </div>
                    </div>
                }
                {/* {renderCalendar()} */}
            </div>
            {(sortLists.length > 0) && 
                <div className={`imc-breakpoint-display--mobile-only`}>
                    <div
                        className={`imc-content--flex-grow imc-content ${mobileExpanded ? 'imc-expand-collapse--expanded' : 'imc-expand-collapse--expanded'}`}
                        data-xpath="filter.container"
                    >
                        <div className={`imc-filter__filters imc-expand-collapse__content ${(mobileExpanded) ? 'active' : ''} top-nav-dropdown left top-nav-drawer drawer-menu`}>
                            <div className={`top-nav-dropdown-menu top-nav-plan imc-section--basic-white imc-content--display-flex imc-content--display-flex-column`}>
                                <div className="imc-filter__mobileheader">
                                    <button
                                        className="imc-button imc-button--small imc-button--small-xy imc-button--radius-3 top-nav-plan-close"
                                        onClick={(e) => toggleDrawer(e, false)}
                                    >
                                        <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black `} useClassName={`svg-black`} />
                                    </button>
                                </div>
                                <div>
                                    <div className='imc-section--basic-white imc-breakpoint-display--mobile-only imc-filter--reset-button-container imc-content--display-flex-wrap imc-content--display-flex-gap-xsmall'>
                                        <p className='imc-section--basic-white imc-content--display-flex-break-mobile imc-filter--title'>Filters</p>
                                        <button
                                            className={`${(filtersCount > 0) ? 'imc-filter--clear-active' : ''} imc-filter--reset-button imc-link--hover-underline`}
                                            onClick={clearQuery}
                                        >
                                            {`Clear ${(filtersCount > 0) ? `All ${filtersCount} ` : ``}Filter${(filtersCount !== 1) ? `s` : ``}`}
                                        </button>
                                    </div>
                                    <div className="imc-section--basic-white imc-filter__filters-mobile-filter-wrapper imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-large imc-content--display-flex-row-gap-xsmall">
                                        {renderSortLists(sortLists)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
});
