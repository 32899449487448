import React, { useState, useEffect } from "react";
import { getEventTypes, getMultipleEventsDetails } from '../../api/events';
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import renderCarouselItem from './EventSlide';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Swiper } from 'swiper/react';
import UserUtility from 'utils/userutility';

interface FeaturedEventsProps {
  fields?: any;
}

const FeaturedEvents: React.FC<FeaturedEventsProps> = ({fields}) => {
    const [events, setEvents] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            const ids = (fields?.exhibitorEventIDs?.value && fields.exhibitorEventIDs.value != '') ? fields.exhibitorEventIDs.value.replace(' ','').replace('{','').replace('}','').split(',') : [];
            const [featuredEventsResponse, featuredByIdsResponse] = await Promise.all([
                fields?.onlyShowIDs.value == true ? Promise.resolve({ data: [] }) : getEventTypes("", true),
                ids.length > 0 ? getMultipleEventsDetails(ids, UserUtility.getChannelData().name) : Promise.resolve([])
            ]);
            const allEvents = [...featuredEventsResponse.data.filter((event: any) => event.eventOwner == 'IMC'), ...featuredByIdsResponse];
            const featuredEvents = allEvents.sort((a: any, b: any) => {
                return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()
            });
            setEvents(featuredEvents);
            setIsLoading(false);
        };

        fetchEvents().catch(console.error);
    }, []);

    const breakpoints = {
        '500': {slidesPerView: 2, spaceBetween: 25},
        '800': {slidesPerView: 3, spaceBetween: 25},
        '1024': {slidesPerView: 4, spaceBetween: 25},
    };

    const renderCarousel = () => {
        return (
            <div className="imc-section--padded-top-xlarge imc-section--padded-bottom-xlarge">
                <div className="event-features-carousel-component">
                    <h2 className="section-title">Featured Events</h2>

                    <div className="events-featured-swiper-container">
                        <Swiper
                            spaceBetween={25}
                            slidesPerView={1}
                            breakpoints={breakpoints}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                        >
                        {events.map(event => renderCarouselItem(event, fields))}
                        </Swiper>

                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        );
    };

    if (!fields) {
        if (isEditorActive()) {
            return <h1 className="alarm">Datasource isn't set.</h1>;
        }
        return (
            <h1 className="alarm">
                Data is not provided. Contact administrators, please.
            </h1>
        );
    }

    if (!isLoading) {
        return (
            <>
                {(events.length > 0) && <div>{renderCarousel()}</div>}
                {!(events.length > 0) && <></>}
            </>
        );
    } else {
        return (<></>);
    }
};

export default FeaturedEvents;

