import FiltersAPI from 'api/search/filters';
import * as types from './actionTypes';

const mock_response = { "filters": { "styles": { "Name": "Styles ", "DisplayName": "Style Filter", "subFilters": [] }, "additionalFilters": { "Name": "Additional Filters", "DisplayName": "Additional Filter Filter", "subFilters": [] }, "buyerPreferences": { "Name": "Buyer Preferences", "DisplayName": "Buyer Preference Filter", "subFilters": [] }, "pricePoints": { "Name": "Price Points", "DisplayName": "Price Point Filter", "subFilters": [] }, "locations": { "Name": "Locations ", "DisplayName": "Location Filter", "subFilters": [] }, "categories": { "Name": "Product Categories", "DisplayName": "Product Category Filter", "subFilters": [] } } };
/**
 * Instantiates the Filters API
 * @type {FiltersAPI}
 * @private
 */
const _filtersAPI = new FiltersAPI();

/**
 * Function to return a getFilters dispatcher
 * @param { string } filterKey key results were fetched for
 * @param { object } filterAPIResponse response returned from API
 * @param { function } dispatch function used to dispatch SET action
 */
function setFilters(filterKey, filterAPIResponse, dispatch) {
    let _resp = filterAPIResponse;// || mock_response;
    const { /*status, */filters, sortOptions } = _resp || {};

    if(filters != null && filters.categories!= null && filters.categories.subFilters != null)
        filters.categories.subFilters.sort(function(a, b) {
            if(a.displayName < b.displayName) { return -1; }
            if(a.displayName > b.displayName) { return 1; }
            return 0;
        }
    );

    return dispatch({
        type: types.SET_PRODUCT_FILTERS,
        filters: filters,
        filterKey: filterKey,
    });
    //const status =  200; 
    //if (status === 200) {

    // dispatch({
    //     type: types.SET_PRODUCT_SORT,
    //     sortOptions,
    // });
    // } else {
    //     // handle errors here
    //     dispatch({
    //         type: types.SET_PRODUCT_FILTERS_ERR,
    //         status,
    //     });
    // }

}

/**
 * Function to return a getFilters dispatcher
 * @param { string } filterKey fetch available filters for this value
 * @param { string } exhibitId exhibitor or line ID
 * @param { boolean } isLine determines if the item is a line
 * @returns {Function} Dispater used to getFilter options from API
 */
export function getFilters(filterKey, exhibitId, isLine, pageId, term, lineId) {
    switch (filterKey) {
        case 'exhibitors':
        case 'exhibitor-directory':
        case 'directory':
        case 'products':
        case 'lines':
            return dispatch => (
                _filtersAPI.getTaxonomyManagerFilters(filterKey, pageId, term, exhibitId, lineId)
                    .then((response) => {
                        return setFilters(filterKey, response, dispatch);
                    })
            );

        default:
            return dispatch => (
                _filtersAPI.getFilters(filterKey, exhibitId, isLine, pageId, term)
                    .then((response) => {
                        return setFilters(filterKey, response, dispatch);
                    })
            );
    }
}


/**
 * Function to return a getFilters dispatcher
 * @param { string } filterKey key results were fetched for
 * @param { object } filterAPIResponse response returned from API
 * @param { function } dispatch function used to dispatch SET action
 */
export function setFilterKey(filterKey) {
    const { /*status, */filters, sortOptions } = {};
    return (dispatch) => {
        //const status =  200; 
        //if (status === 200) {
        dispatch({
            type: types.SET_FILTER_KEY,
            filterKey

        });
    }
}

export const toggleMenu = () => ({
    type: 'TOGGLE_MENU',
});

export const openMenu = () => ({
    type: 'OPEN_MENU',
});

export const closeMenu = () => ({
    type: 'CLOSE_MENU',
});

export const setFiltersCount = (count) => {
    return {
        type: 'SET_FILTERS_COUNT',
        filtersCount: (count) ? count : 0
    }
};