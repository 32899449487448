import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'; // Import styles
import SvgIcon from 'modules/svgicon';
import { encode, decode } from 'utils/querystring';
import { normalizeDate, getLocalDate } from 'utils/dates';
  
const desktop = 992;

export default class CalendarRangeComponent extends Component {
  static propTypes = {
    events: PropTypes.array,
    formatDate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onResetDate: PropTypes.func.isRequired,
    router: PropTypes.object,
    dateRange: PropTypes.object,
    market: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      dateRange: null, // (this.props.dateRange) ? this.props.dateRange : null, // Holds the selected date range, initially no selection
      isCalendarOpen: false, // Holds the state of the calendar, initially closed
      isMarketVisible: false, // Holds the state of the market date, initially hidden
      windowWidth: 1300, // Holds the current window width
      top: 0,
      width: 350,
      market: null,
      load: 0,
      alwaysDropdown: props.alwaysDropdown || false,
    };

    this.ref = React.createRef();

    this.handleDateChange = this.handleDateChange.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
    this.returnValidMarketDate = this.returnValidMarketDate.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.checkSizing = this.checkSizing.bind(this);
  }

  componentDidMount() {
    const searchParams = decode(typeof window !== "undefined" ? this.props.router.location.search : null);
    if (searchParams.startdate || searchParams.enddate) {
      const startDate = searchParams.startdate ? normalizeDate(searchParams.startdate[0]) : null;
      const endDate = searchParams.enddate ? normalizeDate(searchParams.enddate[0], false) : null;
  
      if (startDate || endDate) {
        this.handleDateChange([startDate, endDate]);
      }
    }
    this.checkSizing()
    window.addEventListener('resize', this.checkSizing);
    window.addEventListener('pointerdown', this.checkSizing);
  }

  componentDidUnMount() {
    window.removeEventListener('resize', this.checkSizing);
    window.removeEventListener('pointerdown', this.checkSizing);
  }

  componentDidUpdate(prevProps) {
    if (this.props.dateRange !== prevProps.dateRange && (this?.props?.dateRange == null || this?.props?.dateRange?.length === 0)) {
      this.resetCalendar();
    }
    if (this?.props?.market?.displayName && (this.state.market == null || this.props.market.displayName !== this.state.market.displayName)) {
      this.setState({ market: this.props.market });
    }
    if  (this.state.windowWidth <= desktop || this.state.alwaysDropdown) {
      if (this.state.isCalendarOpen != this.state.isMarketVisible) setTimeout(() => {
        this.setState({ isMarketVisible: this.state.isCalendarOpen });
      }, (this.state.isMarketVisible) ? 500 : 0);
    }
  }

  checkSizing = (event) => {
    let shouldUpdate = false;
    if (event === undefined || (event && event.type === 'resize')) {
      shouldUpdate = true;
    } else if (event && this.ref?.current &&(event.target && this.ref.current.contains(event.target))) shouldUpdate = true;
    if (shouldUpdate) {
      // setTimeout(() => {
        if (this.state.windowWidth !== window.innerWidth) {
          this.setState({ windowWidth: window.innerWidth });
        }
        let calendar = document.querySelector('.react-daterange-picker__calendar');
        let daterange = document.querySelector('.react-daterange-picker__wrapper');
        if (calendar && daterange && (window.innerWidth < desktop || this.state.alwaysDropdown)) {
          this.setState({
            top: parseFloat(
                (calendar.getBoundingClientRect().height + daterange.getBoundingClientRect().height).toFixed(2)
            ),
            width: parseFloat(
                (daterange.getBoundingClientRect().width).toFixed(2)
            ),
          });
        }
      // }, 100);
    }
  }
  
  returnValidMarketDate(date) {
    const { market } = this.state;
    const marketLoaded = (market?.from && market?.to);
    let className = '';
    if (!this.props.events.some((eventItem) => {
        const start = new Date(eventItem.startDateTime);
        const end = new Date(eventItem.endDateTime);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        return (date >= start && date <= end)
    })) {
      className += 'react-calendar--no-event';
    }
    if (marketLoaded) {
      if (date >= getLocalDate(market.from) && date <= getLocalDate(market.to)) {
        className += ' react-calendar--market-date';
      }
    }
    return className;
  }

  handleDateChange(range) {
    this.setState({ dateRange: range });
    if (range && range[0] && range[1]) {
      if (this.props.router) {
        let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
        searchParams['startdate'] = format(range[0], 'yyyy-MM-dd');
        searchParams['enddate'] = format(range[1], 'yyyy-MM-dd');
        this.props.router.navigate({search: encode(searchParams, false, false)});
      }
      const formattedRange = {
        start: this.props.formatDate(range[0]), // Format the start date
        end: this.props.formatDate(range[1]), // Format the end date
      };
      this.props.onChange(formattedRange); // Pass the formatted range to parent
    } else {
        this.props.onResetDate(); // Notify parent to reset
        if (this.props.router) {
          let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
          if (searchParams.startdate || searchParams.enddate) {
            if (searchParams.startdate) delete searchParams.startdate;
            if (searchParams.enddate) delete searchParams.enddate;
            this.props.router.navigate({search: encode(searchParams, false, false)});
          }
        }
    }
  }

  resetCalendar() {
    this.setState({ dateRange: null }); // Clear the range
    this.props.onResetDate(); // Notify parent to reset
  }

  toggleCalendar(isOpen=undefined) {
    this.setState((prevState) => ({
      isCalendarOpen: (isOpen != undefined) ? isOpen : !prevState.isCalendarOpen,
    }));
  }

  render() {
    const { load, market, windowWidth, dateRange, isCalendarOpen, isMarketVisible, alwaysDropdown } = this.state;

    return (
      <div className="imc-content--relative react-calendar--container-wrapper" ref={this.ref}>
        {(!dateRange && !isCalendarOpen) && <div className="calendar-header calendar-range-input imc-content--display-flex imc-content--display-flex-center" onClick={this.toggleCalendar}>
          <SvgIcon width={18} height={18} xlinkHref={'#juniper-calendar'} className={`svg-black imc-content--visibility-hidden`} useClassName={`svg-black`} />
          <span className={`imc-section--basic-white imc-type--color-neutral-medium imc-content--flex-grow imc-section--margin-left-mediumsmall imc-section--margin-right-mediumsmall`}>Select Dates</span>
          <SvgIcon width={10} height={10} xlinkHref={'#juniper-close'} className={`svg-black imc-content--visibility-hidden`} useClassName={`svg-black`} />
        </div>}
        <div className={`calendar-container imc-content--relative}`} key={load}>
          <DateRangePicker
            value={dateRange}
            onChange={this.handleDateChange}
            calendarType="US"
            isOpen={(windowWidth > desktop && (!alwaysDropdown)) ? true : isCalendarOpen}
            minDetail={'year'}
            calendarIcon={<SvgIcon width={18} height={18} xlinkHref={'#juniper-calendar'} className={`svg-black imc-section--margin-left-nano`} useClassName={`svg-black`} />}
            clearIcon={<SvgIcon width={10} height={10} xlinkHref={'#juniper-close'} className={`svg-black imc-section--margin-right-nano`} useClassName={`svg-black`} />}
            className={`${(windowWidth > desktop && (!alwaysDropdown)) ? `keep-open` : `${(alwaysDropdown) ? 'always-dropdown' : ''}`}`}
            tileClassName={({ date }) => this.returnValidMarketDate(date)}
            onResetDate={this.props.onResetDate}
            closeCalendar={(windowWidth > desktop && (!alwaysDropdown)) ? false : true}
            onCalendarClose={() => {this.toggleCalendar(false)}}
            onCalendarOpen={() => {this.toggleCalendar(true)}}
          />
          {(market?.displayName && ((windowWidth > desktop && (!alwaysDropdown)) ? true : isMarketVisible)) &&
            <div
              className="calendar-market imc-section--padded-small imc-section--padded-left-small imc-section--padded-right-small imc-section--basic-white imc-content--display-flex imc-content--display-flex-gap-small imc-content--display-flex-center"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (getLocalDate(market.from) || getLocalDate(market.to)) {
                  this.handleDateChange([getLocalDate(market.from), getLocalDate(market.to)]);
                  this.setState({ load: load + 1 });
                }
              }}
              style={{ top: this.state.top, maxWidth: (windowWidth > desktop && (!alwaysDropdown)) ? '100%' : this.state.width }}
            >
              <div className="imc-content--display-flex imc-content--display-flex-center">
                <svg className={'svg-market-dates'} height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <circle r="12" cx="12" cy="12" />
                </svg>
              </div>
              <span className={``}>{market.displayName}</span>
            </div>
          }
        </div>
      </div>
    );
  }
}