import React from 'react';
import {isEditorActive, withSitecoreContext, Text} from '@sitecore-jss/sitecore-jss-react';
import {withSitecoreRouter} from 'utils/withRouter';
import ExhibitorApi from '../../api/exhibitor';
import ExhibitorAd from './components/exhibitorad.jsx';
import qs from 'query-string';
import {SEARCH_TYPE_EXHIBITOR, SEARCH_TYPE_PRODUCT, getFilterKeyFromPath} from 'utils/search'

class ExhibitorAdComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            pathname: this.props.router.location.pathname
        };
    }

    componentDidMount() {
        const {fields} = this.props;
        //If there is a querystring, we have a search. This component should hide.
        this.updateVisibilityState(this.props);

        if (fields && !!fields.data && fields.data.model) {
            //If we got row, we need to grab each one of the exhibitors in there to fetch details and pass down to the components.
            var exhibitorIds = [];
            fields.data.model.rows.forEach((row, index) => {
                row.exhibitors.forEach((exhibitor, index) => {
                    if (exhibitor?.featureExhibitorsId?.data?.value) {
                        exhibitorIds.push(exhibitor.featureExhibitorsId.data.value);
                    }
                });
            })
            if (exhibitorIds.length > 0) this.fetchData(exhibitorIds);
        }
    }

    updateVisibilityState(props) {
        const qsParsed = qs.parse(props.router.location.search, {ignoreQueryPrefix: true});
        const query = qsParsed.q;
        const filterType = getFilterKeyFromPath(props.router.location.search) || this.props.router.location.pathname.split('/').pop();
        const filters = qsParsed[filterType] ? qs.parse(qsParsed[filterType]) : null;

        const visible = this.checkIfShouldShow(query, filters);
        if (visible !== this.state.visible) this.setState({'visible': visible});
    }

    componentWillReceiveProps(nextProps) {
       if (nextProps.router.location.pathname == this.state.pathname) this.updateVisibilityState(nextProps);
    }

    checkIfShouldShow(query, filters) {
        return ((typeof query === 'undefined') || query === null || query ==="")
            && ((typeof filters === 'undefined') || filters === null || Object.keys(filters).length === 0);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.router.location.pathname == this.state.pathname) this.updateVisibilityState(nextProps);
    }

    fetchData(exhibitorIds) {
        const exhibitorApi = new ExhibitorApi();
        const pageId = this.props.sitecoreContext.route.itemId;
        exhibitorApi.getExhibitorDetail(exhibitorIds, pageId).then((response) => {
            this.setState({
                exhibitorData: response.data
            });
        })
    }

    render() {
        const {fields, rendering} = this.props;
        if (isEditorActive()) { // Experience editor view
            if (!fields) {
                return <h1 className="alarm">Datasource isn't set.{JSON.stringify(this.props)}</h1>;
            } else if (!fields.data) {
                return <h1 className="alarm">No data provided</h1>;
            }
        }
        return (<>
                {/*TODO: Verify design. No title or subheading defined in mock.  */
                    this.state.visible &&
                    <div>
                        {(fields?.data?.model?.title?.data?.value) &&
                        <h3>{fields.data.model.title.data.value}</h3>
                        }
                        {(fields?.data?.model?.subtitle?.data?.value) &&
                        <p className="imc-bosy">{fields.data.model.subtitle.data.value}</p>
                        }
                        <div className="imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-xsmall">
                            {
                                (!!fields && !!fields.data && fields.data.model) && this.renderRows(fields.data.model.rows)
                            }
                        </div>
                    </div>
                }
            </>
        );
    }

    renderRows(model) {
        return (model.map((row, index) => {
            let columnClass = `imc-gallery--1-${Math.max(2, row.exhibitors.length)}`;
    
            switch (row.exhibitors.length) {
                case 2:
                    columnClass = 'imc-gallery--1-2 imc-gallery--1-2--tablet imc-gallery--1-1--mobile';
                    break;
                case 3:
                    columnClass = 'imc-gallery--1-3 imc-gallery--1-3--tablet imc-gallery--1-1--mobile';
                    break;
                case 4:
                    columnClass = 'imc-gallery--1-4 imc-gallery--1-4--tablet imc-gallery--1-2--mobile';
                    break;
                case 5:
                    columnClass = 'imc-gallery--1-5 imc-gallery--1-3--tablet imc-gallery--1-2--mobile';
                    break;
                case 6:
                    columnClass = 'imc-gallery--1-6 imc-gallery--1-3--tablet imc-gallery--1-2--mobile';
                    break;
                default:
                    let tabletCount = Math.ceil(1.0 * row.exhibitors.length / 2.0)
                    let mobileCount = Math.ceil(1.0 * tabletCount / 2.0)
                    columnClass = `imc-gallery--1-${Math.max(2, row.exhibitors.length)} imc-gallery--1-${tabletCount}--tablet imc-gallery--1-${mobileCount}--mobile`;
                    break
            }
    
            return (
                <div key={index}
                     className={`imc-gallery imc-gallery--xsmall-padded ${columnClass} imc-gallery--center`}>
                    {this.renderRow(row)}
                </div>
            );
        }));
    }
    

    renderRow(row) {
        const props = this.props;
        const exhibitorData = this.state.exhibitorData;
        return (row.exhibitors.map((exhibitor, index) => {
            return (
                <ExhibitorAd {...props} exhibitorData={exhibitorData} exhibitor={exhibitor} key={index}/>
            );
        }));
    }

}

let initialState = {
    exhibitorData: [],
    visible: false
}

export default withSitecoreContext()(withSitecoreRouter(ExhibitorAdComponent));
