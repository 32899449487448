import React from 'react';

interface HrProps {
    fields: {
        color?: { value?: string },
        override?: { value?: string },
    };
}

const Hr: React.FC<HrProps> = ({ fields }) => {
    const { color, override } = fields;
    const styles = {
        '--hr-bg': (override?.value) ? (override.value) : (color?.value) ? `var(--${color.value})` : `var(--neutral-heaviest)`,
    } as React.CSSProperties;
    return <hr className="imc-hr imc-hr--full-width imc-hr--3 imc-hr--var imc-section--margin-bottom-large imc-section--margin-top-large" style={styles} />;
};

export default Hr;
