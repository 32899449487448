import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import './assets/app.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/common/scss/base/_toast.css';
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({route, siteName, isPageEditing, colors}) => {
  let mobileAppStyle = '';
  if (route.placeholders["jss-header"] && route.placeholders["jss-header"].find(x => x.componentName == "Top-Header-Component") != null && route.placeholders["jss-header"].find(x => x.componentName == "Top-Header-Component").fields["isMobileApp"] && route.placeholders["jss-header"].find(x => x.componentName == "Top-Header-Component").fields["isMobileApp"].value == true)
    mobileAppStyle = 'imc-header__sticky imc-header__whiteBackground';
  if (isPageEditing) {
    if (route?.placeholders?.['jss-main']) {
      console.log(`Experience Editor Open for Page - ${route.name}`);
    } else if (route?.placeholders?.['imc-snippet-content']) {
      console.log(`Experience Editor Open for Snippet - ${route.name}`);
    }
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields["Page Title"] && route.fields["Page Title"].value) || route.displayName}
        </title>
        <meta name="description" content={route.fields?.["Page Description"]?.value || route.fields?.["Summary"]?.value || route.displayName} />
        <meta property="og:title" content={route.fields?.["OG Title"]?.value || route.displayName} />
        <meta property="og:description" content={route.fields?.["OG Description"]?.value || route.fields?.["Summary"]?.value || route.fields?.["Page Description"]?.value || route.displayName} />
        <meta property="og:image" content={route.fields?.["Preview Image"]?.value?.src || route.fields?.["OG Image"]?.value?.link || route.fields?.["OG Image"]?.value?.src || route.displayName} />
        {/*<meta property="og:url" content={(route.fields && route.fields["Page Description"] && route.fields["Page Description"].value) || route.displayName } />*/}
        <link href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + (((typeof window !== 'undefined') && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) ? 'shared.css' : `shared.__webpack_hash__.css`)} rel='stylesheet' />
          <link href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + (((typeof window !== 'undefined') && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) ? '/style.css' : `/style.__webpack_hash__.css`)} rel='stylesheet' />


        <link rel="apple-touch-icon" sizes="180x180" href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/favicon-16x16.png`} />
        <link rel="mask-icon" href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/safari-pinned-tab.svg`} color="#da532c" />
        <link rel="shortcut icon" href={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/favicon.ico`} />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-config" content={(process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/dist/las-vegas-market') + `/assets/` + siteName + `/browserconfig.xml`} />
        <meta name="theme-color" content="#ffffff"></meta>
        <script async defer src="//assets.pinterest.com/js/pinit.js"></script>
          {colors.length > 0 && <style>{`html:root {${colors.map(color => {
              return `--${color.colorName}: ${color.hexColor};`
          }).join('')}}`}</style>}
          {route?.fields?.colors?.length > 0 && <style>{`html:root {${route.fields.colors.map(color => {
              return `--${color.fields.colorName.value}: ${color.fields.hexColor.value};`
          }).join('')}}`}</style>}
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />

    {/* root placeholder for the app, which we add components to using route data */}
    {(route?.placeholders?.['jss-top']) && <Placeholder name="jss-top" rendering={route} />}
    <header className={`imc-header ${mobileAppStyle}`} id={`header`}>
      {(route?.placeholders?.['jss-header']) && <Placeholder name="jss-header" rendering={route} />}
    </header>
    <section className="imc-site-wrapper">
      {(route?.placeholders?.['jss-main']) && <Placeholder name="jss-main" rendering={route} />}
    </section>
    {(route?.placeholders?.['jss-footer']) && <Placeholder name="jss-footer" rendering={route} />}
    {(route?.placeholders?.['imc-snippet-content']) && <Placeholder name="imc-snippet-content" rendering={route} />}
  </React.Fragment>
  )
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
