import React from 'react';
import { TableWrapperProps } from '../types/Table.types';

const TableWrapper: React.FC<TableWrapperProps> = ({ children, model, tableClass, style, outerWrapperClass, wrapperClass }) => {

    const getStyles = (model: any, style?: Record<string, string | number>) => {
        let variables = style || {};
        if (model?.thBg?.value) variables['--th-bg'] = model.thBg.value;
        if (model?.thColor?.value) variables['--th-color'] = model.thColor.value;
        if (model?.trOdd?.value) variables['--tr-odd'] = model.trOdd.value;
        if (model?.trEven?.value) variables['--tr-even'] = model.trEven.value;
        if (model?.trColor?.value) variables['--tr-color'] = model.trColor.value;
        if (model?.desktopHeight?.value) variables['--desktop-height'] = model.desktopHeight.value;
        if (model?.mobileHeight?.value) variables['--mobile-height'] = model.mobileHeight.value;
        if (model?.desktopWidth?.value) variables['--desktop-width'] = model.desktopWidth.value;
        if (model?.mobileWidth?.value) variables['--mobile-width'] = model.mobileWidth.value;
        return variables;
    }

    let extraClasses = '';
    if (model?.theme?.targetItems && model.theme.targetItems.length > 0) {
        model.theme.targetItems.forEach((item: any, i: number) => {
            if (item?.field?.value) {
                extraClasses += ` ${item.field.value}`;
            }
        });
    }

    let tableStyles = getStyles(model, style);

    return (
        <div className={`${outerWrapperClass} ${extraClasses}`} style={tableStyles}>
            <div className={`${wrapperClass} ${extraClasses}`}>
                <table className={`${tableClass} ${extraClasses}`}>
                    {children}
                </table>
            </div>
        </div>
    );
};

export default TableWrapper;
