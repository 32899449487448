import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { withSitecoreRouter } from 'utils/withRouter';
import { withSitecoreContext,  } from '@sitecore-jss/sitecore-jss-react';
import { parse, format, formatISO } from 'date-fns';

import _ from 'lodash';
import { formatConsistentDate, getLocalDate } from 'utils/dates';
import CalendarRange from 'modules/calendar/CalendarRange';
import TableWrapper from '../Table-Component/components/Wrapper';
import { encode, decode } from 'utils/querystring';
import { getDictionaryValue } from 'utils/dictionary';

import { RenderSearch } from '../../modules/contentSearchWrapper';
import Building from './Components/Building';

const cleanValue = (value: string) => value.replace(/[^a-zA-Z0-9 ]/g, '').trim().replace(/\s+/g, '-').toLowerCase();

const formatDateTime = (date: any) => getLocalDate(formatISO(parse(date, "yyyyMMdd'T'HHmmssX", new Date())));

const formatDateToICS = (date: Date): string => format(date, "yyyyMMdd'T'HHmmss'Z'");

const getAllDays = (dates: {
    start: any,
    end: any
}[]) => {
    let allDays: any[] = [];
    dates.forEach((dateRange: {
        start: any,
        end: any
    }) => {
        const start = new Date(dateRange.start.setHours(0, 0, 0, 0));
        const end = new Date(dateRange.end.setHours(0, 0, 0, 0));
        let currentDate = start;
        while (currentDate <= end) {
            const newDate = new Date(currentDate).setDate(currentDate.getDate() + 1);
            if (!allDays.find((day: any) => day.getTime() === currentDate.getTime())) {
                allDays.push(new Date(currentDate));
            }
            currentDate = new Date(newDate);
        }
    });
    return allDays;
};

const removePastDates = (input_times: any[]) => {
    const times = [...input_times];

    return times.filter((time: any) => {
        let today = new Date().setHours(0, 0, 0, 0);
        if (time?.startDateTime?.value && time?.endDateTime?.value) {
            const startDate = new Date(new Date(formatDateTime(time.startDateTime.value))).setHours(0, 0, 0, 0);
            const endDate = new Date(new Date(formatDateTime(time.endDateTime.value))).setHours(0, 0, 0, 0);
            if (startDate < today && endDate < today) {
                return false;
            }
        } else if (time?.startDateTime?.value && !time?.endDateTime?.value) {
            const startDate = new Date(new Date(formatDateTime(time.startDateTime.value))).setHours(0, 0, 0, 0);
            if (startDate < today) {
                return false;
            }
        } else {
            if (time?.note?.value) {
                return true;
            }
        }
        return true;
    }).map((time: any) => {
        if (time?.startDateTime?.value) {
            let today = new Date().setHours(0, 0, 0, 0);
            let start = new Date(formatDateTime(time.startDateTime.value));
            while (new Date(start) < new Date(today)) {
                start = new Date(new Date(start).setDate(start.getDate() + 1));
            }
            time.startDateTime.value = formatDateToICS(start);
        }
        return time;
    });
};

const collapseDateTimes = (input_times: any[]) => {
    if (!input_times.length) return [];

    const times = [...input_times];

    let collapsedTimes: any[] = [];
    let currentTime = times[0];
    let increment = 1;
    let collapsing = false;

    times.forEach((time: any, t: number) => {
        const nextTime = times[t + 1];
        
        if (!nextTime) {
            if (increment > 1) {
                const newEndDate = new Date(formatDateTime(currentTime.endDateTime.value));
                newEndDate.setDate(newEndDate.getDate() + (increment - 1));
                currentTime.endDateTime.value = formatDateToICS(newEndDate);
            }
            collapsedTimes.push(currentTime);
            return;
        }

        if (
            currentTime.startDateTime.value === "" ||
            currentTime.endDateTime.value === "" ||
            nextTime.startDateTime.value === "" ||
            nextTime.endDateTime.value === ""
        ) {
            if (increment > 1) {
                const newEndDate = new Date(formatDateTime(currentTime.endDateTime.value));
                newEndDate.setDate(newEndDate.getDate() + (increment - 1));
                currentTime.endDateTime.value = formatDateToICS(newEndDate);
            }
            collapsedTimes.push(currentTime);
            currentTime = nextTime;
            increment = 1;
            collapsing = false;
        } else {
            const currentStartDate = formatDateTime(currentTime.startDateTime.value);
            const currentEndDate = formatDateTime(currentTime.endDateTime.value);
            let incrementedStartDate = new Date(currentStartDate);
            let incrementedEndDate = new Date(currentEndDate);
            const nextStartDate = formatDateTime(nextTime.startDateTime.value);
            const nextEndDate = formatDateTime(nextTime.endDateTime.value);

            incrementedStartDate.setDate(incrementedStartDate.getDate() + increment);
            incrementedEndDate.setDate(incrementedEndDate.getDate() + increment);
            
            if (
                incrementedStartDate.getTime() === nextStartDate.getTime() &&
                incrementedEndDate.getTime() === nextEndDate.getTime()
            ) {
                currentTime.note.value = (currentTime.note.value) ? `${currentTime.note.value} ${nextTime.note.value}` : nextTime.note.value;
                increment += 1;
                collapsing = true;
            } else {
                if (increment > 1) {
                    const newEndDate = new Date(formatDateTime(currentTime.endDateTime.value));
                    newEndDate.setDate(newEndDate.getDate() + (increment - 1));
                    currentTime.endDateTime.value = formatDateToICS(newEndDate);
                }
                if (!collapsing  || increment > 1) {
                    collapsedTimes.push(currentTime);
                };
                currentTime = nextTime;
                increment = 1;
                collapsing = false;
            }
        }
    });

    return collapsedTimes;
};


const DiningGuide = (props: any) => {

    const initialBuildings = useMemo(() => {
        return _.cloneDeep(props.fields.data.model.buildings).map((building: any) => ({
            ...building,
            floors: building.floors.map((floor: any) => ({
                ...floor,
                vendors: floor.vendors.map((vendor: any) => {
                    let vendorTimes = removePastDates(vendor.times);
                    let days = vendorTimes.filter((time: any) => time.startDateTime.value && time.endDateTime.value).map((time: any) => {
                        return {
                            start: formatDateTime(time.startDateTime.value),
                            end: formatDateTime(time.endDateTime.value),
                        }
                    });
                    return {
                        ...vendor,
                        times: collapseDateTimes(vendorTimes),
                        days: getAllDays(days).map((date: any) => format(date, "yyyyMMdd")),
                    }
                }).filter((vendor: any) => vendor.times.length > 0)
            })).filter((floor: any) => floor.vendors.length > 0)
        })).filter((building: any) => building.floors.length > 0);
    }, [props.fields.data.model.buildings]);
    const [searchParams, setSearchParams] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [buildingQuery, setBuildingQuery] = useState<string>('');
    const [optionQuery, setOptionQuery] = useState<string>('');
    const [dateQuery, setDateQuery] = useState<any>(null);
    const [buildings, setBuildings] = useState<any[]>(initialBuildings);
    const [refresh, setRefresh] = useState<number>(0);
    const [filtersCount, setFilterCount] = useState<number>(0);

    const [selectedCalendarDateRange, setSelectedCalendarDateRange] = useState<any>(null);

    let optionList: {key: string, value: string}[] = [{
        key: '',
        value: 'Select a Dining Option'
    }];

    let buildingList: {key: string, value: string}[]  = [{
        key: '',
        value: 'Select a Building'
    }];

    let dateList: {key: string, value: string}[]  = [{
        key: '',
        value: 'Select a Date'
    }];

    let dates: {start: any, end: any}[] = [];

    initialBuildings.forEach((building: any) => {
        const buildingKey = cleanValue(building.title.value);
        const buildingItem = {
            key: buildingKey,
            value: building.title.value
        };
        if (!(_.some(buildingList, buildingItem))) {
            buildingList.push(buildingItem);
        }
        building.floors.forEach((floor: any) => {
            floor.vendors.forEach((vendor: any) => {
                vendor.tags.targetItems.forEach((tag: any) => {
                    const optionKey = cleanValue(tag.field.value);
                    const optionItem = {
                        key: optionKey,
                        value: tag.field.value
                    };
                    if (!(_.some(optionList, optionItem))) {
                        optionList.push({
                            key: optionKey,
                            value: tag.field.value
                        });
                    }
                });
                vendor.times.forEach((time: any) => {
                    if (time.startDateTime.value && time.endDateTime.value) {
                        dates.push({
                            start: formatDateTime(time.startDateTime.value),
                            end: formatDateTime(time.endDateTime.value),
                        });
                    }
                });
            });
        });
    });

    // dont show the building filter if there is only 1 building
    if (buildingList.length == 2) {
        buildingList = [];
    }

    getAllDays(dates).forEach((date: any) => {
        dateList.push({
            key: format(date, "yyyyMMdd"),
            value: formatConsistentDate(date)
        });
    });

    useEffect(() => {
        let params = decode(typeof window !== "undefined" ? props.router.location.search : '') as Record<string, any>;
        const checkfilterCount = (params.building ? 1 : 0) + (params.option ? 1 : 0) + (params.search ? 1 : 0) + (params.date ? 1 : 0);
        if (checkfilterCount != filtersCount) setFilterCount(checkfilterCount);
    }, []);

    useEffect(() => {
        let params = decode(typeof window !== "undefined" ? props.router.location.search : '') as Record<string, any>;
        const checkfilterCount = (params.building ? 1 : 0) + (params.option ? 1 : 0) + (params.search ? 1 : 0) + (params.date ? 1 : 0);
        if (checkfilterCount != filtersCount) setFilterCount(checkfilterCount);
        if (params) {
            if (params.building && buildingQuery != params.building) {
                setBuildingQuery(params.building[0]);
            } else if (!params.building && buildingQuery) {
                setBuildingQuery('');
            }
            if (params.option && optionQuery != params.option) {
                setOptionQuery(params.option[0]);
            } else if (!params.option && optionQuery) {
                setOptionQuery('');
            }
            if (params.search && query != params.search) {
                setQuery(params.search[0]);
            } else if (!params.search && query) {
                handleSearch('');
                setQuery('');
            }
            if (params.date && dateQuery != params.date) {
                setDateQuery(params.date[0]);
            } else if (!params.date && dateQuery) {
                setDateQuery('');
            }
        }
        if (props.router.location.search !== searchParams) setSearchParams(props.router.location.search);
    }, [props.router.location.search]);

    useEffect(() => {
        let params = decode(typeof window !== "undefined" ? props.router.location.search : '') as Record<string, any>;
        if (params) {
            if (params.search && query != params.search) {
                setSearchTerm(params.search[0]);
            }
        }
    }, []);

    useEffect(() => {
        setRefresh(refresh + 1);
        setBuildings(initialBuildings.filter((building: any) => {
            return !(buildingQuery) || ((buildingQuery) && cleanValue(building.title.value).includes(buildingQuery.toLowerCase()));
        }).map((building: any) => {return {
            ...building,
            floors: building.floors.map((floor: any) => {return {
                ...floor,
                vendors: floor.vendors.filter((vendor: any) => {
                    let isOptionMatched = false;
                    let isQueryMatched = false;
                    let isDateMatched = false;
                    if (!optionQuery && !query && !dateQuery) return true;
                    if (optionQuery) {
                        vendor.tags.targetItems.forEach((tag: any) => {
                            if (cleanValue(tag.field.value).includes(optionQuery)) {
                                isOptionMatched = true;
                            }
                        });
                    } else {
                        isOptionMatched = true
                    }
                    if (query) {
                        const vendorText = `${vendor.title.value} ${vendor.description.value} ${vendor.tags.targetItems.map((tag: any) => tag.field.value).join(' ')} ${vendor.times.map((time: any) => time.note.value || '' ).join(' ')}`;
                        if (vendorText.toLowerCase().includes(query.toLowerCase())) {
                            isQueryMatched = true;
                        }
                    } else {
                        isQueryMatched = true;
                    }
                    if (dateQuery) {
                        isDateMatched = vendor.days.includes(dateQuery);
                    } else {
                        isDateMatched = true;
                    }
                    return isQueryMatched && isOptionMatched && isDateMatched;
                })
            }}).filter((floor: any) => floor.vendors.length > 0)
        }}).filter((building: any) => building.floors.length > 0));
    }, [query, buildingQuery, optionQuery, dateQuery]);

    const onClickDay = (date: any) => {
        setSelectedCalendarDateRange(date);
    };

    const onResetDate = (date: any) => {
        setSelectedCalendarDateRange(null);
    };

    const renderCalendar: () => JSX.Element = useCallback(() => {
        const currentlySelectedEvents: any[] = [];
        return (
            <CalendarRange
                events={currentlySelectedEvents}
                onClickDay={onClickDay}
                onChange={onClickDay}
                formatDate={formatConsistentDate}
                onResetDate={onResetDate}
                router={props.router}
                dateRange={selectedCalendarDateRange}
                market={null}
                alwaysDropdown={true}
            />
        )
    }, [props.router]);

    const updateSearch = (value: string) => {
        let params = decode(typeof window !== "undefined" ? props.router.location.search : '') as Record<string, any>;
        if (params.search !== value) {
            params.search = value;
            if (params.search === "") {
                delete params.search;
            }
            props.router.navigate({search: encode(params, false, false)});
        }
    }

    const handleSearch = (value: string) => {
        updateSearch(value);
        setSearchTerm(value || '');
    };

    return (
        <div className={`dining-guide imc-section imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--display-flex-column imc-section--padded-top-jumbo imc-section--padded-bottom-jumbo imc-section--padded-left-xlarge imc-section--padded-right-xlarge`}>
            {initialBuildings.length != 0 && <RenderSearch
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                sortLists={[
                    {type: 'option', options: optionList},
                    {type: 'building', options: buildingList},
                    {type: 'date', options: dateList}
                ]}
                renderCalendar={renderCalendar}
                filtersCount={filtersCount}
                router={props.router}
            />}
            <TableWrapper model={props.model} tableClass="" style={{}}>
                <div className={`imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-large`}>
                    {buildings.map((building: any, buildingIndex: number) => Building(building, buildingIndex, refresh))}
                    {initialBuildings.length == 0 &&
                        <div className={`imc-section--padded-top-large imc-section--padded-bottom-large imc-content--display-flex-justify-center`}>
                            <h3 className={`imc-content--center`}>{getDictionaryValue('diningEmpty', 'Check back closer to market for dining options.')}</h3>
                        </div>
                    }
                    {(buildings.length == 0 && initialBuildings.length != 0) &&
                        <div className={`imc-section--padded-top-large imc-section--padded-bottom-large imc-content--display-flex-justify-center`}>
                            <h3 className={`imc-content--center`}>{getDictionaryValue('diningNoResults', 'No Matching Results.')}</h3>
                            <br />
                            <div className="imc-content--display-flex imc-content--display-flex-justify-center">
                                <button
                                    className='imc-searchform--bar--button'
                                    onClick={() => {
                                            let params = {}
                                            if (props.router) {
                                                props.router.navigate({search: encode(params, false, false)});
                                            };
                                        }}
                                >{'Clear Search'}</button>
                            </div>
                        </div>
                    }
                </div>
            </TableWrapper>
        </div>
    );
}

export default (withSitecoreContext()(withSitecoreRouter(DiningGuide)));
