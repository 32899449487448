import React, { ReactNode } from 'react';
import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Pagination from 'modules/search/Pagination.jsx';
import SearchResultsColumn from './components/SearchResultsColumn';

type SearchResultsWrapperProps = {
  rendering: ComponentRendering;
  children?: ReactNode;
  renderResults: () => ReactNode;
  gridClass?: string;
  isPageEditing?: boolean;
  numberOfPages?: number | null;
  searchPlaceholder?: string;
};

const SearchResultsWrapper: React.FC<SearchResultsWrapperProps> = (props) => {
  const {
    rendering,
    children,
    renderResults,
    gridClass = '',
    isPageEditing = false,
    numberOfPages = null,
    searchPlaceholder = 'imc-typeahead',
  } = props;
  return (
    <div
      className={`imc-content--display-flex imc-content--display-flex-gap-xlargemedium imc-content--display-flex-gap-xlargemedium imc-gallery--search imc-vr--collosal ${gridClass}`}
    >
      {/* Left panel */}
      <div className="imc-gallery__item">
        <Placeholder name="imc-category-filter" rendering={rendering} />
      </div>

      {/* Middle customizable content */}
      <SearchResultsColumn {...props} />
    </div>
  );
};

export default SearchResultsWrapper;
