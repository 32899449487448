// Library dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import qs from 'query-string';
import find from 'lodash/find';
import deepEqual from 'deep-equal';
import { withSitecoreRouter } from 'utils/withRouter';

// Utils
import { encode, decode } from 'utils/querystring';
import { cleanObjKeys } from 'utils/cleanObjKeys';
import { mobileScreenUtility } from 'utils/mobileScreenUtility';
import { replaceparams } from 'utils/replaceparams';
import { resetPagination } from 'utils/resetpagination';
import { getDictionaryValue } from 'utils/dictionary';

// Module dependencies
import FilterWrapper from './FilterWrapper';
import FilterItem from './FilterItem';
import * as filterActions from "../../../components/Results-Filter/actions/filterActions";
import SvgIcon from 'modules/svgicon';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import { padContent, unPadContent } from "utils/scrollhelpers";
import Link from '../../../components/Link';

/**
 * String representing sortorder
 */
const SORT_ORDER = 'sortorder';

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{location, history, clearAction: *, labelClear: *, filters: *, resultTotal: *, fetchData: *,
 * storeFilterAction: *, match, filterKey}}
 */
const propTypes = {
    setSelectedFilterCount: PropTypes.func,
    toggleFiltersOpen: PropTypes.func,
    router: PropTypes.object.isRequired,
    clearAction: PropTypes.func,
    labelClear: PropTypes.string, // Label: Clear filters
    filters: PropTypes.object, // array for creating filter selections
    resultTotal: PropTypes.number, // Number of results
    storeFilterAction: PropTypes.func,
    match: PropTypes.object.isRequired,
    filterKey: PropTypes.string.isRequired,
    isLvdcOnly: PropTypes.bool,
    lvdcExhibitorLabels: PropTypes.object,
    filtersOpen: PropTypes.bool,
    searchLabels: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {
 * {labelClear: string, resultTotal: number, filters: null, clearAction: null,
 * storeFilterAction: null, match: {path: string, params: {}}, fetchData: function()}
 * }
 */
const defaultProps = {
    setSelectedFilterCount: null,
    toggleFiltersOpen: null,
    filtersOpen: false,
    labelClear: 'Clear All Filters',
    resultTotal: 0,
    filters: null,
    clearAction: null,
    storeFilterAction: null,
    match: {
        path: '',
        params: {},
    },
    isLvdcOnly: false,
    lvdcExhibitorLabels: {
        offeringFilterKey: 'specialServiceOfferings',
        lvdcTag: 'LVDC',
    },
    searchLabels: {},
};

/**
 * clear pagenum from the basepath
 * and replace url params with values
 * @param {object} match url match object
 * @returns {string} path with filled in param values
 */
function cleanedBasepath(match) {
    const updatedParams = {
        ...match.params,
        pagenum: '',
    };
    return replaceparams(match.url, updatedParams);
}

/**
 * Component for displaying a Filter module and maintaining its state
 */
class Filter extends Component {
    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */




    constructor(props) {
        super(props);
        const cachedWidth = 800;
        this.state = {
            selectedFilters: {},
            selectedFeaturedFilters: {},
            selectedMobileFilters: {},
            selectedMobilePastFilters: {},
            mobileExpanded: false,
            currentPath: null,
            filters: props.filters,
            filterKey: props.filterKey,
            searchLabels: props.searchLabels,
            id: Math.random(),
        };
        this.filterGlobal = '';
        this.filterUpdated = this.filterUpdated.bind(this);
        this.featuredFilterUpdated = this.featuredFilterUpdated.bind(this);
        this.checkSelectedFeaturedFilters = this.checkSelectedFeaturedFilters.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.parseFilters = this.parseFilters.bind(this);
        this.populateFilters = this.populateFilters.bind(this);
        this.toggleMobileContent = this.toggleMobileContent.bind(this);
        this.hideMobileFilter = this.hideMobileFilter.bind(this);
        this.clearFilterSelection = this.clearFilterSelection.bind(this);
        this.clearQueries = this.clearQueries.bind(this);
        this.sendFilterCountUpdate = this.sendFilterCountUpdate.bind(this);
        this.onResize = this.onResize.bind(this, cachedWidth);
    }

    /**
     * Populates the filters based on the current querystring
     */
    componentDidMount() {
        this.populateFilters();
        window.addEventListener('resize', this.onResize);

      const { router: {location}, filterKey } = this.props;
      const oldQueryString = Object.assign({}, decode(location.search));
      const queryString = Object.assign({}, decode(location.search.replace('%7C', '|')));

      const thisTabQuery = queryString[filterKey] &&
            queryString[filterKey].length > 0 ? decode(queryString[filterKey][0]) : {};
        this.sendFilterCountUpdate(thisTabQuery);
        if (queryString[filterKey] && (queryString[filterKey] != encode(thisTabQuery, false) || oldQueryString[filterKey] != encode(thisTabQuery, false))) {
            queryString[filterKey] = encode(thisTabQuery, false);
            this.updateURL(queryString, false);
        }
        this.setState({
            currentPath: location.pathname
        })
        this.checkSelectedFeaturedFilters();
    }

    /**
     * If the new search query isn't a match, update the selected filters
     * @param {object} lastProps the next properties incoming from parent.
     */
    componentDidUpdate(lastProps, lastState) {
        const { router: {location}, filterKey } = this.props;
        const queryString = decode(location.search);
        const thisTabQuery = (queryString[filterKey]?.length > 0) ? decode(queryString[filterKey][0]) : undefined;

        if (this.props.filterKey == this.state.filterKey) {
            if ((lastProps.filters !== null && this.props.filters === null && lastProps.filters !== this.props.filters) || lastProps.router.location !== this.props.router.location) {
                if (thisTabQuery) this.sendFilterCountUpdate(thisTabQuery);
                this.parseFilters(this.props.filters, this.props.router.location);
                this.populateFilters(this.props.router.location);
                if (this.props.filtersOpen) {
                    this.setState({
                        mobileExpanded: true,
                    });
                }
            }
            if (this.props.location.search !== lastProps.router.location.search) {
                this.checkSelectedFeaturedFilters();
                if (lastProps.router.location.search !== this.props.router.location.search && queryString[filterKey] && queryString[filterKey].length > 0) {
                    if (queryString[filterKey] && queryString[filterKey] != encode(thisTabQuery, false)) {
                        queryString[filterKey] = encode(thisTabQuery, false);
                        this.updateURL(queryString, false);
                    }
                }
            }
            if (lastProps.mobileExpanded !== this.props.mobileExpanded && this.props.mobileExpanded !== this.state.mobileExpanded) {
                if (this.props.mobileExpanded) {
                    padContent(window.innerWidth - document.documentElement.getBoundingClientRect().width);
                    this.setState({
                        mobileExpanded: true,
                    });
                } else {
                    unPadContent();
                    this.setState({
                        mobileExpanded: false,
                    });
                }
            }
        }
        if (this.props.filterKey !== '') {
            if (!deepEqual(this.state.filters, this.props.filters)) {
                if (thisTabQuery) this.sendFilterCountUpdate(thisTabQuery);
                this.setState({
                    filters: this.props.filters,
                    filterKey: this.props.filterKey,
                    searchLabels: this.props.searchLabels,
                });
            }
        }
    }
    /**
     * Remove event Listeners on window
     */
    componentWillUnmount() {
        const { selectedFilters } = this.state;
        window.removeEventListener('resize', this.onResize);

        // clear any selected filters from the url
        if (Object.keys(selectedFilters).length > 0) {
            // clear filter selections
            // and mark search results as out of date,
            // but dont immediately conduct a new search
            // this.clearFilterSelection(false);
        }
    }

    /**
     * On Resize function
     * @param {number} cachedWidth cachedWidth
     */
    onResize(cachedWidth) {
        const newWidth = (typeof window !== "undefined") ? window.innerWidth : 800;
        if (newWidth !== cachedWidth) {
            let resizeTimer;
            clearTimeout(resizeTimer);
            if (window.innerWidth >= 992) this.props.filterActions.closeMenu();
            resizeTimer = setTimeout(() => {
                const isMobile = mobileScreenUtility();
            }, 500);
        }
    }

    /**
     * Toggle Drawer
     * @param {object} event event
     * @param {boolean} status status
     * @returns {void}
     * */
    toggleDrawer = (event, status) => {
        if (event == undefined || event?.key === 'Escape') {
            this.props.filterActions.closeMenu();
        } else {
            if (status) {
                this.props.filterActions.openMenu();
            } else {
                this.props.filterActions.closeMenu();
            }
        }
    };

    /**
     * Populates the filters based on the current querystring
     */
    populateFilters(location = this.props.router.location) {
        const { filterKey } = this.props;
        const selectedFilters = decode(location.search);
        let thisTabFilters = {};
        if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
            if (typeof selectedFilters[filterKey][0] === 'string') {
                thisTabFilters = decode(selectedFilters[0]);
            } else {
                thisTabFilters = selectedFilters[filterKey][0];
            }
        }

        if (thisTabFilters.q) {
            delete selectedFilters.q;
            delete selectedFilters.filter;
        }
        this.setState({
            selectedFilters,
        });
    }

    /**
     * Determines if extra filters have been passed on page load and will parse out the filters that aren't needed
     * @param {object} filters Next incoming filter object
     */
    parseFilters(filters, location) {
        const { filterKey } = this.props;
        const selectedFilters = decode(location.search);
        let filtersModified = false;
        const urlFilters = selectedFilters[filterKey] &&
            selectedFilters[filterKey].length > 0 ? decode(selectedFilters[filterKey][0]) : {};
        Object.keys(urlFilters).forEach((key) => {
            if (key !== 'filter') {
                if (filters[key]) {
                    let checkFilterArray = [...filters[key].subFilters];
                    filters[key].subFilters.forEach((item) => {
                        if (item.subFilters) {
                            checkFilterArray = checkFilterArray.concat(item.subFilters);
                        }
                    });
                    const newArray = [];
                    Object.keys(urlFilters[key]).forEach((childKey) => {
                        if (find(checkFilterArray, {
                            name: urlFilters[key][childKey],
                        })) {
                            newArray.push(urlFilters[key][childKey]);
                        } else {
                            filtersModified = true;
                        }
                    });
                    if (newArray.length == 0)
                        delete urlFilters[key];
                    else
                        urlFilters[key] = newArray;
                }
            }
        });
        if (filtersModified) {
            selectedFilters[filterKey] = [encode(urlFilters, false, false)];
            this.updateURL(selectedFilters, true);
            this.setState({
                selectedFilters,
            });
        }
    }


    /**
     * Passes out the updated filter whenever there is a change
     * @param {object} filter Updated filter to construct
     * * @param {object} filterForAnalytics filter sent to analitycs. It sends only the parent if all children were selected
     */
    filterUpdated(filter) {

        this.filterGlobal = filter;
        const { router, filterKey, isLvdcOnly, lvdcExhibitorLabels, filters } = this.props;
        const location = router.location;
        const { selectedFilters } = this.state;
        const { offeringFilterKey, lvdcTag } = lvdcExhibitorLabels;
        const currentQuery = Object.assign({}, decode(location.search));
        let filterObjectKeys = [];

        // Killing filter here to have a clean check on the number of filters.
        const queryString = Object.assign({}, decode(location.search));
        delete currentQuery[filterKey];
        let thisTabQuery = queryString[filterKey] &&
            queryString[filterKey].length > 0 ? decode(queryString[filterKey][0]) : {};
        let newFilters = Object.assign({}, thisTabQuery, filter);

        if (isLvdcOnly) {
            if (newFilters[offeringFilterKey]) {
                if (newFilters[offeringFilterKey].indexOf(lvdcTag) === -1) {
                    newFilters[offeringFilterKey].push(lvdcTag);
                }
            } else {
                newFilters[offeringFilterKey] = [lvdcTag];
            }
        }

        if (newFilters.filter && newFilters.filter.length > 0) {
            newFilters.filter = null;
        }
        // Trim the fat from the object
        // newFilters = cleanObjKeys(newFilters);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length === 1 && filterObjectKeys.indexOf(SORT_ORDER) === 0) {
            newFilters.filter = false;
            thisTabQuery = newFilters;
            queryString[filterKey] = encode(thisTabQuery, false);
        }
        newFilters = cleanObjKeys(newFilters);
        thisTabQuery = newFilters;
        this.sendFilterCountUpdate(thisTabQuery);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length !== 0) {
            // newFilters.filter = true;
            queryString[filterKey] = encode(thisTabQuery, false);
        } else {
            delete queryString[filterKey];
        }

        this.updateURL(queryString, true);
        this.setState({
            // selectedFilters: cleanObjKeys(thisTabQuery), -> hide this because it updates when componet updates causing a mismatch on filterItems for one render cycle
            selectedMobileFilters: selectedFilters,
            selectedMobilePastFilters: cleanObjKeys(selectedFilters),
        });
    }

    /**
     * Updates URL for Featured Filters
     * @param {object} filter Updated filter to construct
     */
    featuredFilterUpdated(filter) {

        this.filterGlobal = filter;
        const { router, filterKey, isLvdcOnly, lvdcExhibitorLabels, filterGroupFields } = this.props;
        const location = router.location;
        const { selectedFilters } = this.state;
        const { offeringFilterKey, lvdcTag } = lvdcExhibitorLabels;
        const currentQuery = Object.assign({}, decode(location.search));
        let filterObjectKeys = [];

        const filterItem = filterGroupFields[0].FeaturedFilters.filter((f) => {
            return f?.link?.data?.value?.text == filter[Object.keys(filter)[0]][0];
        })[0] || {};
        
        if (filterItem?.triggerLink?.data?.value) {
            const currentHost = window.location.host;
            const regex = new RegExp(`^\\?|^\\/|.*\\/\\/${currentHost}|.*\\/\\/www\\.${currentHost}`,'i');
            const href = filterItem.link.data.value.href;
            if (filterItem.link.data.value.href) {
                if (filterItem.link.target == '_blank') {
                    window.open(href, '_blank');
                    return;
                } else if (regex.test(href)) {
                    router.navigate(filterItem.link.data.value.href);
                    return;
                } else if (filterItem.link.data.value.href) {
                    window.location.href = href;
                    return;
                }
            }
        }
        

        // Killing filter here to have a clean check on the number of filters.
        const queryString = Object.assign({}, decode(location.search));
        delete currentQuery[filterKey];
        let thisTabQuery = queryString[filterKey] &&
            queryString[filterKey].length > 0 ? decode(queryString[filterKey][0]) : {};

        if (filterItem?.clearOtherFilters?.data?.value === true) {
            thisTabQuery = {};
        }

        if (filterItem?.clearAllQueries?.data?.value === true) {
            thisTabQuery = {};
            delete queryString['q'];
            delete queryString['sortorder'];
            delete queryString['type'];
            delete queryString['alphabetName'];
        }
        let newFilters = Object.assign({}, thisTabQuery);

        // get the newly selected filters
        filterGroupFields.forEach((filterGroup) => {
            filterGroup.FeaturedFilters.map((filterItem) => {
                Object.keys(filter).forEach((childKey) => {
                    filter[childKey].forEach((newItem) => {
                        const { text, href, querystring: newQueryString } = filterItem.link.data.value;
                        if (text === newItem) {
                            const newQueries = decode(newQueryString);
                            Object.keys(newQueries).map((q) => {
                                const newQueryItems = newQueries[q];
                                if (q.toLowerCase() == 'q') {
                                    delete newFilters[q];
                                    queryString['q'] = newQueryItems[0];
                                } else if (q.toLowerCase() == 'sortorder') {
                                    delete newFilters[q];
                                    queryString['sortorder'] = newQueryItems[0];
                                } else if (q.toLowerCase() == 'type') {
                                    delete newFilters[q];
                                    queryString['type'] = newQueryItems[0];
                                } else if (q.toLowerCase() == 'alphabetname') {
                                    delete newFilters[q];
                                    queryString['alphabetName'] = newQueryItems[0];
                                } else {
                                    newQueryItems.forEach((newQueryItem) => {
                                        let newItemValues = decode(newQueryItem);
                                        Object.keys(newItemValues).forEach((key) => {
                                            if (!newFilters[key] || filterItem?.clearFilterGroup?.data?.value === true) {
                                                newFilters[key] = [];
                                            }
                                            newItemValues[key].forEach((value) => {
                                                if (!newFilters[key].includes(value)) {
                                                    newFilters[key].push(value);
                                                }
                                            });
                                        });
                                    });
                                }
                            });
                        }
                    });
                });
            });
        });

        if (isLvdcOnly) {
            if (newFilters[offeringFilterKey]) {
                if (newFilters[offeringFilterKey].indexOf(lvdcTag) === -1) {
                    newFilters[offeringFilterKey].push(lvdcTag);
                }
            } else {
                newFilters[offeringFilterKey] = [lvdcTag];
            }
        }

        if (newFilters.filter && newFilters.filter.length > 0) {
            newFilters.filter = null;
        }
        // Trim the fat from the object
        // newFilters = cleanObjKeys(newFilters);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length === 1 && filterObjectKeys.indexOf(SORT_ORDER) === 0) {
            newFilters.filter = false;
            thisTabQuery = newFilters;
            queryString[filterKey] = encode(thisTabQuery, false);
        }
        newFilters = cleanObjKeys(newFilters);
        thisTabQuery = newFilters;
        this.sendFilterCountUpdate(thisTabQuery);
        filterObjectKeys = Object.keys(newFilters);
        if (filterObjectKeys.length !== 0) {
            // newFilters.filter = true;
            queryString[filterKey] = encode(thisTabQuery, false);
        } else {
            delete queryString[filterKey];
        }

        this.updateURL(queryString, true);
        this.setState({
            // selectedFilters: cleanObjKeys(thisTabQuery), -> hide this because it updates when componet updates causing a mismatch on filterItems for one render cycle
            selectedMobileFilters: selectedFilters,
            selectedMobilePastFilters: cleanObjKeys(selectedFilters),
        });
    }

    /**
     * Check if any featured filters are selected
     * @returns {void}
     * */
    checkSelectedFeaturedFilters() {
        const { router, filterKey, filterGroupFields } = this.props;
        const location = router.location;
        const queryString = (location?.search) ? decode(location.search) : {};
        const { selectedFeaturedFilters } = this.state;
        const selectedFilters = {};

        filterGroupFields.forEach((filterGroup) => {
            filterGroup.FeaturedFilters.map((filterItem) => {
                const { text, href, querystring: featuredQueryString } = filterItem.link.data.value;
                const filterQueries = decode(featuredQueryString);
                Object.keys(filterQueries).map((q) => {
                    const newQueryItems = filterQueries[q];
                    const selectedQueryItems = queryString[q] || [];

                    if (selectedQueryItems.length > 0 && q.toLowerCase() != 'q' && q.toLowerCase() != 'sortorder' && q.toLowerCase() != 'type' && q.toLowerCase() != 'alphabetname') {
                        selectedQueryItems.forEach((selectedQueryItem) => {
                            const selectedItemValues = decode(selectedQueryItem);
                            
                            newQueryItems.forEach((newQueryItem) => {
                                let newItemValues = decode(newQueryItem);
                                Object.keys(newItemValues).forEach((key) => {

                                    let addFilter = true;

                                    if (Object.prototype.hasOwnProperty.call(selectedItemValues, key)) {
                                        newItemValues[key].map((value) => {
                                            if (!(selectedItemValues[key].indexOf(value) > -1)) {
                                                addFilter = false;
                                            }
                                        });
                                    } else {
                                        addFilter = false;
                                    }

                                    if (addFilter) {
                                        selectedFilters[text] = true;
                                    }

                                });
                            });

                        });

                    }

                });
            });
        });

        if (!deepEqual(selectedFeaturedFilters, selectedFilters)) {
            this.setState({
                selectedFeaturedFilters: selectedFilters,
            });
        }
    }

    /**
     * Send the filter count up to the HOC.
     * @param {object} thisTabQuery The query object for which app we are in.
     */
    sendFilterCountUpdate(thisTabQuery) {
        let filtersCount = 0;
        Object.keys(thisTabQuery).forEach((key) => {
            if (key !== 'filter') {
                filtersCount += thisTabQuery[key].length;

                let hasParents = false;
                let parentCount = 0;
                Object.keys(thisTabQuery[key]).forEach((childKey) => {
                    if (thisTabQuery[key][childKey].indexOf('.') > -1) {
                        hasParents = true;
                    } else {
                        parentCount++;
                    }
                });
                if (hasParents) {
                    filtersCount -= parentCount;
                }
            }
        });
        if (this.props.filtersCount !== filtersCount) this.props.filterActions.setFiltersCount(filtersCount);
    }

    /**
     * Pushed updated search query to history
     * @param {object} searchQuery object containing selected filters and search term
     * @param {bool} resetPage Determines if pagination should be set back to 1
     */
    updateURL(searchQuery, resetPage = false) {
        const {match, router, router: {location} } = this.props;
        const newHistory = resetPagination(match, location);
        let basepath;
        if (resetPage) {
            delete searchQuery['page'];
            basepath = cleanedBasepath(match);
            newHistory.search = (searchQuery);
            router.navigate(`${basepath}?${encode(searchQuery, false, false)}`, newHistory);
        } else {
            newHistory.search = encode(searchQuery, false, false);
            router.navigate(newHistory);
        }
    }

    /**
     * select clear function Btn based on Mobile Filter function
     * @param {object} e Event action from click event
     * @param {bool} runSearch should new data be fetched based on cleared filter selections?
     */
    clearFilterSelection(e, runSearch = true) {
        e.preventDefault();
        this.clearFilter(runSearch);
    }

    clearQueries(e, runSearch = true, clearQueries = true) {
        e.preventDefault();
        this.clearFilter(runSearch, clearQueries);
    }

    /**
     * Clears the filter of all selected options
     * @param {bool} runSearch should new data be fetched based on cleaned filter selections?
     */
    clearFilter(runSearch = true, clearQueries = false) {
        const { clearAction, router: {location}, filterKey } = this.props;
        const queryObj = (clearQueries) ? {} : qs.parse(location.search);
        if (filterKey) {
            delete queryObj[filterKey];
        }
        this.updateURL(queryObj, true);
        // in some cases, we don't want to trigger
        // a seach immediately, but need to mark the current
        // search results as out of date in relation to selected filters.
        // eg, on componentWillUnmount, clearing filters but not wanting to
        // immediately execute a new search.

        const cleanedFilters = {
            selectedFilters: {},
        };

        const updatedState = mobileScreenUtility() ?
            {
                ...cleanedFilters,
                selectedMobileFilters: {},
            } : cleanedFilters;

        if (clearAction && runSearch) {
            clearAction();
        }
        if (this.props.filtersCount !== 0) {
            this.props.filterActions.setFiltersCount(0);
            if (this.props.setSelectedFilterCount) {
                this.props.setSelectedFilterCount(this.props.filterKey, 0);
            }
        }
        this.setState(updatedState);
    }

    /**
     * Toggle Content on Mobile
     */
    toggleMobileContent() {
        this.toggleDrawer();
    }
    /**
     * Hide Mobile Filter
     */
    hideMobileFilter() {
        this.toggleMobileContent();
    }

    /**
     * Renders the filter items
     * @returns {(Array|null)} Array of JSX filter items or nothing if no filters are available
     */
    renderFilterControlsMobile() {
        return (
            <div className="imc-filter__mobileheader">
                <button
                    className="imc-button imc-button--small imc-button--small-xy imc-button--radius-3 top-nav-plan-close"
                    onClick={this.hideMobileFilter}
                >
                    <SvgIcon width={12} height={12} xlinkHref={'#juniper-close'} className={`svg-black `} useClassName={`svg-black`} />
                </button>
            </div>
        );
    }
    /**
     * Renders the filter items
     * @returns {(Array|null)} Array of JSX filter items or nothing if no filters are available
     */
    renderFilters() {
        const { filters, filterKey, searchLabels } = this.state;
        const { selectedFilters } = this.state;
        const filterItems = [];
        let thisTabFilters = {};
        if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
            thisTabFilters = decode(selectedFilters[filterKey][0]);
        }
        if (filters) {
            Object.keys(filters).forEach((item) => {
                const filterOfInterest = filters[item];
                filterItems.push(
                    <FilterItem
                        filterCategory={item}
                        filterKey={filterKey}
                        key={`filterItem-${filters[item].name}`}
                        {...filterOfInterest}
                        updateFilter={(filter) => this.filterUpdated(filter)}
                        selectedFilters={thisTabFilters[item]}
                        searchLabels={searchLabels}
                    />,
                );
            });
        }

        return filterItems;
    }
    /**
     * Renders the custom list of featured filter items
     * @returns {(Array|null)} Array of JSX filter items or nothing if no filters are available
     */
    renderFeaturedFilters() {
        const { filtersCount, filterGroupFields } = this.props;
        const { filterKey, searchLabels, selectedFeaturedFilters } = this.state;
        const { selectedFilters } = this.state;
        const filterItems = [];
        let thisTabFilters = {};
        if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
            thisTabFilters = decode(selectedFilters[filterKey][0]);
        }
        if (filterGroupFields?.length > 0) {
            filterGroupFields.forEach((filterGroup, g) => {
                const item = {
                    name: filterGroup.label.data.value,
                    displayName: filterGroup.label.data.value,
                    subFilters: filterGroup.FeaturedFilters.map((filter) => {
                        return {
                            name: filter.link.data.value.text,
                            displayName: filter.link.data.value.text,
                            checked: (selectedFeaturedFilters[filter.link.data.value.text]) ? true : false,
                        };
                    }),
                };

                filterItems.push(
                    <div>
                        <div className='imc-section--basic-white imc-filter--reset-button-container imc-content--display-flex-wrap imc-content--display-flex-gap-xsmall'>
                            <p className='imc-section--basic-white imc-content--display-flex-break-mobile imc-filter--title'>{item.name}</p>
                            <button
                                className={`${(filtersCount > 0) ? 'imc-filter--clear-active' : ''} imc-filter--reset-button imc-link--hover-underline`}
                                onClick={(e) => this.clearQueries(e)}
                            >
                                {`Clear Search`}
                            </button>
                        </div>
                        <div className="imc-section--basic-white imc-filter__filters-mobile-filter-wrapper imc-content--display-flex imc-content--display-flex-gap-xsmall imc-content--display-flex-column"> 
                            <FilterItem
                                isFeaturedFilter={true}
                                filterCategory={item.name}
                                filterKey={filterKey}
                                key={`filterItem-${filterGroup.label.data.value}`}
                                {...filterGroup}
                                {...item}
                                updateFilter={(filter) => {
                                    this.featuredFilterUpdated(filter);
                                }}
                                selectedFilters={thisTabFilters[item]}
                                searchLabels={searchLabels}
                                alwaysExpanded={filterGroup?.alwaysExpanded?.data?.value || false}
                            />
                        </div>
                    </div>
                );
            });
        }

        return (
            <>
                {filterItems}
            </>
        );
    }

    /**
     * Renders the clear filter link if filters are currently selected
     * @returns {*} Clear Filter Link (if applicable)
     */
    renderClearFilterLink() {
        const { labelClear, filterKey } = this.props;
        const { selectedFilters, mobileExpanded } = this.state;
        const clearAllClass = mobileExpanded ? '' : 'imc-filter__filters-mobile__clearall';
        let isDisabled = false;
        if (!selectedFilters || Object.keys(selectedFilters).indexOf(filterKey) === -1) {
            isDisabled = true;
        }
        return (
            <button
                disabled={isDisabled}
                tabIndex={0}
                onClick={this.clearFilterSelection}
                className={`imc-link imc-content--delta imc-filter__clearall imc-filteritem__clearlink
                ${clearAllClass}`}
                data-xpath="filter.clearlink"
            >
                {labelClear}
            </button>
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { mobileExpanded, filtersCount, filterGroupFields } = this.props;
        const mobileClasses = mobileExpanded ?
            'imc-expand-collapse--expanded' :
            'imc-expand-collapse--expanded';
        const filterWrapperClass = mobileExpanded ? 'active' : '';
        return (
            <div
                className={`imc-filter imc-content ${mobileClasses}`}
                data-xpath="filter.container"
            >
                <DropdownMenu
                    wrapperClass={`imc-content--display-none`}
                    key={`filter-menu-mobile`}
                    clickHandler={(event, status) => {
                        this.toggleDrawer(event, status ? status : true);
                    }}
                    escapeHandler={(event, status) => {
                        this.toggleDrawer(event, status ? status : false);
                    }}
                    isDrawer={true}
                    openState={mobileExpanded}
                    foriegnContent={true}
                    previousFocusOnExit={true}
                    closeOnScroll={false}
                >
                    <></>
                </DropdownMenu>
                <div className={`imc-filter__filters imc-expand-collapse__content ${filterWrapperClass} top-nav-dropdown left top-nav-drawer drawer-menu`}>
                    <div className={`top-nav-dropdown-menu top-nav-plan imc-section--basic-white imc-content--display-flex imc-content--display-flex-column`}>
                        {this.renderFilterControlsMobile()}
                        {(filterGroupFields?.length > 0) && this.renderFeaturedFilters()}
                        <div>
                            <div className='imc-section--basic-white imc-filter--reset-button-container imc-content--display-flex-wrap imc-content--display-flex-gap-xsmall'>
                                <p className='imc-section--basic-white imc-content--display-flex-break-mobile imc-filter--title'>Filters</p>
                                <button
                                    className={`${(filtersCount > 0) ? 'imc-filter--clear-active' : ''} imc-filter--reset-button imc-link--hover-underline`}
                                    onClick={this.clearFilterSelection}
                                >
                                    {`Clear ${(filtersCount > 0) ? `All ${filtersCount} ` : ``}Filter${(filtersCount !== 1) ? `s` : ``}`}
                                </button>
                            </div>
                            <div className="imc-section--basic-white imc-filter__filters-mobile-filter-wrapper imc-content--display-flex imc-content--display-flex-gap-xsmall imc-content--display-flex-column">
                                {this.renderFilters()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {
        mobileExpanded: state.filterReducer.mobileExpanded,
        filtersCount: state.filterReducer.filtersCount,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        filterActions: bindActionCreators({ ...filterActions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreRouter(Filter));
