import React, { Component } from 'react';
import {withSitecoreRouter} from 'utils/withRouter';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import NewSearchItemThumb from 'modules/newSearchItemThumb/NewSearchItemThumb';
import Pagination from 'modules/search/Pagination.jsx';
import {getExhibitorCatalogURL} from "utils/exhibitor";
import SearchResultsColumn from 'modules/searchresultswrapper/components/SearchResultsColumn';
/**
 * TabMyLists component
 * todo: create
 * @constructor
 */

class DigitalShowroomCatalogList extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            showPdf: false,
            data: {},
            showModal: false,
        };
        this.renderCatalogs = this.renderCatalogs.bind(this);
    }

    /**
     * @method constructor
     * @returns {*} Components
     */
    renderCatalogs() {
        const { tabData, sitecoreContext } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        if(tabData.catalogs && Array.isArray(tabData.catalogs))
         return (
            <div className={`imc-gallery am-grid imc-gallery--1-4 imc-gallery--1-3--tablet imc-gallery--1-2--mobile imc-gallery--50-50 imc-gallery--align-flex-stretch imc-gallery--justify-left imc-gallery--xlarge-padded`}>
                {tabData.catalogs.map(
                    catalog => {
                        const { lineDetail, router } = this.props;
                        let index = 2;
                        let isLine = false;
                        if (lineDetail) {
                            index = 4;
                            isLine = true;
                        }
                        const exhibitId = router.location.pathname.split('/')[index];
                        const mainType = isLine ? 'line' : 'exhibitor';
                        const catFav = {
                            itemId: exhibitId,
                            itemType: mainType,
                            label: catalog.name,
                            contentName: '',
                            itemContents: [{
                                deleted: true, //Leave this in true for adding and item thru the copy endpoint
                                contentName: catalog.name,
                                itemId: catalog.id,
                                itemType: 'catalog',
                            }],
                        };
                        return (
                            <div className={`imc-gallery__item`}>
                                <NewSearchItemThumb
                                    key={catalog.id}
                                    url={getExhibitorCatalogURL(catalog.exhibitorId, catalog.id)}
                                    image={isEditing ? '' : catalog.previewImageLink}
                                    newWindow={false}
                                    actionItem={catFav}
                                    name={catalog.name}
                                    itemID={catalog.id}
                                    product={catalog}
                                    isNew={catalog.newProductForMarket}
                                    extraClass={'searchItemThumb--full-width searchItemThumb--catalog'}
                                />
                            </div>
                        )
                    }
                )}
            </div>
        );
        else return [];
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const  {
            currentTab: { size: { value: resultsPerPage } },
            tabData: { catCount: prodCount, catalogs: products },
        } = this.props;
        const numberOfPages = (products && Array.isArray(products) && products.length > 0) ? Math.ceil(parseInt(prodCount, 10) / resultsPerPage) : null;
        return (
            <SearchResultsColumn rendering={this.props.rendering} renderResults={() => this.renderCatalogs()}  numberOfPages={numberOfPages} searchPlaceholder={'imc-search-bar'} />
        );
    }
}

export default withSitecoreContext()(
    withSitecoreRouter(DigitalShowroomCatalogList)
);
