import React from 'react';
import FiltersAPI from 'api/search/filters';
import {withSitecoreRouter} from 'utils/withRouter';
import qs from 'query-string';

import { getDictionaryValue } from 'utils/dictionary/index';
import { resetPagination } from 'utils/resetpagination';
import { encode, decode } from 'utils/querystring';
import deepEqual from 'deep-equal';

class FilterSearchByLetter extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			value: "",
			term: null,
			type: null
		};
	}

	componentDidMount() {
		const { router } = this.props;
		this.changeFilterByLetter = this.changeFilterByLetter.bind(this);
		this.renderSelect = this.renderSelect.bind(this);

		this._filtersApi = new FiltersAPI();
		this.getData(router.location.search);
	}
	componentDidUpdate(lastProps) {
		if (!deepEqual(lastProps.router.location, this.props.router.location)) {
			this.getData(this.props.router.location.search);
		}
	}

	getData(search) {
		const qsParsed = decode(search);
		const term = (qsParsed?.q?.length > 0) ? qsParsed.q[0] : '';
		const lastTerm = this.state.term;
		let alphabetName = (qsParsed?.alphabetName?.length > 0) ? qsParsed.alphabetName[0] : '';
		let value = alphabetName || this.state.value;
        if((lastTerm !== term && term == '') || alphabetName == ''){
			alphabetName = '';
			value = 'All';
		}
		const type = qsParsed.type || '';
		if(true || (!!term && term.length>0)) {
            this._filtersApi.getAlphabets(alphabetName, term, type)
                .then(response => {
                    this.setState({
                        data: response,
                        term: term,
                        value: value
                    })
                })
                .catch(err => console.error(this.props.url, err.toString()))
        } else {
            this.setState({ data: null, term: term, value: value });
        }
        if (alphabetName && alphabetName != value) {
            this.changeFilterByLetter({ target: {alphabetName} }, false);
        }
	}


	updateURL(searchQuery) {
		const { match, router, router : {location} } = this.props;
		const newHistory = resetPagination(match, location);
		newHistory.search = encode(searchQuery, false, false);
		router.navigate(newHistory);
	}



	changeFilterByLetter(event, updateState = true) {
		const { router : {location} } = this.props;
		if (updateState) this.setState({ value: event.target.value });
		let qsParsed = qs.parse(location.search, { ignoreQueryPrefix: true });

		if (event.target.value == "") {
			delete qsParsed.alphabetName;
		} else {
			qsParsed.alphabetName = event.target.value;
		}
		if(qsParsed.page){
			delete qsParsed.page;
		}
		this.updateURL(qsParsed);
	}

	renderSelect() {
		if (this.state.data != null && this.state.data.alphabetList != null) {
			return (
				<React.Fragment>
					{this.state.data.alphabetList.map((item, index) => (
						this.renderOption(index, item)
					))}
				</React.Fragment>
			)
		}
	}
	renderOption(index, item) {
		if (item) {
			return <option key={index} value={item}>{item}</option>
		}
	}
	render() {
		return ((this?.state?.data?.alphabetList != null) &&
            <div className="imc-searchform--bar--item imc-searchform--bar--item--caret">
                <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title">{getDictionaryValue('filterByName', 'Filter by Name:')}</span>
                <div className="imc-searchform--bar--item--input">
                    <div className="imc-formfield imc-dropdown imc-searchform--dropdown  imc-searchform--dropdown--minwidth-auto">
                        <label className="imc-visuallyhide" htmlFor="dropdown-filter-by-letter">
                            {getDictionaryValue('filterBy', 'Filter By')}
                        </label>
                        <select id="dropdown-filter-by-letter" onChange={this.changeFilterByLetter} value={this.state.value}>
                            <option value="">{getDictionaryValue('all', 'All')}</option>
                            {this.renderSelect()}
                        </select>
                    </div>
                </div>
            </div>
		);
	}
}

export default withSitecoreRouter(FilterSearchByLetter);
