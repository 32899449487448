import React, { Component } from 'react'

export default class index extends Component {
    render() {
        if (this.props.text)
            return (
                <div className={`imc-section--padded-left-large imc-section--padded-right-large alert-box imc-content--delta ${ this.props.extraClass? this.props.extraClass:''}`}>
                    {this.props.text}
                </div>
            )
        return null;
    }
}
