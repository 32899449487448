import React, { ReactNode } from 'react';
import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Pagination from 'modules/search/Pagination.jsx';

type SearchResultsColumnProps = {
  rendering: ComponentRendering;
  children?: ReactNode;
  renderResults: () => ReactNode;
  gridClass?: string;
  isPageEditing?: boolean;
  numberOfPages?: number | null;
  searchPlaceholder?: string;
};

const SearchResultsColumn: React.FC<SearchResultsColumnProps> = ({
  rendering,
  children,
  renderResults,
  isPageEditing = false,
  numberOfPages = null,
  searchPlaceholder = 'imc-typeahead',
}) => {
  return (
      <div className="imc-gallery__item imc-content--full-width">
        <div className="imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-medium">
          <Placeholder name={searchPlaceholder} rendering={rendering} />
          {React.Children.map(children, (child) => child)}
          <Placeholder name="imc-exhibitorad" rendering={rendering} />
        </div>

        {!isPageEditing &&
          <div className={`imc-searchresults imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge`}>
            <div className="imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-jumbo imc-vr--xxlarge" >
              {renderResults()}
            </div>
            {(numberOfPages) &&
              <Pagination totalPages={numberOfPages} paginationContainerId={`searchContainer`} nonSearch />
            }
          </div>
        }
      </div>
  );
};

export default SearchResultsColumn;
