// React + Sitecore + JSS route handler
import React from 'react';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import { isEditorActive, withSitecoreContext, RouteData } from '@sitecore-jss/sitecore-jss-react';
import { layoutServiceFactory } from './lib/layout-service-factory';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';

// UXP speficic imports
import {
  getSitecoreDefaultLanguage,
  getSitecoreApiKey,
  getSitecoreLayoutApiHost,
  getSitecoreApiHost
} from './AppGlobals';
import { loadSvgSprite } from './modules/svgsprite'
import { Environment } from './Environment';
import UserUtility from 'utils/userutility';
import ImcDataLayer from 'utils/datalayer';
import { analytics } from './modules/marketplan/helpers/analytics';
import { Loading } from 'modules/loading';
import {dataFetcher} from './dataFetcher';

/* eslint-disable no-console */

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let ssrInitialState = null;

class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      routeData: ssrInitialState, // null when client-side rendering
      defaultLanguage: getSitecoreDefaultLanguage(),
      loading: false,
    };

    // route data from react-router - if route was resolved, it's not a 404
    if (props.route !== null) {
      this.state.notFound = false;
    }


    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (ssrInitialState && (!ssrInitialState.sitecore || !ssrInitialState.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (ssrInitialState && ssrInitialState.context && ssrInitialState.context.language) {
      this.state.defaultLanguage = ssrInitialState.context.language;
    }

    if (!isEditorActive() && !Environment.isServer && ssrInitialState && ssrInitialState.sitecore.context && ssrInitialState.sitecore.context.googleTagManager) {
      ImcDataLayer.Initialize(ssrInitialState.sitecore.context.googleTagManager);
      if (props.route !== null)
        this.gtmPageTracking(props.route);
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (typeof window !== 'undefined') {
      ssrInitialState = null;
    }

    this.componentIsMounted = false;
    this.languageIsChanging = false;

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const layoutData = this.props.sitecoreContext;
    const layoutSiteName = layoutData?.site?.name;
    
    loadSvgSprite(layoutSiteName);
    
    // If we are not using SSR we have to load layout data
    if (!this.props.isSSR) {
      this.updateLayoutData(true);
    }

    this.componentIsMounted = true;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateLayoutData(fromMount = false) {
    this.setState({ loading: true })

    const language = this.getLanguage();
    
    // instantiate the dictionary service.
    const layoutServiceInstance = layoutServiceFactory.create(this.props?.sitecoreContext?.site?.name);

    // get the route data for the new route
    layoutServiceInstance.fetchLayoutData(this.props.route, language).then((routeData) => {
      this.props.updateSitecoreContext(routeData);

      const currentRoute = this.props.route;
      if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {

        const sitecoreRoute = routeData.sitecore.route;
        const pageType = (sitecoreRoute.fields && sitecoreRoute.fields["gtmPageType"] && sitecoreRoute.fields["gtmPageType"].value) || "";
        const pageCategory = (sitecoreRoute.fields && sitecoreRoute.fields["gtmPageCategory"] && sitecoreRoute.fields["gtmPageCategory"].value) || "";

        if (typeof window !== 'undefined') {
          if (!!!window.channel) {
            window.channel = { name: routeData.sitecore.context.site.name };

            setChannelCode();
          }

          if (!!!window.pageType) {
            window.pageType = pageType;
            window.pageCategory = pageCategory;
          }

          if (!!!window.pageType) {
            window.pageType = pageType;
            window.pageCategory = pageCategory;
          }

          if (!!routeData.sitecore.context.userDetails) {
            UserUtility.setUserData(routeData.sitecore.context.userDetails);
          }

          //gtm
          const route = routeData.sitecore.route;
          this.gtmPageTracking(route);
          this.hubspotPageTracking(currentRoute)
        }

        this.setState({ routeData, notFound: false, loading: false });
      } else {
        if (!fromMount) {
            location.reload();
            this.setState({ loading: true });
        } else {
            this.setState({ routeData, notFound: true, loading: false });
        }
      }
    });
  }

  getLanguage() {
    return this.props.language || this.props.sitecoreContext.language || config.defaultLanguage;
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.getLanguage();

    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }

  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  gtmPageTracking(route) {
    if (!route)
      return;

    const pageType = (route.fields && route.fields["gtmPageType"] && route.fields["gtmPageType"].value) || "";
    const pageCategory = (route.fields && route.fields["gtmPageCategory"] && route.fields["gtmPageCategory"].value) || "";
    const pageTitle = (route.fields && route.fields["Page Title"] && route.fields["Page Title"].value) || "";
    const dl = new ImcDataLayer();

    dl.pageTracking.setPageData(pageType, pageCategory, pageTitle);
    dl.pageTracking.push();
  }

  hubspotPageTracking(route) {
    if (!route)
      return;
    if (typeof window !== 'undefined') {
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', route]);
      _hsq.push(['trackPageView']);
    }

  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.url;
    const newRoute = this.props.url;

    const a = this.props.sitecoreContext;
    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in Sitecore editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateLayoutData();
    this.scrollToTop();
  }

  render() {
    const layoutData = this.props.sitecoreContext;
    const layoutSiteName = layoutData?.site?.name;

    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    // `route` is null in case if route is not found
    if (layoutData.route === null) {
      return (
        <div>
          <Helmet>
            <title>{i18n.t('Page not found')}</title>
          </Helmet>
          <NotFound context={layoutData} />
        </div>
      );
    }

    const siteName = layoutSiteName;

    if (typeof window !== 'undefined') {
      if (!!!window.channel && !!layoutSiteName) {
        window.channel = { name: layoutSiteName };
        setChannelCode();
      }

      if (!!layoutData.userDetails) {
        UserUtility.setUserData(layoutData.userDetails);
      }
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!layoutData.route || this.languageIsChanging) {
      return null;
    }

    const isPageEditing = layoutData.pageEditing;
    // Render the app's root structural layout
    return (
      <>
        <Layout route={layoutData.route} siteName={siteName} colors={layoutData.jsssite.colors} />
        <Loading showLoading={this.state.loading} showPageLoading={this.state.loading}  isPageEditing={isPageEditing}/>
      </>
    );
  }
}

export function setServerSideRenderingState(ssrState) {
  ssrInitialState = ssrState;
}

export default withSitecoreContext({ updatable: true })(RouteHandler);

//TODO: replace with endpoint call or something served in sitecore context
function setChannelCode() {
    var code = 'atlmkt';
    switch (window.channel.name) {
      case "las-vegas-market":
        code = 'lvm';
        break;
      case "atlanta-market":
        code = 'atlmkt';
        break;
      case "americasmart":
        code = 'amc';
        break;
      case "atlanta-apparel":
        code = 'atlapp';
        break;
      case "atlanta-apparel":
        code = 'atlapp';
        break;
      case "las-vegas-apparel":
        code = 'lvapp';
        break;
    }
    window.channel.code = code;
  }
