// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for displaying a expand/collapse module and maintaining its state
 * @example
 * Rendered from domRenderer:
 * <div data-expand-collapse>
 * <div data-expand-collapse-heading>This is the Heading. It can be HTML.</div>
 * <div data-expand-collapse-content>This is the collapsible content. It can be HTML.</div>
 * </div>
 *
 * Rendered from JSX:
 * <ExpandCollapse>
 *     <div>Heading. Can be JSX.</div>
 *     <div>Content. Can be JSX.</div>
 * </ExpandCollapse>
 */
export default class ExpandCollapse extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.title = props.title;
        this.state = {
            expanded: this.props.expanded,
        };

        // set aliases
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.updateExpanded = this.updateExpanded.bind(this);
        // this.scrollElementToTop = this.scrollElementToTop.bind(this);
        this.expandKeypress = this.expandKeypress.bind(this);
    }


    /**
     * Updates the expanded state from the prop
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        if (prevProps.expanded !== this.props.expanded) {
            this.updateExpanded();
        }
        // this.scrollElementToTop();
    }

    /**
     * Scroll to top content
     */
    // scrollElementToTop() {
    //     if (this.props.scrollToTop && this.state.expanded) {
    //         let offsetTop = 0;
    //         const offsetTopObject = document.getElementsByClassName(this.props.scrollOffset)[0];
    //         if (offsetTopObject !== undefined) {
    //             offsetTop = offsetTopObject.offsetHeight;
    //         }

    //         const offsetTopObjectCategory = document.getElementsByClassName(this.props.scrollOffsetCategory)[0];
    //         if (offsetTopObjectCategory !== undefined && this.props.accordianIndex === 0) {
    //             offsetTop += offsetTopObjectCategory.offsetHeight + 50;
    //         }
    //         window.scrollTo(0, this.expandCollapseHeading.offsetTop - offsetTop);
    //     }
    // }

    /**
     * Updates the expanded state based on the updated prop
     */
    updateExpanded() {
        this.setState({
            expanded: this.props.expanded,
        });
    }

    /**
     * @method toggleExpanded
     * @description Toggles if the element is expanded or collapsed
     * @param {object} e event
     */
    expandKeypress(e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code && code === 13) {
            this.toggleExpanded();
        }
    }
    /**
     * @method toggleExpanded
     * @description Toggles if the element is expanded or collapsed
     * @param {object} e event
     */
    toggleExpanded() {
        const expandedUpdatedState = !this.state.expanded;
        this.setState({
            expanded: expandedUpdatedState,
        });
        this.props.expandedUpdated(expandedUpdatedState);
    }

    /**
     * @method renderHeading
     * @description
     * Renders the heading container element
     * @returns {Element} Rendered element heading
     */
    renderHeading() {
        const { title, children, renderAsHTML } = this.props;
        if (renderAsHTML) {
            return React.createElement('div', { dangerouslySetInnerHTML: { __html: title } });
        }
        return children[0];
    }

    /**
     * @method renderContent
     * @description
     * Renders the heading container element
     * @returns {Element} Rendered content element
     */
    renderContent() {
        const { content, children, renderAsHTML, contentClass } = this.props;
        if (renderAsHTML) {
            return React.createElement('div', {
                className: `imc-expand-collapse__content ${contentClass}`,
                dangerouslySetInnerHTML: { __html: content },
            });
        }
        return React.createElement('div', {
            className: `imc-expand-collapse__content ${contentClass}`,
        },
            children[1],
        );
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { expandCollapse, addFlex, additionalHeadingClass, heading, alwaysExpanded } = this.props;
        const { expanded } = this.state;

        const expandedClass = expanded ? 'imc-expand-collapse--expanded' : '';
        const isMobile = expandCollapse === 'mobile' ? 'imc-expand-collapse--mobile' : '';
        const isFlex = addFlex ? 'imc-expand-collapse--flex' : '';
        const alwaysExpandedClass = alwaysExpanded ? 'imc-expand-collapse--expanded imc-expand-collapse--always' : '';

        return (
            <div
                ref={(elem) => { this.nv = elem; }}
                className={`imc-expand-collapse ${expandedClass} ${isMobile} ${isFlex} ${alwaysExpandedClass}`}
            ><div
                tabIndex={0}
                role="button"
                className={`imc-expand-collapse__heading ${additionalHeadingClass}`}
                ref={(node) => { this.expandCollapseHeading = node; }}
            >
                    <a
                        tabIndex={0}
                        role="button"
                        aria-expanded={expanded}
                        onClick={this.toggleExpanded}
                        onKeyPress={this.expandKeypress}
                        aria-label={heading}
                    >
                        {this.renderHeading()}
                    </a>
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{heading: *, content: *, expanded: shim, expandCollapse: *, addFlex: shim}}
 */
ExpandCollapse.propTypes = {
    heading: PropTypes.string, // Heading string that is clickable
    content: PropTypes.string, // Content that expands or collapses
    expanded: PropTypes.bool, // determines if expanded by default
    expandCollapse: PropTypes.string, // If value is mobile then it will add mobile specific class
    addFlex: PropTypes.bool, // determines if display flex need to add
    // scrollToTop: PropTypes.bool, // determines if we need to scroll up the content
    // scrollOffset: PropTypes.string, // if any offset for scroll,
    // scrollOffsetCategory: PropTypes.string, // if any offset for scroll,
    expandedUpdated: PropTypes.func, // Need to used for updating parent state
    additionalHeadingClass: PropTypes.string, // If any additional heading class needed
    renderAsHTML: PropTypes.bool, // Renders the header and content as HTML
    // accordianIndex: PropTypes.number, // accordianIndex for expand collapse
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
        PropTypes.node,
    ]).isRequired,
    contentClass: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{expanded: boolean}}
 */
ExpandCollapse.defaultProps = {
    heading: '',
    content: '',
    expanded: true,
    expandCollapse: 'all',
    addFlex: false,
    scrollToTop: true,
    scrollOffset: '',
    scrollOffsetCategory: '',
    expandedUpdated: () => { },
    additionalHeadingClass: '',
    renderAsHTML: false,
    children: {},
    contentClass: '',
    accordianIndex: null,
};
