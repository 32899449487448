import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    tag: PropTypes.string,
    url: PropTypes.string,
    className: PropTypes.string,
    clickEvent: PropTypes.func,
};

const defaultProps = {
    tag: 'some tag',
    url: null,
    className: null,
    clickEvent: null,
    isType: false,
};

/**
 * render link
 * @param {string} tag tag
 * @param {string} url destination url
 * @param {func} clickEvent Click event to occur
 * @returns {JSX} tag
 */
function renderTagText(tag, url, clickEvent, isType) {
    let className= 'imc-button--border-full imc-button--no-border imc-button--primary-inverted imc-button--small imc-button--small-xy imc-section--neutral-light imc-content--normal imc-content--milli'
    let outItem = null;
    if (clickEvent) {
        outItem = <button className={className} onClick={clickEvent}>{tag}</button>;
    } else if (isType){
        outItem = <button className={className}>{tag}</button>;
    }else{
        outItem = url ? <a className={className} href={url}>{tag}</a> : tag;
    }
    return outItem;
}

/**
 * Render a tag
 * @param {object} props incoming props
 * @returns {XML} Tag component
 */
export default function Tag(props) {
    const { key, tag, url, className, clickEvent, isType } = props;
    return (
        <li key={key}
            className={`${className} imc-content--milli imc-content--alt2  imc-content--pointer-events-none`}
        >
            {renderTagText(tag, url, clickEvent, isType)}
        </li>
    );
}

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;
