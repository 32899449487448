import { parse, format, set } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

export function normalizeDate(
  dateString: string | null,
  start: boolean = true,
  utc: boolean = false,
  inputTime: string = '00:00:00',
): Date | null {
  if (!dateString) return null; // Handle null or missing inputs

  const timeZone: string | null = (window as any)?.userInfo?.timeZone || null; // Default to user's timezone
  const timeParts = inputTime.split(':').map(Number => parseInt(Number));
  const time = start
    ? {
        hours: timeParts[0] || 0,
        minutes: timeParts[1] || 0,
        seconds: timeParts[2] || 0,
        milliseconds: 0,
      }
    : { hours: 23, minutes: 59, seconds: 59, milliseconds: 0 };

  // Step 1: Parse the date string (assumes format "yyyy-M-d")
  let parsedDate: Date = parse(dateString, 'yyyy-M-d', new Date());
  if (utc) {
    parsedDate = new Date(dateString); // If `utc` is true, use the date string as-is
  }

  // Step 2: Convert to UTC using the specified timezone
  if (timeZone) {
    // Convert parsed date to UTC based on the given timezone
    const utcDate = fromZonedTime(parsedDate, timeZone);
    // Ensure time is explicitly set to midnight in UTC
    return set(utcDate, { hours: time.hours, minutes: time.minutes, seconds: time.seconds, milliseconds: time.milliseconds });
  }

  // Fallback to returning the parsed date set to midnight in the local timezone
  return set(parsedDate, { hours: time.hours, minutes: time.minutes, seconds: time.seconds, milliseconds: time.milliseconds });
}

export function formatConsistentDate(date: Date): string {
  const day = format(date, 'dd');
  const dayOfWeek = format(date, 'eeee');
  const month = format(date, 'LLLL');
  const year = format(date, 'yyyy');

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export function formatConsistentDateRange(dateRange: DateRange): string {
  if (dateRange.start === dateRange.end) {
    return formatConsistentDate(dateRange.start);
  } else {
    const startDay = format(dateRange.start, 'dd');
    const startDayOfWeek = format(dateRange.start, 'eeee');
    const startMonth = format(dateRange.start, 'LLLL');
    const startYear = format(dateRange.start, 'yyyy');
    const endDay = format(dateRange.end, 'dd');
    const endDayOfWeek = format(dateRange.end, 'eeee');
    const endMonth = format(dateRange.end, 'LLLL');
    const endYear = format(dateRange.end, 'yyyy');

    return `${startDayOfWeek}, ${startMonth} ${startDay}${
      startYear !== endYear ? `, ${startYear}` : ''
    } - ${endDayOfWeek}, ${endMonth} ${endDay}, ${endYear}`;
  }
}

export function getLocalDate(date: string): Date {
    const start = new Date(date);
    return new Date(start.valueOf() + start.getTimezoneOffset() * 60 * 1000);
}