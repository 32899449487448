import {
    SET_EXHIBITED_ON_THIS_FLOOR,
    SET_EXHIBITED_ON_THIS_FLOOR_ERR,
    SHOW_LOADING,
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';

/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();

/**
 * Action to get Digital Showroom Info
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitedOnThisFloor(buildingId, floorId, count, term, hasProducts, shopZioAvailable, sortType, loadingStateFunc) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitedOnThisFloor(buildingId, floorId, count, hasProducts, shopZioAvailable, sortType).then((response) => {
            //filter by term
            response = response.filter(x => (x.name && x.name.toLowerCase().indexOf(term.toLowerCase()) >= 0) || term == '');
            if (response) {
                dispatch({
                    type: SET_EXHIBITED_ON_THIS_FLOOR,
                    lines: response,
                    linesCount: response.length,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
                loadingStateFunc(false);
            } else {
                dispatch({
                    type: SET_EXHIBITED_ON_THIS_FLOOR_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}