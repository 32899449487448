import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../components/Link';

import { SearchCard } from 'modules/searchcard';
import { CardTags } from 'modules/cardtags';

import { getDictionaryValue } from 'utils/dictionary/index';
import { updateUrl } from 'utils/tagupdate';
import { INFORMATION_IDENTIFIER } from 'utils/exhibitor';
import { TAGS_IDENTIFIER } from 'utils/search';
import {withSitecoreRouter} from 'utils/withRouter';


const propTypes = {
    title: PropTypes.string,
    metaData: PropTypes.object,
    destinationUrl: PropTypes.string,
    labels: PropTypes.object,
    tag: PropTypes.array,
    id: PropTypes.string,
    moreLabel: PropTypes.string,
    pageDescription: PropTypes.string,
    pageBreadcrumbs: PropTypes.string,
};

const defaultProps = {
    title: '',
    publishDate: '',
    metaData: {},
    destinationUrl: '#',
    filterKey: INFORMATION_IDENTIFIER,
    tagKey: TAGS_IDENTIFIER,
    tag: [],
    id: '',
    pageDescription: '',
    pageBreadcrumbs: '',
};

/**
 * @method ExhibitorCard
 * @description
 * Display Img, Title, details,etc provided in a card format
 * @param {object} props Incoming props
 * @returns {NodeList} DOM elements to render
 */
class InformationCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateTagUrl = this.updateTagUrl.bind(this);
    }

    /**
     * Updates the URL in the following manner:
     * ?{props.filterKey}=encode(filter=true&{props.tagKey=tag1|tag2|tag3})
     * @param { string } tag string to update url with
     */
    updateTagUrl(event) {
        const { router, match, filterKey, tagKey } = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateUrl(tag, router, match, filterKey, tagKey);
    }


    render() {
        const { title, metaData, pageBreadcrumbs, pageDescription, destinationUrl, id, tags } = this.props;
        const props = {
            search: true,
            ...this.props,
            teaserText: pageDescription,
            smallImage: null
        }
        return (
            <>
                <SearchCard {...props} />
            </>
        );
    }
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
// function mapStateToProps(state) {
//     return {
//         labels: state.labelConfigReducer.labels,
//         moreLabel: state.labelConfigReducer.labels.searchResults.moreLabel,
//     };
// }

InformationCard.propTypes = propTypes;
InformationCard.defaultProps = defaultProps;

export default withSitecoreRouter(InformationCard);
