import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withSitecoreRouter } from 'utils/withRouter';

import CalendarRange from 'modules/calendar/CalendarRange';
import EventCard from './components/EventCard';
import SortSearchResults from '../../components/Sort-Search-Results';
import { ReactComponent as NoResultsImage } from '../../assets/svg/common/noResults.svg';
import {getEventTypes} from '../../api/events';
import { encode, decode } from 'utils/querystring';
import { showMarketPlan } from 'utils/general';
import { formatConsistentDate, formatConsistentDateRange } from 'utils/dates';

const EVENT_TYPE = {
    IMC: "IMC",
    EXHIBITOR: "Exhibitor",
}
function haveSameElements(list1, list2) {
    if (!list1 && !list2) return true;
    if (list1 && list2 == undefined) return false;
    if (list2 && list1 == undefined) return false;
    if (list1.length !== list2.length) return false;
    return [...list1].sort().every((item, index) => item === [...list2].sort()[index]);
}

class EventList extends PureComponent {
    static propTypes = {
        fields: PropTypes.object,
        sitecoreContent: PropTypes.object,
    };

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            allEvents: [],
            labels: this.props.fields,
            currentlySelectedEvents: [],
            eventTypes: [EVENT_TYPE.EXHIBITOR, EVENT_TYPE.IMC],
            filters: {},
            isLoading: true,
            selectedCalendarDateRange: null,
            searchText: "",
            modalOpen: false,
            exhibitorEvents: [],
            IMCEvents: [],
            showClearSearch: {},
            eventTopics: [{
                key: '',
                value: 'Event Type'
            }],
            topic: '',
            market: null,
        };

        this.efficientlyParseEventItemsByOwner = this.efficientlyParseEventItemsByOwner.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.renderAllItemsByTime = this.renderAllItemsByTime.bind(this);
        this.updateQueryString = this.updateQueryString.bind(this);
        this.renderEventCard = this.renderEventCard.bind(this);
        this.onClickDay = this.onClickDay.bind(this);
        this.onResetDate = this.onResetDate.bind(this);
        this.onResetAll = this.onResetAll.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onTabSelect = this.onTabSelect.bind(this);
        this.renderCalendar = this.renderCalendar.bind(this);
        this.onToggleModal = this.onToggleModal.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.renderNoResultsMsg = this.renderNoResultsMsg.bind(this);
        this.currentlySelectedEvents = this.getCurrentlySelectedEvents.bind(this);
        this.adjustDate = this.adjustDate.bind(this);
    }


    componentDidMount() {
        this.getEvents()
        this.updateQueryString();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.router.location != this.props.router.location) {
            let searchParams = decode(typeof window !== "undefined" ? this.props.router.location.search : null);
            let prevSearchParams = decode(typeof window !== "undefined" ? prevProps.router.location.search : null);
            if (!haveSameElements(searchParams.topic, prevSearchParams.topic)) {
                this.onOrderChange(this.state.eventTypes, (searchParams?.topic?.length > 0) ? searchParams.topic[0] : '');
            }

        }
        if (this.props?.marketPlanReducer?.loaded &&this.props?.marketPlanReducer?.market && (this.state.market == null || this.props?.marketPlanReducer?.market.displayName != this.state.market.displayName)) {
            this.setState({ market: this.props.marketPlanReducer.market });
        }
    }

    async getEvents() {
        const responses = [getEventTypes()]
        if (this?.props?.fields?.channels && this.props.fields.channels.length > 0) {
            this.props.fields.channels.forEach(channel => {
                if (channel?.fields?.link?.value?.href && channel?.fields?.channelHeader?.value) responses.push(getEventTypes('', false, channel.fields.link.value.href, channel.fields.channelHeader.value));
            });
        }
        const awaitEvents = await Promise.all(responses);
        const filteredEvents = awaitEvents.map((response, r) => {
            if (r == 0) return response;
            const data = response.data.filter((event) => {
                return event.eventOwner == EVENT_TYPE.IMC;
            });
            return {
                count: data.length,
                data: data
            }
        });
        const allEvents = {
            count: filteredEvents.reduce((acc, response) => acc + response.count, 0),
            data: filteredEvents.map((response) => response.data).flat()
        };
        const events = {
            ...allEvents,
            data: allEvents.data.map((event) => {
                let startDateTime = (event.startDateTime.indexOf('Z') > 0) ? event.startDateTime : `${event.startDateTime}Z`;
                let endDateTime = (event.endDateTime.indexOf('Z') > 0) ? event.endDateTime : `${event.endDateTime}Z`;
                let today = this.adjustDate(new Date(), startDateTime);
                if (new Date(today) > new Date(startDateTime)) {
                    startDateTime = today;
                }
                return {
                    ...event,
                    startDateTime,
                    endDateTime,
                };
            })
        };
        const { exhibitorEvents, IMCEvents } = this.efficientlyParseEventItemsByOwner(events.data);
        const eventsWithTopics = events.data.filter(item => (item.eventType));
        const topics = (eventsWithTopics.length > 0) ? Array.from(new Set(eventsWithTopics.map(item => item.eventType))).map(eventType => {
            return {
                key: eventType.toLowerCase().replace(' ', ''),
                value: eventType,
            };
        }) : [];

        let tabs = this.state.eventTypes;
        let searchParams = decode(typeof window !== "undefined" ? this.props.router.location.search : null);
        let topic = (searchParams.topic?.length > 0) ? searchParams.topic[0] : '';
        let { currentlySelectedEvents: currentEvents, type } = this.getCurrentlySelectedEvents(exhibitorEvents, IMCEvents, tabs, topics, (searchParams.topic?.length > 0) ? searchParams.topic[0] : undefined);

        if ((typeof window !== 'undefined') && window.location && window.location.hash && window.location.hash.replace("#","").toLowerCase() == "exhibitor") {
            currentEvents = exhibitorEvents;
            tabs = [EVENT_TYPE.EXHIBITOR];
        }

        this.setState({
            count: events.count,
            currentlySelectedEvents: currentEvents,
            allEvents: events.data,
            exhibitorEvents,
            IMCEvents,
            isLoading: false,
            eventTypes: tabs,
            eventTopics: [
                ...this.state.eventTopics,
                ...topics
            ],
            topic: topic,
        });
    }

    updateQueryString() {
        let type = "all"
        let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
        if (searchParams?.q && searchParams.q.length > 0) {
            this.setState({ searchText: searchParams.q[0] });
        }
        if (searchParams?.type && searchParams.type.length > 0) {
            if (searchParams.type[0] == 'exhibitor') {
                this.onTabSelect([EVENT_TYPE.EXHIBITOR]);
            } else if (searchParams.type == 'market') {
                this.onTabSelect([EVENT_TYPE.IMC]);
            }
        }
        if ((typeof window !== 'undefined') && window.location && window.location.hash && window.location.hash.replace("#","").toLowerCase() == "exhibitor") {
            this.onTabSelect([EVENT_TYPE.EXHIBITOR]);
        }
    }

    renderEventCard(cardItem) {
        const { learnMoreLabel } = this.props?.fields;

        const formattedRange = formatConsistentDateRange({
            start: cardItem.formattedDate,
            end: cardItem.formattedEndDate,
        });

        return (
          <EventCard
            key={`cardItem${cardItem.id}`}
            eventData={cardItem}
            startDate={cardItem.startDateTime ? formatConsistentDate(new Date(cardItem.startDateTime)) : null}
            endDate={cardItem.endDateTime ? formatConsistentDate(new Date(cardItem.endDateTime)) : null}
            learnMoreLabel={learnMoreLabel}
            formattedRange={formattedRange}
            event={cardItem}
          />
        );
    }

    renderNoResultsMsg() {
        return (
            <div className='event-card--svg-container'>
                <NoResultsImage className='event-card--svg' />
                <p>Sorry, no results found.</p>
            </div>
        )
    }

    renderAllItemsByTime() {
        const { showMultiDay } = this.props.fields;
        const { currentlySelectedEvents, eventTypes, selectedCalendarDateRange, searchText } = this.state;

        const formattedEvents = {};

        if (currentlySelectedEvents.length < 1) {
            return this.renderNoResultsMsg();
        }

        currentlySelectedEvents.forEach((eventItem) => {
            let isInDateRange = (selectedCalendarDateRange) ? false : true;
            let isCorrectType = false;
            let hasSearchTerm = false;

            if (searchText.length > 0) {
                if (eventItem?.title && eventItem.title.toLowerCase().includes(searchText.toLowerCase())) {
                    hasSearchTerm = true
                } else {
                    return;
                };
            }

            if (selectedCalendarDateRange) {
                const selectedStart = new Date(selectedCalendarDateRange.start);
                const selectedEnd = new Date(selectedCalendarDateRange.end);
                const dateFrom = new Date(eventItem.formattedDate);
                const dateTo = new Date(eventItem.formattedEndDate);
                let isBetween = false;
            
                if (dateFrom && dateTo) {
                    // Check if the selected date range overlaps with the event date range
                    isBetween = selectedStart <= dateTo && selectedEnd >= dateFrom;
                }
            
                isInDateRange = isBetween
                    || selectedStart === dateFrom
                    || selectedEnd === dateTo;
            } else {
                isInDateRange = true;
            }

            eventTypes.forEach((type) => {
                if (type === EVENT_TYPE.IMC && eventItem?.isIMCEvent || type === EVENT_TYPE.EXHIBITOR && eventItem?.isExhibitorEvent) {
                    isCorrectType = true;
                }
            })
            if (!isCorrectType) {
                return;
            }

            if (!isInDateRange) {
                return;
            }

            if (showMultiDay?.value == true && eventItem?.isIMCEvent) {
                let dateFrom = new Date(eventItem.startDateTime);
                let dateTo = new Date(eventItem.endDateTime);
                if (selectedCalendarDateRange) {
                    if (this.adjustDate(new Date(selectedCalendarDateRange.start), eventItem.startDateTime, true) > dateFrom) {
                        dateFrom = this.adjustDate(new Date(selectedCalendarDateRange.start), eventItem.startDateTime);
                    }
                    if (this.adjustDate(new Date(selectedCalendarDateRange.end), eventItem.endDateTime, true) < dateTo) {
                        dateTo = this.adjustDate(new Date(selectedCalendarDateRange.end), eventItem.endDateTime);
                    }
                }
                const allDates = [];
                let currentDate = new Date(dateFrom);
                while (currentDate <= new Date(dateTo)) {
                    allDates.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                allDates.forEach((date) => {
                    const selectedDate = formatConsistentDate(date);
                    if (!formattedEvents[selectedDate]) {
                        formattedEvents[selectedDate] = {
                            events: []
                        }
                    }
                    formattedEvents[selectedDate].events.push({
                        ...eventItem,
                        sortStartDateTime: this.adjustDate(date, eventItem.startDateTime),
                        sortEndDateTime: this.adjustDate(date, eventItem.endDateTime),
                    });
                });
            } else {
                let minStartTime = eventItem.startDateTime;
                let maxEndTime = this.adjustDate(new Date(minStartTime), eventItem.endDateTime);
                if (selectedCalendarDateRange) {
                    if (this.adjustDate(new Date(selectedCalendarDateRange.start), eventItem.startDateTime, true) > new Date(minStartTime)) {
                        minStartTime = this.adjustDate(new Date(selectedCalendarDateRange.start), eventItem.startDateTime);
                        maxEndTime = this.adjustDate(new Date(selectedCalendarDateRange.start), eventItem.endDateTime);
                    }
                }
                const selectedDate = formatConsistentDate(minStartTime);
                if (!formattedEvents[selectedDate]) {
                    formattedEvents[selectedDate] = {
                        events: []
                    }
                }
                formattedEvents[selectedDate].events.push({
                    ...eventItem,
                    sortStartDateTime: minStartTime,
                    sortEndDateTime: maxEndTime,
                });
            }
        });

        const props = Object.keys(formattedEvents).sort((a, b) => new Date(a) - new Date(b));
        const cardResults = props.map((groupDate) => {
            const events = formattedEvents[groupDate].events;
            return(<div
              className='event-date--container imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--display-flex-column imc-content--display-flex-wrap'
              key={`eventItem${groupDate}`}
            >
                <p className='event-date--item'>{`${groupDate} | ${events.length} Event${(events.length > 1) ? `s` : ``}`}</p>
                {events.sort((a, b) => {
                    if (a.sortStartDateTime === b.sortStartDateTime) {
                        return new Date(a.sortEndDateTime) - new Date(b.sortEndDateTime);
                    }
                    return new Date(a.sortStartDateTime) - new Date(b.sortStartDateTime);
                }).map(eventItem => {
                    return (
                        <div className='event-card--multi-card-container'>
                            {this.renderEventCard(eventItem)}
                        </div>
                    );
                  })}
            </div>);
        });
        return cardResults.length > 0 ? cardResults : this.renderNoResultsMsg();
    }

    adjustDate(desiredDate, desiredTime, returnDate = false) {
        const firstDate = new Date(desiredDate);
        const secondDate = new Date(desiredTime);

        const timeHours = secondDate.getUTCHours();
        const timeMinutes = secondDate.getUTCMinutes();
        const timeSeconds = secondDate.getUTCSeconds();
        const newDate = new Date(Date.UTC(firstDate.getUTCFullYear(), firstDate.getUTCMonth(), firstDate.getUTCDate(), timeHours, timeMinutes, timeSeconds));

        if (returnDate) return newDate;
        return newDate.toISOString().split('.')[0] + 'Z';
    }

    // Runs once when component mounts to avoid multiple nested loop operations
    efficientlyParseEventItemsByOwner(events) {
        let exhibitorEvents = [];
        let IMCEvents = [];

        events.map((item) => {
            const isImcEvent = item.eventOwner === EVENT_TYPE.IMC;
            const isExhibitorEvent = item.eventOwner == EVENT_TYPE.EXHIBITOR;

            const event = {
                eventDate: item.formattedDate,
                isIMCEvent: isImcEvent,
                isExhibitorEvent: isExhibitorEvent,
                  ...item
            };

            if (event.isIMCEvent){
                IMCEvents.push(event);
            }

            if(event.isExhibitorEvent){
                exhibitorEvents.push(event);
            }
        })

        return { exhibitorEvents, IMCEvents };
    }

    onClickDay(dates) {
        this.setState({ selectedCalendarDateRange: dates })
    }

    onResetDate() {
        this.setState({ selectedCalendarDateRange: null })
    }

    onResetAll() {
        this.setState({
            selectedCalendarDateRange: null,
            searchText: "",
            topic: ""
        })
        let searchParams = decode(typeof window !== "undefined" ? this.props.router.location.search : null);
        if (searchParams) {
            if (searchParams.q) delete searchParams.q;
            if (searchParams.startdate) delete searchParams.startdate;
            if (searchParams.enddate) delete searchParams.enddate;
            if (searchParams.topic) delete searchParams.topic;
            this.props.router.navigate({search: encode(searchParams, false, false)});
        }
    }

    onSearchChange(text) {
        let searchParams = decode(typeof window !== "undefined" ? this.props.router.location.search : null);
        if (searchParams.q !== text?.target?.value) {
            searchParams.q = text?.target?.value;
            if (searchParams.q === "") {
                delete searchParams.q;
            }
            this.props.router.navigate({search: encode(searchParams, false, false)});
        }
        this.setState({ searchText: text?.target?.value })
    }

    onClearSearch() {
        this.onSearchChange({ target: { value: "" } });
    }

    getCurrentlySelectedEvents(exhibitorEvents, IMCEvents, tabs, eventTopics, topic = undefined) {
        let currentlySelectedEvents = [];
        let type = "all";

        if (tabs.some(eventType => eventType === EVENT_TYPE.IMC)) {
            currentlySelectedEvents = [...IMCEvents];
            type = "market";
        }

        if (tabs.some(eventType => eventType === EVENT_TYPE.EXHIBITOR)) {
            currentlySelectedEvents = currentlySelectedEvents.concat(exhibitorEvents);
            if (type == "market") {
                type = "all";
            } else {
                type = "exhibitor";
            }
        }

        if ((topic != undefined && topic != '') || (topic == undefined && this.state.topic != '')) {
            let so = (topic != undefined) ? topic : this.state.topic;
            let currentTopic = eventTopics.find(item => item.key === so);
            if (currentTopic?.value) currentlySelectedEvents = currentlySelectedEvents.filter((event) => {
                return event.eventType == currentTopic.value
            });
        }

        return {currentlySelectedEvents, type};
    }

    onOrderChange(tabs, topic) {
        let { currentlySelectedEvents, type } = this.getCurrentlySelectedEvents(this.state.exhibitorEvents, this.state.IMCEvents, tabs, this.state.eventTopics, topic);
        this.setState({
            topic: topic,
            currentlySelectedEvents: currentlySelectedEvents,
        });

    }

    onTabSelect(tabs) {
        const { router } = this.props;
        const { exhibitorEvents, IMCEvents } = this.state;
        let { currentlySelectedEvents, type } = this.getCurrentlySelectedEvents(this.state.exhibitorEvents, this.state.IMCEvents, tabs, this.state.eventTopics);

        let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
        if (searchParams.type !== type) {
            let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
            searchParams.type = type;
            router.navigate({search: encode(searchParams, false, false)});
        }
        
        this.setState({
            eventTypes: tabs,
            currentlySelectedEvents,
        })
    }

    onToggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    renderCalendar() {
        const { currentlySelectedEvents } = this.state;

        return (
            <CalendarRange
                events={currentlySelectedEvents}
                onClickDay={this.onClickDay}
                onChange={this.onClickDay}
                formatDate={formatConsistentDate}
                onResetDate={this.onResetDate}
                router={this.props.router}
                dateRange={this.state.selectedCalendarDateRange}
                market={this.state.market}
            />
        )
    }

    render() {

        const { eventTypes, isLoading, currentlySelectedEvents } = this.state;
        const svgClass = 'enabled';
        const { showClearSearch, heading, allEventsLabel, marketEventsLabel, exhibitorEventsLabel } = this.props.fields;
        let hideSubmitButton = true;
        let disabled = false;

        if (currentlySelectedEvents.length <= 0) {
            disabled = true;
        }

        return (
            <div className="event-list imc-loading__anchor">
                {isLoading && <p>Loading...</p>}
                {!isLoading && (
                    <div className="imc-section--padded-top-xlarge imc-section--padded-bottom-xlarge">
                        {(heading?.value?.trim()) && <h1 className={`imc-section--padded-bottom-xlarge andmore-bold`}>{heading.value}</h1>}
                        {/* Tabs */}
                        {((allEventsLabel?.value?.trim()) || (marketEventsLabel?.value?.trim()) || (exhibitorEventsLabel?.value?.trim())) && <div className='event-card--tab'>
                            {(allEventsLabel?.value?.trim()) && <p
                                onClick={() => this.onTabSelect([EVENT_TYPE.EXHIBITOR, EVENT_TYPE.IMC])}
                                className={classNames('event-card--tab--item', {
                                    'event-card--tab--selected': eventTypes.some(eventType => eventType === EVENT_TYPE.IMC) && eventTypes.some(eventType => eventType === EVENT_TYPE.EXHIBITOR)
                                })}
                            >{allEventsLabel.value}</p>}
                            {(marketEventsLabel?.value?.trim()) && <p
                                onClick={() => this.onTabSelect([EVENT_TYPE.IMC])}
                                className={classNames('event-card--tab--item', {
                                    'event-card--tab--selected': eventTypes.some(eventType => eventType === EVENT_TYPE.IMC) && !eventTypes.some(eventType => eventType === EVENT_TYPE.EXHIBITOR)
                                })}
                            >{marketEventsLabel.value}</p>}
                            {(exhibitorEventsLabel?.value?.trim()) && <p
                                onClick={() => this.onTabSelect([EVENT_TYPE.EXHIBITOR])}
                                className={classNames('event-card--tab--item', {
                                    'event-card--tab--selected': eventTypes.some(eventType => eventType === EVENT_TYPE.EXHIBITOR) && !eventTypes.some(eventType => eventType === EVENT_TYPE.IMC)
                                })}
                            >{exhibitorEventsLabel.value}</p>}
                        </div>}
                        {/* Search */}
                        {/* Filters */}
                        <div className='event-card--cal-card-container imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--relative'>
                            <div className='event-card--filters imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--display-flex-column'>
                                <div className={`event-card--reset imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-space-between`}>
                                    <p className={`imc-content--beta imc-content--bold imc-breakpoint-display--hide-mobile`}>Filters</p>
                                    <button class={`imc-filter--reset-button`} onClick={() => {this.onResetAll()}}>Reset</button>
                                </div>
                                <div className='event-card--cal-btn-container'>
                                    <div
                                        className='imc-searchform--row event-card--search'
                                    >
                                        <input
                                            className='imc-searchform--global imc-searchform--input imc-searchform--input'
                                            type="text"
                                            value={this.state.searchText}
                                            name="EventSearch"
                                            placeholder="Search"
                                            onChange={this.onSearchChange}
                                            ref={this.searchInput}
                                            disabled={disabled}
                                        />

                                        {showClearSearch?.value &&
                                            <button
                                                type="button"
                                                disabled={false}
                                                onClick={this.onClearSearch}
                                                aria-label={'Clear Search'}
                                                className={`imc-searchform--button--clear
                                    ${hideSubmitButton ? 'imc-searchform--button--clear--floorplan--hidesubmit' : ''}`}
                                            >
                                                <svg
                                                    className={svgClass}
                                                    width="10"
                                                    height="10"
                                                    role="img"
                                                    aria-labelledby="clear-search-title clear-search-desc"
                                                >
                                                    <title id="clear-search-title">{'Clear Search'}</title>
                                                    <desc id="clear-search-desc">{'Clear the Search Form'}</desc>
                                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-close" />
                                                </svg>
                                            </button>
                                        }
                                        <button
                                            className='imc-searchform--button--search'
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                role="img"
                                                aria-labelledby="btn-search-title btn-search-desc"
                                            >
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                {this.renderCalendar()}
                                {(this.state.eventTopics.length > 1) && <SortSearchResults options={this.state.eventTopics} label={`topic`} />}
                            </div>
                            {/* Cards */}
                            <div className='event-card--all-container  imc-content--display-flex imc-content--display-flex-gap-xlarge imc-content--display-flex-column'>
                                {this.renderAllItemsByTime()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        plan: {
            ...state.marketPlanReducer
        },
    };
}

export default connect(mapStateToProps)(withSitecoreRouter(EventList));
