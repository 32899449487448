import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag.jsx';
import MoreCta from './MoreCta.jsx';

const propTypes = {
    className: PropTypes.string,
    dataXPath: PropTypes.string,
    tags: PropTypes.array,
    id: PropTypes.string.isRequired,
    maxTagsToDisplay: PropTypes.number,
    labelMoreTags: PropTypes.string,
    destinationUrl: PropTypes.string,
    clickEvent: PropTypes.func,
};

const defaultProps = {
    className: '',
    dataXPath: '',
    tags: [],
    maxTagsToDisplay: 3,
    labelMoreTags: '+{num} More',
    destinationUrl: '',
    clickEvent: null,
};


/**
 * Render Card "Tags"
 */
export default class CardTags extends Component {
    /**
     * Render Tags and MORE cta
     * @returns {XML} tag collection
     */
    renderEventTags() {
        const { tags, id, maxTagsToDisplay, labelMoreTags, destinationUrl, clickEvent, eventType } = this.props;
        const tagCount = tags &&  tags.length;
        if (tagCount > maxTagsToDisplay) {
            const tagCollection = [];
            if(!!eventType)
                tagCollection.push(<Tag key={`${id}-${eventType}`} tag={eventType} isType={true}/>)
            for (let i = 0; i < (maxTagsToDisplay ); i++) {
                const tag = tags[i];
                tagCollection.push(<Tag key={`${id}-${tag}`} tag={tag} clickEvent={clickEvent} />);
            }
            let remainingTags = tagCount - maxTagsToDisplay;
            // one less than maxTags should be shown
            // when more cta is displayed
            ///remainingTags += 1;
            tagCollection.push(
                <MoreCta
                    key={`${id}-more`}
                    remainingTags={remainingTags}
                    labelMoreTags={labelMoreTags}
                    destinationUrl={destinationUrl}
                />,
            );
            return tagCollection;
        }else{
            const tagCollection = [];
            if(!!eventType)
                tagCollection.push(<Tag key={`${id}-${eventType}`} tag={eventType} isType={true}/>)
            if(!!tags){
                for (let i = 0; i < tags.length; i++) {
                    const tag = tags[i];
                    tagCollection.push(<Tag key={`${id}-${tag}`} tag={tag} clickEvent={clickEvent} />);
                }
            }
            return tagCollection;
        }
        // return (tags && tags.map(tag => <Tag key={`${id}-${tag}`} tag={tag} clickEvent={clickEvent} />));
    }

    /**
     * Render Method
     * @returns {XML} CardTags
     */
    render() {
        const { className, dataXPath } = this.props;
        return (
            <ul className={`imc-content--light ${className} imc-content--display-flex imc-content--display-flex-gap-medium imc-content--display-flex-center`} data-xpath={dataXPath}>
                {this.renderEventTags()}
            </ul>
        );
    }
}

CardTags.propTypes = propTypes;
CardTags.defaultProps = defaultProps;
