import React from 'react';
import moment, { Moment as MomentType} from 'moment';
import Moment from 'react-moment';
import EventsUtility from 'utils/events';
import SvgIcon from 'modules/svgicon';
import { formatISO, parse } from 'date-fns';
import { getLocalDate } from 'utils/dates';

const getTimeFormat = (time: MomentType) => {
    let format = "h";
    if (time.minutes() !== 0) format += ":mm";
    format += "A";
    return format;
};


const Vendor = (vendor: any, buidlingName: string, buildingIndex: number, floorName: string, floorIndex: number, vendorIndex: number) => {
    const locations = (vendor.locations && vendor.locations.length > 0) ? vendor.location.targetItems.map((location: any) => {
        return {
            showroomBuilding: location.showroomBuilding || null,
            showroomFloor: location.showroomFloor || null,
            showroom: location.showroom.value || null,
            showroomBuildingFloor: location.showroomBuildingFloor.value || null
        }
    }) : [{
        showroomBuilding: null,
        showroomFloor: null,
        showroom: null,
        showroomBuildingFloor: `${buidlingName}, ${floorName}`
    }];
    return (                      
        <div key={`floor-${buildingIndex}-${floorIndex}-${vendorIndex}`} className={``}>
            <div className="imc-content--full-height imc-content--display-flex imc-content--display-flex-column section-wrapper imc-content--border-radius-8 imc-button--round imc-section imc-section--basic-white imc-section--padded-bottom-large imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large imc-content--drop-shadow-extra-light">
                <div className={`imc-content--flex-grow imc-content--display-flex imc-content--display-flex-gap-medium imc-content--display-flex-wrap imc-content--display-flex-column`}>
                    <div className={`imc-content--display-flex imc-content--display-flex-gap-medium imc-content--display-flex-wrap`}>
                        <div className={`imc-content--display-flex imc-content--display-flex-column imc-content--display-flex-gap-none imc-content--flex-grow`}>
                            {vendor?.title?.value && <h4><span className="event-card--title normal-line-height">{vendor.title.value}</span></h4>}
                            {(locations && locations.length > 0) &&
                                <div className="imc-type--title-2-ui imc-content--flex imc-content--display-flex-gap-xsmall">
                                    <div className="imc-content--flex">
                                        <SvgIcon width={18} height={18} xlinkHref={'#IconLocation'} className={`svg-black`} useClassName={`svg-black`} />
                                    </div>
                                    {
                                    `${EventsUtility.getEventLocation(locations[0]).DisplayLocation}`
                                    }
                                </div>
                            }
                            {vendor?.description?.value && <div className="imc-type--title-2-ui imc-content--flex imc-content--display-flex-gap-xsmall">
                                <div className="imc-content--flex">
                                    <SvgIcon width={18} height={18} xlinkHref={'#IconInfo'} className={`svg-black imc-content--visibility-hidden`} useClassName={`svg-black`} />
                                </div>
                                {vendor.description.value}
                            </div>}
                        </div>
                    </div>
                    {(vendor.times && vendor.times.length > 0) &&
                        <div className="imc-content--flex-grow imc-content--border-radius-8 imc-content--display-flex imc-content--display-flex-wrap imc-content--width-fit imc-section--neutral-extra-light imc-section--padded-medium imc-section--padded-left-largemedium imc-section--padded-right-largemedium">
                            {/* <div className="imc-type--title-2-ui"><span className="imc-content--bold">Dates & Hours</span></div> */}
                            <div className="imc-content--display-flex imc-content--display-flex-gap-xsmall imc-content--display-flex-wrap imc-content--display-flex-column">
                                {vendor.times.map((timeRange: any, timeIndex: number) => {
                                    if (timeRange.endDateTime.value == "" && timeRange.note.value != "" && timeRange.startDateTime.value != "") {
                                        const start = getLocalDate(formatISO(parse(timeRange.startDateTime.value, "yyyyMMdd'T'HHmmssX", new Date())));
                                        const startDate = start.getDate();
                                        const startTimeFormat = getTimeFormat(moment(start));
                                        return (
                                            <div>
                                                <div className="imc-type--title-2-ui imc-content--display-flex imc-content--display-flex-gap-small imc-content--display-flex-wrap imc-content--display-flex-row-gap-none">
                                                    <span className={`imc-content--bold imc-content--flex-grow`}>
                                                        <Moment format={`${`ddd M/D`}`} local>{start}</Moment>
                                                    </span>
                                                    {/* <strong>·</strong> */}
                                                    <span>
                                                        {timeRange.note.value}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    } else if (timeRange.endDateTime.value != "" && timeRange.startDateTime.value != "") {
                                        const start = getLocalDate(formatISO(parse(timeRange.startDateTime.value, "yyyyMMdd'T'HHmmssX", new Date())));
                                        const end = getLocalDate(formatISO(parse(timeRange.endDateTime.value, "yyyyMMdd'T'HHmmssX", new Date())));
                                        const startDate = start.getDate();
                                        const endDate = end.getDate();
                                        const startTimeFormat = getTimeFormat(moment(start));
                                        const endTimeFormat = getTimeFormat(moment(end));
                                        return (
                                            <div>
                                                <div className="imc-type--title-2-ui imc-content--display-flex imc-content--display-flex-gap-small imc-content--display-flex-wrap imc-content--display-flex-row-gap-none">
                                                    <span className={`imc-content--bold imc-content--flex-grow`}>
                                                        <Moment format={`${`ddd M/D`}`} local>{start}</Moment>
                                                        {startDate != endDate && <><span> - </span><Moment format={`${`ddd M/D`}`} local>{end}</Moment></>}
                                                    </span>
                                                    {/* <strong>·</strong> */}
                                                    <span>
                                                        <Moment format={startTimeFormat} local>{start}</Moment> - <Moment format={endTimeFormat} local>{end}</Moment>
                                                    </span>
                                                </div>
                                                {(timeRange.note.value) && <span className={`imc-type--title-2-ui `}>{timeRange.note.value}</span>}
                                            </div>
                                        );
                                    } else if (timeRange.endDateTime.value == "" && timeRange.startDateTime.value == "" && timeRange.note.value != "") {
                                        return (
                                            <div>
                                                <div className="imc-type--title-2-ui imc-content--display-flex imc-content--display-flex-gap-small imc-content--display-flex-wrap imc-content--display-flex-row-gap-none">
                                                    <span dangerouslySetInnerHTML={{__html: timeRange.note.value}} />
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    }
                    {(vendor?.tags?.targetItems && vendor?.tags.targetItems.length > 0) &&
                        <div>
                            <div className="imc-type--title-2-ui">
                                <span>
                                    {vendor.tags.targetItems.map((tag: any, tagIndex: number) => {
                                        return tag.field.value.replace(' ', "\u00A0");
                                    }).join(', ')}
                                </span>
                            </div>
                        </div>
                    }
                </div>
                {(vendor?.link?.value) &&
                    <div className={`imc-section--padded-top-medium imc-section--padded-left-largemedium imc-section--padded-right-largemedium`}>
                        <a href="click" target="_blank" className={`imc-button imc-button-- imc-button imc-button--right-arrow imc-button--text-only imc-button--left`}>View Menu</a>
                    </div>
                }
            </div>
        </div>
    )
};

export default Vendor;