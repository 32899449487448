// Library dependencies
import React from 'react';
import { render } from 'react-dom';

// Module dependencies
import { getDataAttributes } from 'utils/getattributes';

// Local dependencies
import ExpandCollapse from '../components/ExpandCollapse';

/**
 * @method getElements
 * @description
 * Retrieved the elements from the DOM
 * @returns {NodeList} DOM elements to render
 */
function getElements() {
    return document.querySelectorAll('[data-expand-collapse]');
}

/**
 * @method renderElement
 * @description
 * Creates Expand/Collapse component instances from the element and its attributes
 * @param {Element} elem The DOM element object to create a Carousel component from
 *
 * @private
 */
function renderElement(elem) {
    const heading = elem.querySelectorAll('[data-expand-collapse-heading]')[0];
    const content = elem.querySelectorAll('[data-expand-collapse-content]')[0];
    const attrs = getDataAttributes(elem);

    attrs.heading = heading.innerHTML;
    attrs.content = content.innerHTML;
    attrs.expanded = (attrs.expanded === 'true');
    attrs.addFlex = (attrs.addFlex === 'true');
    attrs.scrollToTop = (attrs.scrollToTop === 'true');
    attrs.renderAsHTML = true;
    render(
        <ExpandCollapse {...attrs} />,
        elem,
    );
}

/**
 * @method renderExpandCollapse
 * @description
 * Initializes the module by fetching all of the Expand/Collapse elements from the DOM
 * and creating ExpandCollapse component instances
 * and rendering them to the DOM
 */
function renderExpandCollapse() {
    const elems = getElements();

    for (let i = 0; i < elems.length; i++) {
        renderElement(elems[i]);
    }
}


/**
 * Exports public API methods
 */
export default {
    renderExpandCollapse,
};
