import React, { useState, useEffect } from "react";
import { Placeholder, Image, isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ToastContainer, cssTransition } from 'react-toastify';
import ErrorMessage from 'modules/errormessage';
import Link from '../Link';
import { useDispatch } from 'react-redux';
import { use } from "i18next";
import { toggleDrawerState } from '../../modules/marketplan/actions/actions';
import DropdownMenu from 'modules/dropdownmenu/DropdownMenu';
import { padContent, unPadContent, getTopMatter } from "utils/scrollhelpers";
import SvgIcon from 'modules/svgicon';
import { showMarketPlan } from 'utils/general';
import { set, update } from "lodash";
import DesktopNavMenu from "./desktopNavigation";
import SearchDrawer from "./searchDrawer";
import MobileNavMenu from "./mobileNavigation";
import { buildNavigationMenu } from "./interface";
import UserUtility from 'utils/userutility';
import { Environment } from '../../Environment';




interface TopHeaderComponentProps {
  fields?: any;
  rendering?: any;
  sitecoreContext: any;
}

const renderLogo = (fields: any, updatedMenu?: boolean) => {
  let logoClassName = `imc-content--width-fit ${(updatedMenu) ? `imc-content--display-block` : `imc-content--block`}`;
  let logoInnerClassName = `imc-content--display-flex imc-content--display-flex-center ${(updatedMenu) ? `` : `imc-content--full-height`}`;
  return (
    <div className={`imc-header__logo ${(updatedMenu) ? ` imc-content--display-flex-basis-grow imc-content--pointer-events-none` : ``}`}>
    { fields.overrideLogoLink && fields.overrideLogoLink.value.href!="" &&
    <Link field={fields.overrideLogoLink} href={!!fields.overrideLogoLink && fields.overrideLogoLink.value && fields.overrideLogoLink.value.url!=""? fields.overrideLogoLink.value.url: "/" } className={`${logoClassName} ${(updatedMenu) ? ` imc-content--pointer-events` : ``}`} data-xpath="header.logo" title={fields.logo.value.alt}>
      {fields.logo.value.svgCode !== undefined &&
        <div className={logoInnerClassName} dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
      }
      {fields.logo.value.svgCode === undefined &&
        <Image media={fields.logo.value} />
      }
    </Link>
    }
    { !!!fields.overrideLogoLink || fields.overrideLogoLink.value.href=="" &&
    <Link href="/" className={`${logoClassName} ${(updatedMenu) ? ` imc-content--pointer-events` : ``}`} data-xpath="header.logo" title={fields.logo.value.alt}>
      {fields.logo.value.svgCode !== undefined &&
        <div className={logoInnerClassName} dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
      }
      {fields.logo.value.svgCode === undefined &&
        <Image media={fields.logo.value} />
      }
    </Link>
    }
  </div>
  );
}

const TopHeaderComponent: React.FC<TopHeaderComponentProps> = ({fields, rendering, sitecoreContext}) => {

  const showPlan = showMarketPlan({sitecoreContext});
  const isLoggedIn = ((Environment.isHot != undefined) || UserUtility.isLoggedIn());
  const [isHeaderStickied, headerStickied] = useState<boolean>(false);
  const [isNavStickied, navStickied] = useState<boolean>(false);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [desktopNavTop, setDesktopNavTop] = useState(120);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isSearchDrawerOpen, setSearchDrawerOpen] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {

    const handleScroll = () => {
      const {currentScrollTop, minimumScrollUp, minimumScrollDown, stickyHeaderMinScroll, stickyNavMinScroll } = getTopMatter();
      const headerTop = document.getElementsByClassName('imc-header__container-wrapper ')[0].getBoundingClientRect().top;
      const headerBottom = document.getElementsByClassName('imc-header__container-wrapper ')[0].getBoundingClientRect().bottom;
      const velocity = lastScrollTop - currentScrollTop;
      let scrollingUp = (velocity > minimumScrollUp) || (isScrollingUp && velocity >= minimumScrollDown * -1);

      if (!stickyHeaderMinScroll) {
        scrollingUp = false;
      }

      if (headerTop == 0 && currentScrollTop > 0) {
        headerStickied(true);
      } else {
        headerStickied(false);
        scrollingUp = false;
      }

      if (stickyNavMinScroll || scrollingUp) {
        navStickied(true)
      } else {
        navStickied(false);
      }

      setIsScrollingUp(scrollingUp);
      setLastScrollTop(currentScrollTop);
      setDesktopNavTop(headerBottom);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
      if (isDrawerOpen || isSearchDrawerOpen) {
          padContent(window.innerWidth - document.documentElement.getBoundingClientRect().width);
        } else {
          unPadContent();
        }
  }, [isDrawerOpen, isSearchDrawerOpen]);

  let navMenu = buildNavigationMenu(fields, rendering, isLoggedIn);
  const updatedMenu = Array.isArray(navMenu.menus) && navMenu.menus.length > 0;

  const toggleDrawer = (event: any, status: boolean) => {
    if (event == undefined || event?.key === 'Escape') {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(status);
    }
  };

  const toggleSearchDrawer = (event: any, status: boolean) => {
    if (event == undefined || event?.key === 'Escape') {
      setSearchDrawerOpen(false);
    } else {
      setSearchDrawerOpen(status);
    }
  };

  let searchProps = {};
  if (rendering.placeholders['imc-header-placeholder']?.length > 0) {
    searchProps = rendering.placeholders['imc-header-placeholder'][0].fields;
  }

  let searchPlaceholders = null;
  if (rendering.placeholders['imc-header-placeholder']?.length > 0) {
    searchPlaceholders = rendering.placeholders['imc-header-placeholder'][0].placeholders;
  }

  if (typeof fields === 'undefined') {
    if (isEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  if(!(fields.isMobileApp!=null && fields.isMobileApp.value)){
    return <>
      <div className={`imc-header__container-wrapper${updatedMenu ? ' .imc-header__container-updated-menu' : ' imc-breakpoint-display--hide-tablet'}${isHeaderStickied ? ` header-sticky` : ``} ${isNavStickied ? ' nav-sticky' : ''}`} tabIndex={0}>
        {(updatedMenu || isEditorActive()) && <Placeholder name="jss-top-header-top" rendering={rendering}></Placeholder>}
        <ToastContainer limit={3} />
        <div className={`imc-header__container imc-section${updatedMenu ? ' imc-section--full-bleed imc-content--display-flex-stretch imc-content--display-flex-center-tablet imc-content--display-flex-gap-large imc-section--padded-left-large imc-section--padded-right-large' : '  imc-content--display-flex-center'}`}>

          <DropdownMenu
              wrapperClass={`imc-break imc-breakpoint-display--hide-medium-desktop`}
              buttonClassName={`imc-button--icon-only imc-button--transparent-mobile-header imc-button--transparent imc-breakpoint-display--hide-medium-desktop`}
              key={`top-nav-plan`}
              clickHandler={(event?: any, status?: boolean) => {
                toggleDrawer(event, status ? status : true);
              }}
              escapeHandler={(event?: any, status?: boolean) => {
                toggleDrawer(event, status ? status : false);
              }}
              label={``}
              chevron={false}
              icon={`juniper-hamburger`}
              color={`svg-black svg-black-hover`}
              isDrawer={false}
              openState={isDrawerOpen}
              width={24}
              height={24}
              foriegnContent={true}
              previousFocusOnExit={true}
          >
            <></>
          </DropdownMenu>

          {renderLogo(fields, updatedMenu)}

          {showPlan && <div className={`imc-break imc-breakpoint-display--hide-medium-desktop`}>
            <button className={`imc-button--icon-only imc-button--transparent-mobile-header imc-button--transparent`} onClick={() => {
                dispatch(toggleDrawerState(true));
            }}>
              <SvgIcon width={24} height={24} className={`imc-header__logo-svg`} useClassName={`imc-header__logo-svg svg-black-hover`} xlinkHref={`#juniper-on-plan`} />
            </button>
          </div>}

          {!showPlan && <div className={`imc-content--visibility-hidden`}>
          <button className={`imc-button--icon-only imc-button--transparent-mobile-header imc-button--transparent`}>
              <SvgIcon width={24} height={24} className={`imc-header__logo-svg`} useClassName={`imc-header__logo-svg svg-black-hover`} xlinkHref={`#juniper-on-plan`} />
          </button>
          </div>}

          {!updatedMenu &&<div className="imc-header__utilities  imc-breakpoint-display--hide-tablet">
            <div className="imc-header-subnav">
              <div className="imc-header-subnav__item header-search imc-breakpoint-display--hide-tablet">
                <div className="header-search-wrap">
                  <Placeholder name="imc-header-placeholder" rendering={rendering} />
                </div>
              </div>
              {fields.exhibitorDirectoryLinks.map((item: any, index: number) => {
                const extraClass = index === 0 ? 'imc-breakpoint-display--hide-tablet' : '';
                return <div className={`imc-header-subnav__item ${extraClass}`} key={index}>
                    <Link field={item.fields.link}
                      className="imc-header-subnav__link imc-header-subnav__link--account">
                      <Image field={item.fields.icon.value} />
                      {item.fields.icon.value.svgCode !== undefined &&
                        <div dangerouslySetInnerHTML={{ __html: item.fields.icon.value.svgCode }} />
                      }
                      {item.fields.icon.value.svgCode === undefined &&
                        <Image className="imc-image--responsive" media={item.fields.icon} />
                      }
                      <span className="imc-breakpoint-display--hide-tablet imc-header-subnav__link-text max-two-lines" title={item.fields.plannerDesktop.value || item.fields.link.value.text} >{item.fields.plannerDesktop.value || item.fields.link.value.text}</span>
                      <span className="imc-breakpoint-display--hide-medium-desktop imc-header-subnav__link-text imc-breakpoint-display--hide-tablet max-two-lines" title={item.fields.plannerMobile.value}>{item.fields.plannerMobile.value}</span>
                    </Link>
                  </div>
              })}
              <Placeholder name="imc-mp-header-link" rendering={rendering} />
            </div>
          </div>}

          {updatedMenu &&
            <div className="imc-breakpoint-display--hide-tablet">
              <DesktopNavMenu
                rendering={rendering}
                navMenu={navMenu}
              />
            </div>
          }

          {updatedMenu &&
            <div className={`imc-breakpoint-display--hide-tablet imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-basis-grow imc-content--display-flex-justify-right`}>
              <div className={`imc-content--full-width imc-content--center`} style={{maxWidth: '150px'}}>
                <DropdownMenu
                    wrapperClass={`imc-content--display-flex imc-content--display-flex-justify-center`}
                    buttonClassName={`imc-button--search imc-button--transparent-mobile-header imc-button--transparent`}
                    key={`top-nav-plan`}
                    clickHandler={(event?: any, status?: boolean) => {
                      toggleSearchDrawer(event, status ? status : true);
                    }}
                    escapeHandler={(event?: any, status?: boolean) => {
                      toggleSearchDrawer(event, status ? status : false);
                    }}
                    label={``}
                    chevron={false}
                    icon={`juniper-search-v3`}
                    color={`svg-black svg-black-hover`}
                    isDrawer={false}
                    openState={isSearchDrawerOpen}
                    width={24}
                    height={24}
                    foriegnContent={true}
                    previousFocusOnExit={true}
                >
                  <></>
                </DropdownMenu>
                {isEditorActive() && <Placeholder name="imc-header-placeholder" rendering={rendering} />}
              </div>
            </div>
          }

          <ErrorMessage></ErrorMessage>

        </div>
      </div>
      {updatedMenu && <div id={'desktop-menu'} className={`desktop-menu imc-content--fixed imc-content--full-width imc-breakpoint-display--hide-tablet`} style={{top: `${desktopNavTop}px`}}></div>}
      {(updatedMenu || isEditorActive()) && <Placeholder name="jss-top-header-bottom" rendering={rendering} handleSearch={true} hideMenu={true} searchProps={searchProps} searchPlaceholders={searchPlaceholders}></Placeholder>}
      {updatedMenu && <MobileNavMenu
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        navMenu={navMenu}
      />}

      {updatedMenu &&
        <div className="imc-breakpoint-display--hide-tablet">
          <SearchDrawer
            isDrawerOpen={isSearchDrawerOpen}
            toggleDrawer={toggleSearchDrawer}
            title={navMenu.title}
            rendering={rendering}
            searchPlaceholders={searchPlaceholders}
          />
        </div>
      }
      <div className={`imc-modal--overlay-scrollbar`}></div>
    </>;
  }else{
    return (
      <div className="imc-header__container imc-header__mobileAppPadding imc-header__borderBottom imc-header__sticky imc-header__whiteBackground">
        <div className="imc-header__logo imc-header__mobileAppHeader">
          <a href="/app/start-page" className="imc-content--block" data-xpath="header.logo" title={fields.logo.value.alt}>
            {fields.logo.value.svgCode !== undefined &&
              <div dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
            }
            {fields.logo.value.svgCode === undefined &&
              <Image  media={fields.logo.value} />
            }
          </a>
        </div>
        <Placeholder name="imc-mp-header-link" rendering={rendering} isMobileApp={fields.isMobileApp}></Placeholder>
      </div>
    );
  }  
};

export default withSitecoreContext()(TopHeaderComponent);
