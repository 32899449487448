import React, { useEffect } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'broadstreet-zone': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                'zone-id'?: number;
            };
        }
    }
}

interface BroadstreetAdStaticProps {
    fields: {
        networkId: {
            value: number;
        };
        zoneId: {
            value: number;
        };
    };
}

const BroadstreetAdStatic: React.FC<BroadstreetAdStaticProps> = ({ fields }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.broadstreetads.com/init-2.min.js";
        script.async = true;

        script.onload = () => {
            if (typeof window !== 'undefined' && 'broadstreet' in window) {
                (window as any).broadstreet.watch({
                    networkId: fields.networkId.value,
                });
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [fields.networkId.value]);

    return (
        <>
            {(fields?.zoneId?.value) && <broadstreet-zone zone-id={fields.zoneId.value}></broadstreet-zone>}
        </>
    );
};

export default BroadstreetAdStatic;
