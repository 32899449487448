import React, {PureComponent} from 'react';
import Moment from 'react-moment';

import Link from '../Link';

import { CardTags } from 'modules/cardtags';
import { SearchCard } from 'modules/searchcard';

import { updateSearchUrl } from '../../utils/tagupdate';
import { withSitecoreRouter } from 'utils/withRouter';
import { getDictionaryValue } from 'utils/dictionary/index';
import { formatConsistentDate } from 'utils/dates';


class ArticleCardComponent extends PureComponent {
    /**
     * @method contructor
     * @description Lifecycle method
     * @param {object} props incoming props
     */
    constructor(props) {
        super(props);
        const {
            URL,
            destinationUrl,
            ImageSrc,
            mainImage,
            Title,
            title,
            PublishDate,
            publishDate,
            Description,
            teaserText,
            editorialType,
            Tags,
            tags,
        } = props;

        this.state = {
            link: destinationUrl || URL || '',
            imageSrc: this.getImageSrc(ImageSrc, mainImage),
            title: title || Title || '',
            publishDate: publishDate || PublishDate || '',
            description: teaserText || Description || '',
            articleType: editorialType || '',
            keys: tags || Tags || [],
        };

        this.renderCardTags = this.renderCardTags.bind(this);
        this.tagClick = this.tagClick.bind(this);
        this.updateCardValues = this.updateCardValues.bind(this);
        this.getImageSrc = this.getImageSrc.bind(this);
        this.updateCardValues();
    }

    /**
     * Tag click event
     * @returns {JSX} Card tags
     */
    tagClick(event) {
        const {match, router, filterKey, tagKey} = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateSearchUrl(tag, router, match, filterKey, tagKey);
    }

    componentDidMount() {
        this.updateCardValues();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.updateCardValues();
        }
    }

    updateCardValues() {
        const {
            search,
            URL,
            ImageSrc,
            mainImage,
            Title,
            Tags,
            publishDate,
            teaserText,
            destinationUrl,
            PublishDate,
            Description,
            title,
            tags,
            editorialType,
            keys = []
        } = this.props;

        const imageSrc = this.getImageSrc(ImageSrc, mainImage);
        this.setState({

            link: search ? destinationUrl : URL,
            imageSrc: imageSrc,
            title: search ? title : Title,
            publishDate: search ? publishDate : PublishDate,
            description: search ? teaserText : Description,
            articleType: editorialType,
            keys: [ ...(Tags || tags || keys)],
        });

    }

    getImageSrc(imageSrc, mainImage) {
        return imageSrc ? imageSrc.replace('/-/media', '/-/jssmedia').replace('/sitecore/shell', '')
            : mainImage ? mainImage.replace('/-/media', '/-/jssmedia').replace('/sitecore/shell', '')
            : ``;
    }

    /**
     * Render Card Tags
     * @returns {JSX.Element} Card tags
     */
    renderCardTags() {
        const {link, tags} = this.state;
        return (
            <CardTags
                className=""
                id=""
                tags={this.props.tags}
                destinationUrl={link}
                clickEvent={this.tagClick}
                maxTagsToDisplay={2}
            />
        );
    }

    render() {
        const {extrasClass} = this.props;
        const {link, title, publishDate, imageSrc, teaserText, description, mainImage, editorialType, destinationUrl} = this.props;
        // Article Page API returns different field names than Article Search API
        const {URL, Title, PublishDate, ImageSrc, Description} = this.props;

        const props = {
            search: true,
            ...this.props,
            smallImage: mainImage || imageSrc || ImageSrc,
            startDateTime: PublishDate || publishDate,
            endDateTime: PublishDate || publishDate,
            formattedDate: formatConsistentDate(PublishDate || publishDate),
            formattedEndDate: formatConsistentDate(PublishDate || publishDate),
            time: {
                startTime: "12:00 PM",
                endTime: "03:00 PM" 
            },
            eventType: editorialType,
            title: title || Title,
            description: description || Description,
            teaserText: teaserText || description || Description,
            destinationUrl: destinationUrl || URL || link,
            onlyShowStartDate: true,
        };
        return (
            <SearchCard {...props} router={false} />
        );
    }
}

ArticleCardComponent.displayName = 'ArticleCardComponent';

export default withSitecoreRouter(ArticleCardComponent);
