import React, { useState, useRef, useEffect, ReactNode, useLayoutEffect } from 'react';
import { padContent, unPadContent } from 'utils/scrollhelpers';
import SvgIcon from 'modules/svgicon';
import {withSitecoreRouter} from "../../utils/withRouter";

interface DropdownMenuProps {
  children: ReactNode;
  label: string;
  buttonClassName?: string;
  contentClassName?: string;
  mobilelabel?: string;
  chevron?: boolean;
  icon?: string;
  color?: string;
  notifications?: number;
  router: any;
  isDrawer?: boolean;
  loading?: boolean;
  hidden?: boolean;
  isCollapsible?: boolean;
  preventFocus?: boolean;
  clickHandler?: (event: any, status: boolean) => void;
  escapeHandler?: (event: any, status: boolean, forceClear?: boolean) => void;
  openState?: boolean;
  initialState?: boolean;
  width?: string|number;
  height?: string|number;
  rightIcon?: boolean;
  wrapperClass?: string;
  collapseRef?: React.RefObject<HTMLElement>;
  menuRef?: React.RefObject<HTMLElement>;
  foriegnContent?: boolean;
  previousFocusOnExit?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  closeOnScroll?: boolean;
  closeOnBlur?: boolean;
  leaveContent?: boolean;
  stayOpenOnEscape?: boolean
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({
    label,
    children,
    buttonClassName='',
    contentClassName='',
    mobilelabel=null,
    chevron=true,
    icon=null,
    color=null,
    notifications=null,
    router: { location=null },
    isDrawer=null,
    loading=false,
    hidden=false,
    collapseRef=null,
    menuRef=null,
    isCollapsible=null,
    clickHandler=null,
    escapeHandler=null,
    foriegnContent=false,
    openState=undefined,
    initialState=false,
    width=16,
    height=16,
    rightIcon=false,
    wrapperClass='top-nav-dropdown',
    previousFocusOnExit=false,
    buttonRef = useRef<HTMLButtonElement>(null),
    preventFocus=false,
    closeOnScroll=true,
    closeOnBlur=true,
    leaveContent=false,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);
    const [pathname, setInitialPathname] = useState(location.pathname);

    const isOpenRef = useRef(isOpen);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const prevFocusedElementRef = useRef<HTMLElement | null>(null);


    const noPointerEvents: React.CSSProperties = {
        pointerEvents: 'none'
    };

    // reset isOpen state if optional openState prop changes
    useEffect(() => {
        if (openState !== undefined && openState !== isOpenRef.current) {
            setIsOpen(openState);
            isOpenRef.current = openState;
        }
      }, [openState]);

    const toggleMenu = (event: any, closeMenu: boolean=false) => {
        const newIsOpen = closeMenu ? false : !isOpenRef.current;
        if (escapeHandler && (event?.key === 'Escape' || closeMenu)) {
            escapeHandler(event, newIsOpen);
        } else if (clickHandler) {
            clickHandler(event, newIsOpen);
        }
        if (isCollapsible) {
            if (true || event?.key !== 'Escape' || event?.key !== 'Tab') {
                setIsOpen(newIsOpen);
                isOpenRef.current = newIsOpen;
            }
        } else {
            const focusedElement = document.activeElement as HTMLElement;
            if (focusedElement && focusedElement !== document.body) {
                prevFocusedElementRef.current = focusedElement;
            } else {
                prevFocusedElementRef.current = buttonRef.current;
            }
            setIsOpen(newIsOpen);
            isOpenRef.current = newIsOpen;
        }
    };

    const handleClickEvent = (event: MouseEvent, isMenuOpen: boolean) => {
        if (wrapperRef.current && isMenuOpen) {
            if (!wrapperRef.current.contains(event.target as Node)) {
                if (!closeOnBlur) return;
                if (!isCollapsible && !isDrawer) {
                    toggleMenu(event, true);
                } else if ((collapseRef?.current && collapseRef.current.contains(event.target as Node) || menuRef?.current && menuRef.current.contains(event.target as Node)) && event?.target && ('ariaExpanded' in event?.target && event.target['ariaExpanded'] !== null)) {
                    toggleMenu(event, false);
                } else if (isCollapsible && buttonRef.current && (buttonRef.current.contains(event.target as Node) || buttonRef.current == event.target)) {
                    toggleMenu(event, false);
                } else if (preventFocus) {
                    if (escapeHandler) escapeHandler(event, true, true);
                }
            } else if (!isCollapsible && buttonRef.current && buttonRef.current.contains(event.target as Node)) {
                // toggleMenu(event, true);
            } else if (isCollapsible && buttonRef.current && (buttonRef.current.contains(event.target as Node) || buttonRef.current == event.target)) {
                toggleMenu(event, false);
            }
        } else if (isCollapsible && buttonRef.current && buttonRef.current == event.target) {
            toggleMenu(event, isMenuOpen);
        }
    };

    const handleKeyDownEvent = (event: globalThis.KeyboardEvent, open: boolean) => {
        if (event.key === 'Escape' && open && !leaveContent) {
            toggleMenu(event, true);
        } else if (event.key === 'Enter' && isCollapsible){
            if (open) {
                let focusedElement = document.activeElement;
                if (focusedElement == buttonRef.current) {
                    toggleMenu(event, !open);
                }
            } else {
                let focusedElement = document.activeElement;
                if (focusedElement == buttonRef.current) {
                    toggleMenu(event, open);
                }
            }
        }
    };

    const handleScrollEvent = (event: Event) => {
        if (!isDrawer && isOpenRef.current && closeOnScroll) toggleMenu(event, false);
    };

    const handleFocusEvent = (event: FocusEvent, open: boolean|null) => {
        const focusedElement = event.target as HTMLElement;
        if (!open && focusedElement && !preventFocus) {
            if ((wrapperRef.current && wrapperRef.current.contains(focusedElement)) || (buttonRef.current && buttonRef.current == focusedElement)) {
                toggleMenu(event, false);
                event.stopPropagation();
            };
        }
    };

    const handleBlurEvent = (event: FocusEvent, open: boolean|null) => {
        if (open && closeOnBlur) {
            if (!(menuRef?.current && menuRef.current.contains(event.relatedTarget as Node))) toggleMenu(event, true);
        }
    };

    // Add event listener to handle clicks outside the dropdown menu
    useLayoutEffect(() => {
        if (!foriegnContent) {
            // define event listeners with isOpen updated from state
            const handleClick = (event: MouseEvent) => handleClickEvent(event, isOpenRef.current);
            const handleScroll = (event: Event) => handleScrollEvent(event);
            const handleKeyDown = (event: globalThis.KeyboardEvent) => handleKeyDownEvent(event, isOpenRef.current);
            const handleFocus = (event: FocusEvent) => handleFocusEvent(event, isOpenRef.current);
            const handleBlur = (event: FocusEvent) => handleBlurEvent(event, isOpenRef.current);

            // add eventListeners
            document.addEventListener('scroll', handleScroll);
            document.addEventListener('keyup', handleKeyDown);
            if (wrapperRef.current && isCollapsible) {
                document.addEventListener('mousedown', handleClick);
                wrapperRef.current.addEventListener('focusin', handleFocus);
                wrapperRef.current.addEventListener('focusout', handleBlur);
            } else {
                document.addEventListener('click', handleClick);
            }
            if (menuRef?.current && isCollapsible) {
                menuRef.current.addEventListener('focusin', handleFocus);
                menuRef.current.addEventListener('focusout', handleBlur);
            }

            // remove eventListeners
            return () => {
                document.removeEventListener('scroll', handleScroll);
                document.removeEventListener('keyup', handleKeyDown);
                if (wrapperRef.current && isCollapsible) {
                    document.removeEventListener('mousedown', handleClick);
                    wrapperRef.current.removeEventListener('focusin', handleFocus);
                    wrapperRef.current.removeEventListener('focusout', handleBlur);
                } else {
                    document.removeEventListener('click', handleClick);
                }
                if (menuRef?.current && isCollapsible) {
                    menuRef.current.removeEventListener('focusin', handleFocus);
                    menuRef.current.removeEventListener('focusout', handleBlur);
                }
            };

        } else {

            // if content is outside this component, only add keydown event listener
            const handleKeyDown = (event: globalThis.KeyboardEvent) => handleKeyDownEvent(event, isOpenRef.current);
            document.addEventListener('keydown', handleKeyDown);
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };

        }
    }, []);

    useEffect(() => {
        if (isDrawer) {
            if (isOpen) {
                padContent(window.innerWidth - document.documentElement.getBoundingClientRect().width);
                if (dropDownRef?.current) dropDownRef.current.style.marginRight = `0px`;
            } else {
                unPadContent();
                if (dropDownRef?.current) dropDownRef.current.style.marginRight = `-${window.innerWidth - document.documentElement.getBoundingClientRect().width}px`;
            }
        }
        if (!isCollapsible && previousFocusOnExit) {
            if (isOpen) {
            } else {
                if (prevFocusedElementRef.current) prevFocusedElementRef.current.focus();
            }
        }
    }, [isOpen, openState]);

    // Listen for route changes
    useEffect(() => {
        if (location.pathname !== pathname) {
            toggleMenu(undefined, true); // Close the dropdown on route change
        }
    }, [location.pathname]);

    return (
        <><div className={`${wrapperClass}${isOpen ? ' active' : ''}${isDrawer ? ' top-nav-drawer right' : ''}`} ref={wrapperRef}>
        {!hidden && <button
            ref={buttonRef}
            className={`imc-button ${buttonClassName} ${icon} ${(chevron) ? 'top-nav-chevron' : ''}${isOpen ? ' active' : ''}`}
            aria-expanded={(isOpen) ? true : false}
            aria-haspopup="true"
            onClick={(event) => {
                if (!isCollapsible) toggleMenu(event);
            }}
        >
            {(notifications && notifications > 0 && icon) && 
                <div className="top-nav-notification" style={(isCollapsible) ? noPointerEvents : {}}>{notifications}</div>
            }
            {(icon && !rightIcon) &&
                <SvgIcon style={(isCollapsible) ? noPointerEvents : {}} width={(typeof width === 'number') ? width : 16} height={(typeof width === 'number') ? width : 16} className={`${loading ? `` : ``} top-nav-user-icon ${icon} ${(color) ? color : ''}`} useClassName={`${(color) ? color : ''}`} xlinkHref={`#${(loading) ? icon : icon}`} />
            }
            {label &&
                <span style={(isCollapsible) ? noPointerEvents : {}} className={`${(contentClassName) ? contentClassName : ''}`}>
                    {mobilelabel != null && <span className={`imc-breakpoint-display--mobile-only`}>{mobilelabel}</span>}
                    <span className={`${mobilelabel != null ? 'imc-breakpoint-display--hide-mobile' : ''}`} style={(isCollapsible) ? noPointerEvents : {}}>{label}</span>
                </span>
            }
            {(icon && rightIcon) &&
                <SvgIcon style={(isCollapsible) ? noPointerEvents : {}} width={(typeof width === 'number') ? width : 16} height={(typeof width === 'number') ? width : 16} className={`${loading ? `` : ``} top-nav-user-icon ${icon} ${(color) ? color : ''}`} useClassName={`${(color) ? color : ''}`} xlinkHref={`#${(loading) ? icon : icon}`} />
            }
        </button>}
        {(isOpen || isDrawer || isCollapsible || leaveContent) && React.Children.map(children, child =>
            React.cloneElement(child as React.ReactElement<any>, {
                setIsOpen,
                dropDownRef,
                'aria-expanded': isOpen,
                'aria-hidden': !isOpen,
            }))} {/* Pass setIsOpen to children */}
        </div>
        {isDrawer && <div onClick={(event) => {toggleMenu(event, true)}} className={`top-nav-overlay imc-modal--overlay ${isOpen ? 'active' : ''}`}></div>}
        </>
    );
};

export default withSitecoreRouter(DropdownMenu);
