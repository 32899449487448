import React from 'react';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { ShowroomInterface, LineInterface, PlanInferface } from '../reducers/interface';
import { getLocalDate } from 'utils/dates';

interface ToastNotificationProps {
    Item: any;
    plan: any;
    line?: LineInterface;
    type: string;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({Item, plan, type, line}) => {
    let start: Date = getLocalDate(plan.market.from);  
    let end: Date = getLocalDate(plan.market.to);
    return (
        <div className="top-nav-toast-title">
            <div className="imc-content--flex imc-content--gap-large">
                <h2 className="imc-type--title-4 imc-content--nowrap"><span className="andmore-bold">{plan.market.displayName}</span></h2>
                <div className={`imc-type--title-5 imc-content--single-line`}>
                    <span className="">
                        <Moment format={`${`MMM D`}`}>{start}</Moment> - <Moment format={`${`${(start.getMonth() != end.getMonth()) ? 'MMM ' : ''}D`}`}>{end}</Moment>
                    </span>
                </div>
            </div>
            <div className="top-nav-toast-room imc-content--flex imc-content--gap-large imc-content--display-flex-space-between">
                <div>
                    <div className={`imc-type--title-4`}>{(line?.name) ? line.name : Item.showroomName}</div>
                    {(line?.name) && <div className={`top-nav-gray`}>{`Shown By ${Item.showroomName}`}</div>}
                </div>
                <div className={`Toastify__description andmore imc-content--bold imc-button--radius-3`}>
                    {type == 'adding' && <span>Adding to plan...</span>}
                    {type == 'added' && <span>Added to Plan!</span>}
                    {type == 'removing' && <span>Removing from plan...</span>}
                    {type == 'removed' && <span>Removed from Plan!</span>}
                    {type == 'error' && <span>Failed to Add!</span>}
                    {type == 'removalerror' && <span>Failed to Remove!</span>}
                </div>
            </div>
        </div>
    );
};

export const showToastPromise = (
    promise: Promise<string>,
    Item: ShowroomInterface,
    plan: PlanInferface,
    successType: string,
    errorType: string,
    line?: LineInterface
) => {
    const successMessage = <ToastNotification Item={Item} plan={plan} line={line} type={successType} />;
    const errorMessage = <ToastNotification Item={Item} plan={plan} line={line} type={errorType} />;
    toast.promise<string>(
        promise, {
            success: {
                render({}) {
                    return <>{successMessage}</>;
                },
                icon: ({}) => {
                    return <svg
                        className={`top-nav-user-icon svg-green}`}
                        width="20"
                        height="20"
                        role="img"
                        aria-labelledby="clear-search-title clear-search-desc"
                    >
                        <title id="clear-search-title">{`success`}</title>
                        <desc id="clear-search-desc">{`success`}</desc>
                        <use
                            className={`svg-green`}
                            xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#juniper-on-plan`}
                        />
                    </svg>
                },
                autoClose: 2000,
                className: "toast-success",
            },
            error: {
                render({}) {
                    return <>{errorMessage}</>;
                },
                icon: ({}) => {
                    return <svg
                        className={`top-nav-user-icon svg-orange`}
                        width="20"
                        height="20"
                        role="img"
                        aria-labelledby="clear-search-title clear-search-desc"
                    >
                        <title id="clear-search-title">{`error`}</title>
                        <desc id="clear-search-desc">{`error`}</desc>
                        <use
                            className={`svg-orange`}
                            xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#juniper-warning`}
                        />
                    </svg>
                },
                autoClose: false,
                className: "toast-error",
            },
        }
    );
};
