import React, { Component } from 'react';
import Pagination from 'modules/search/Pagination.jsx';
import PropTypes from 'prop-types';
import { Placeholder, isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import qs from 'query-string';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';
import { createAppState } from "modules/redux-app-state";
import { ExhibitorCard } from 'modules/exhibitorcard';
import SearchResultsColumn from 'modules/searchresultswrapper/components/SearchResultsColumn';
import {
    LINE_IDENTIFIER,
    getExhibitorProductsURL,
    getShopzioUrl,
} from "utils/exhibitor";
// action imports
import * as dataActions from "./actions/dataActions";
import * as multiSelectActions from '../Type-Ahead-Search/actions/multiSelectActions';
import ImcDataLayer from "utils/datalayer";
// reducers
import digitalShowroomExhibitorReducer from "./reducers";

const digitalShowroomLineState = createAppState();

/**
 * TabMyLists component
 * todo: create
 * @constructor
 */

class DigitalShowroomLinesList extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            data: {},
            multiSelectMode: false,
        };
        this.multiSelect = [];
        this.renderItems = this.renderItems.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.multiSelectChange = this.multiSelectChange.bind(this);
        this.goToShop = this.goToShop.bind(this);
        digitalShowroomLineState.reducerRegistry.register({
            digitalShowroomExhibitorReducer
        });
    }
    componentDidUpdate(lastProps) {
        if (lastProps.addToListSuccess !== this.props.addToListSuccess) {
            if (this.props.addToListSuccess) {
                this.props.multiSelectActions.multiSelectReset();
            }
        }
        if (lastProps.selectedItems !== this.props.selectedItems) {
            this.multiSelect.checked = this.props.selectedItems.some(e => e.id === this.multiSelect.id);
        }
    }


    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }



    getActionItem(line) {
        return {
            itemId: line.lineId,
            itemType: this.capitalizeFirstLetter(LINE_IDENTIFIER),
            label: line.lineName,
        }
    }


    multiSelectChange(line) {
        const { multiSelectActions } = this.props;
        const actionItem = this.getActionItem(line);
        multiSelectActions.itemChanged({ actionItem: actionItem, selected: this.multiSelect[line.lineId].checked, id: `SearchItemThumb${line.lineId}` });
    }

    /**
      * @method renderPagination
      * @returns {*} Pagination Component
      */
    renderPagination() {
        const { lineCount } = this.props?.tabData;
        const size = this?.props?.currentTab?.size?.value || 12;

        if (lineCount > size) {
            const integerPart = parseInt(lineCount / size)
            if (integerPart < (lineCount / size)) {
                this.totalPages = integerPart + 1;
            } else {
                this.totalPages = integerPart;
            }
        } else {
            this.totalPages = 1;
        }
        return (
            <Pagination
                totalPages={this.totalPages}
                paginationContainerId={`searchContainer`}
            />
        );
    }

    /**
      * @method renderPagination
      * @returns {*} Pagination Component
      */
    getTotalPages() {
        const { lineCount } = this.props?.tabData;
        const size = this?.props?.currentTab?.size?.value || 12;

        if (lineCount > size) {
            const integerPart = parseInt(lineCount / size)
            if (integerPart < (lineCount / size)) {
                this.totalPages = integerPart + 1;
            } else {
                this.totalPages = integerPart;
            }
        } else {
            this.totalPages = 1;
        }
        return this.totalPages;
    }

    goToShop(shopId) {
        
        const shopzioUrl = getShopzioUrl(shopId);
        if (typeof window !== 'undefined') {
            window.location = shopzioUrl;
            ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', shopzioUrl);
        }
    }
    /**
     * @method renderItems
     * @returns {*} Components
     */
    renderItems() {
        const {
            tabData,
            exhibitor,
        } = this.props;
        const { lines, exhibitId, multiSelectMode } = tabData;
        const locations = exhibitor.activeLeases;
        const exhibitorId = exhibitId;
        const detailUrl = getExhibitorProductsURL(exhibitId);
        if (lines === undefined || lines.length === 0) {
            return this.renderNoResults();
        }
        return !!lines && Array.isArray(lines) && lines.map(
            (line, index) => (
                <ExhibitorCard
                    exhibitorId={exhibitorId}
                    exhibitorName={line.exhibitorName}
                    key={`product-${index}`}
                    title={line.lineName}
                    products={line.products || []}
                    type={'Line'}
                    lineId={line.lineId}
                    lineName={line.lineName}
                    productCount={line.productCount}
                    showroomLocation={locations}
                    allowFavoriting={false}
                    fallbackImagePath={process.env.PUBLIC_URL + '/assets/images/150-150.png'}
                    exhibitor={line}
                />
            ));
    }

    renderNoResults() {
        if (typeof window === 'undefined' || (typeof window != 'undefined' && !window.Sitecore) || !("currentTab" in this.props))
            return (
                <></>
            );

        const { tabData } = this.props;
        const { exhibitId } = tabData;
        const label = "Click to view Lines";
        let query = '';
        if (typeof window != 'undefined' && !window.Sitecore) {
            query = qs.parse(window.location.search).q;
        }
        const type = this.props.currentTab.type.value
        return (
            <div className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
            imc-searchresults`}>
                <div className="imc-vr--large imc-content imc-stacked">
                    There are no matching search results
                    {(query !== '' && query !== undefined) ?
                        <span> for <strong>&quot;{query}&quot;</strong></span> : null
                    }
                </div>
                <div className="imc-vr--large imc-content imc-stacked">
                    <a
                        href={`/exhibitor/${exhibitId}/${type.toLowerCase()}`}
                        className="imc-link imc-link--alt-darkred imc-link--caret-after"
                    >
                        {label}
                    </a>
                </div>
            </div>
        );
    }
    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { tabData, sitecoreContext } = this.props;
        if (tabData === undefined && isEditorActive()) {
            return <h1 className="alarm">Error. Use this component inside a Digital Tab.</h1>;
        }
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        return (
            <SearchResultsColumn rendering={this.props.rendering} renderResults={this.renderItems} numberOfPages={this.getTotalPages()} isPageEditing={isEditing} searchPlaceholder={'imc-search-bar'} />
        );
    }



}
const defaultProps = {
    pageSize: 6,
};
const propTypes = {
    tagKey: PropTypes.string,
};
DigitalShowroomLinesList.propTypes = propTypes;
DigitalShowroomLinesList.defaultProps = defaultProps;

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
*/
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
        multiSelectActions: bindActionCreators({ ...multiSelectActions }, dispatch),

    };
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    let _props = {};
    if (state.digitalShowroomInfoReducer) {
        Object.assign(_props, {
            exhibitor: state.digitalShowroomInfoReducer,
        });
    }
    if (state.multiSelectReducer) {
        _props.selectedItems = state.multiSelectReducer.selectedItems;
        _props.multiSelectMode = state.multiSelectReducer.multiSelectMode;
    }
    return _props;
}

export default withSitecoreContext()(
    withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomLinesList))
);