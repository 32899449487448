// component dependencies
// Module dependencies
import { Loading } from 'modules/loading';
import PropTypes from 'prop-types';
// Library dependencies
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ImcDataLayer from 'utils/datalayer';
import { getDictionaryValue } from 'utils/dictionary';
import UserAgent from 'utils/useragent';
import UserUtility from 'utils/userutility';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../../AppGlobals';
// Local dependencies
import { submitFormData } from '../service/contactExhibitorService';






const contactExhibitorEndpointKey = 'contactExhibitorUrl';

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{actionUrl: *, accountId: *, labels: *, showSuccess: *, showModal: *, showError: *, closeModal: *,
 * companyName: *, email: *, userName: *, exhibitorId: *}}
 */
const propTypes = {
    actionUrl: PropTypes.string, // Action form submit url
    // organizationId: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    labels: PropTypes.object, // labels from Resource Bundle
    showSuccess: PropTypes.bool, // Flag to show the success message
    showModal: PropTypes.bool, // Flag to show the success message
    showError: PropTypes.bool, // Flag to show the error message
    closeModal: PropTypes.func, // Need to used for updating parent state
    companyName: PropTypes.string,
    email: PropTypes.string,
    userName: PropTypes.string,
    exhibitorId: PropTypes.any,
};

/**
 * @property defaultProps
 * @type {{actionUrl: string, accountId: string, labels: {formValidations: {msgSubmitSuccessMessage: string,
 * maxMessageLengthError: string, messageBoxEmptyError: string, selectCheckboxError: string},
 * formMessage: {formMessage3: string, formSuccessMessage1: string}, exhibitor: {yourName: string, yourCompany: string,
 * yourEmail: string, postalCode: string, message: string, seeking: string, submit: string,
 * contactExhibitorCTALabel: string}}, showSuccess: boolean, showModal: boolean, showError: boolean,
 * closeModal: defaultProps.closeModal, exhibitor: object}}
 */
const defaultProps = {
    actionUrl: '',
    // organizationId: '',
    accountId: '',
    exhibitor: {},
    labels: {
        formValidations: {
            msgSubmitSuccessMessage: 'Your message was sent successfully.',
            maxMessageLengthError: 'Message cannot be more than 140 Characters',
            messageBoxEmptyError: 'Please enter the message.',
            postalEmptyError: 'Please enter your zip/postal code.',
            selectCheckboxError: 'Please select atleast 1 or more checkboxes',
        },
        formMessage: {
            formMessage3: 'Error submitting the form',
            formSuccessMessage1: 'Form submitted successfully, thank you.',
        },
        exhibitor: {
            yourName: 'YOUR NAME:',
            yourCompany: 'YOUR COMPANY:',
            yourEmail: 'YOUR EMAIL:',
            postalcode: 'POSTAL CODE:',
            message: 'MESSAGE:',
            seeking: 'I\'M SEEKING:',
            submit: 'Send',
            contactExhibitorCTALabel: 'Contact Exhibitor',
        },
    },
    showSuccess: false,
    showModal: false,
    showError: false,
    closeModal: () => { },
    companyName: '',
    email: '',
    userName: '',
    productCategories: [],
    exhibitorId: '',
};

/**
 * Component for displaying a Optin modal module and maintaining its state
 */
export default class ContactExhibitorModal extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            showModal: false,
            loading: false,
            showMessage: false,
            showSuccess: false,
            message: '',
            postalCode: '',
        };

        this._apiKey = contactExhibitorEndpointKey;
        this.renderSuccess = this.renderSuccess.bind(this);
        this.resetComponent = this.resetComponent.bind(this);
        this.fixSocialMediaLink = this.fixSocialMediaLink.bind(this);
        
    }

    /**
     * Fetches data required from API before load
     */
    componentWillMount() {
        const { showSuccess, showModal } = this.props;
        this.setState({ showSuccess, showModal });
    }

    /**
     * Reset
     */
    resetComponent() {
        this.setState({
            showMessage: false,
            showSuccess: false,
            showModal: false,
        });
        this.props.closeModal();
    }
    /*
    * Login Clock
    */
    loginClick() {
        UserUtility.redirectToLogin();
    }


    /**
     * @method renderIframe
     * @description When the component has mounted will create and append dummy iframe in IE to avoid overlaying issue
     * @returns {Element} Rendered content element
     */
    renderIframe() {
        const { showModal } = this.props;
        if (showModal && UserAgent.isIE()) {
            return (
                <iframe
                    className="imc-contactexhibitormodal--iframe"
                    src=""
                    frameBorder="0"
                    title="dummyIframe"
                />
            );
        }
        return null;
    }

    renderSuccess() {
        const { showSuccess } = this.state;
        return (
            <div className='imc-content imc-content--center imc-vertical-align imc-content--full-width'>
                <p>{(showSuccess) ? getDictionaryValue('submitSuccessMessage', 'Exhibitor successfully contacted') :
                    getDictionaryValue('submitErrorMessage', 'Error trying to contact the exhibitor. Please try again.')}
                </p>
            </div>
        );
    }

    getStateAbbreviationCode(contactInfo) {

        const states = Array.isArray(contactInfo.countries) && contactInfo.countries.some(c => c.code == contactInfo.country) ? contactInfo.countries.find(c => c.code == contactInfo.country).state : [];

        if (contactInfo == null || contactInfo.countries == null || states == null) {
            return '';
        } else {
            var returnCode = states.some(s => s.code == contactInfo.state) ? states.find(s => s.code == contactInfo.state).stateAbbreviation : contactInfo.state;
            if (returnCode == null) {
                return '';
            } else {
                return returnCode;
            }
        }
    }
    getCountryName(contactInfo) {
        var returnCountry = Array.isArray(contactInfo.countries) && contactInfo.countries.some(c => c.code == contactInfo.country) ? contactInfo.countries.find(c => c.code == contactInfo.country).displayValue : '';
        if (contactInfo == null || returnCountry == null) {
            return '';
        } else {
            return returnCountry;
        }
    }

    nvl(value, nvlValue) {
        if (value == null) {
            return nvlValue;
        } else {
            return value;
        }
    }

    nullOrEmpty(inputString) {

        if (inputString == null) {
            return true;
        }

        if (inputString === null) {
            return true;
        }

        if (inputString == '') {
            return true;
        }

        // if not a string
        if ((inputString + '').trim() == '') {
            return true;
        }

        if (inputString === '') {
            return true;
        }

        // if not a string
        if ((inputString + '').trim() === '') {
            return true;
        }

        return false;
    }

    produceCityLine(city, stateAbbreviation, zip) {

        var returnLine = '';

        if (!this.nullOrEmpty(city)) {
            returnLine = returnLine + city;
        }

        if (!this.nullOrEmpty(city) && (!this.nullOrEmpty(stateAbbreviation) || !this.nullOrEmpty(zip))) {
            returnLine = returnLine + ', ';
        }

        if (!this.nullOrEmpty(stateAbbreviation) && !this.nullOrEmpty(zip)) {
            returnLine = returnLine + stateAbbreviation + ' ' + zip;
        } else if (!this.nullOrEmpty(stateAbbreviation) && this.nullOrEmpty(zip)) {
            returnLine = returnLine + stateAbbreviation;
        } else if (this.nullOrEmpty(stateAbbreviation) && !this.nullOrEmpty(zip)) {
            returnLine = returnLine + zip;
        }
        return returnLine;
    }

    renderSocialIcon(url, key, defaultTitle) {
        return <div className="imc-content--inline-block imc-margin--right--smallmedium">
            <a href={this.fixSocialMediaLink(url,key)} target="_blank">
                <svg width="25" height="25">
                    <title>{getDictionaryValue(key, defaultTitle)}</title>
                    <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref={`#social-light-${key}`}
                    />
                </svg>
            </a>
        </div>;
    }
    hasSocialIcons(socialMedia) {
        return socialMedia.facebookUrl || socialMedia.pintrestUrl || socialMedia.instagramUrl ||
            socialMedia.twitterUrl || socialMedia.youtubeUrl || socialMedia.snapchatUrl;
    }

    renderSocialIcons(socialMedia) {
        return socialMedia ? (<>

            {socialMedia.facebookUrl &&
                this.renderSocialIcon(socialMedia.facebookUrl, 'facebook', 'Faceboook')
            }
            {socialMedia.pintrestUrl &&
                this.renderSocialIcon(socialMedia.pintrestUrl, 'pinterest', 'Pinterest')
            }
            {socialMedia.instagramUrl &&
                this.renderSocialIcon(socialMedia.instagramUrl, 'instagram', 'Instagram')
            }
            {socialMedia.twitterUrl &&
                this.renderSocialIcon(socialMedia.twitterUrl, 'twitter', 'Twitter')
            }
            {socialMedia.youtubeUrl &&
                this.renderSocialIcon(socialMedia.youtubeUrl, 'youtube', 'YouTube')
            }
            {socialMedia.snapchatUrl &&
                this.renderSocialIcon(socialMedia.snapchatUrl, 'snapchat', 'Snapchat')
            }
        </>) : <></>;
    }

    fixSocialMediaLink(url, key)
    {
        switch(key)
        {
            case 'facebook':
                return url.indexOf("facebook.com")>-1? (url.indexOf("//")>-1?url:"//"+url):"//facebook.com/"+url;
        
            case 'pinterest':
                return url.indexOf("pinterest.com")>-1?(url.indexOf("//")>-1?url:"//"+url):"//pinterest.com/"+url;
            
            case 'instagram':
                return url.indexOf("instagram.com")>-1?(url.indexOf("//")>-1?url:"//"+url):"//instagram.com/"+url;
            
            case 'twitter':
                return url.indexOf("twitter.com")>-1?(url.indexOf("//")>-1?url:"//"+url):"//twitter.com/"+url;
            
            case 'youtube':
                return url.indexOf("youtube.com")>-1?(url.indexOf("//")>-1?url:"//"+url):"//youtube.com/"+url;
            
            case 'snapchat':
                return url.indexOf("snapchat.com")>-1?(url.indexOf("//")>-1?url:"//"+url):"//snapchat.com/"+url;

        }
        return "";

    }

    /**
     * Renders the Modal
     * @returns {XML} Search JSX wrapper
     */
    render() {
        const { showModal, exhibitor } = this.props;
        const { loading } = this.state;

        return (
            <div>
                <ReactModal
                    isOpen={showModal}
                    overlayClassName="imc-modal--overlay"
                    className={`imc-modal--box imc-modal--box-height-auto imc-contactexhibitormodal imc-modal--exhibitor-contact`}
                    ariaHideApp={false}
                    onRequestClose={this.resetComponent}
                >
                    <div className="imc-modal--content imc-contactexhibitormodal--position">
                        <h2
                            className="imc-heading--box-modal"
                        >
                            {`${getDictionaryValue('contact', 'Contact')} ${exhibitor.companyName}`}
                        </h2>
                        <div className="imc-contactexhibitormodal--body">
                            <div className="imc-gallery imc-gallery imc-gallery--1-1 imc-gallery--row-reverse imc-content--full-width">
                                <div className="imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right imc-content--full-height-desktop imc-content--display-flex">
                                    {exhibitor.contactInfo &&
                                        <div className="imc-contactexhibitormodal--body-panel  imc-content--full-width">
                                            {exhibitor.contactInfo.primaryPhoneNo &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('phone', 'Phone')}
                                                    </span>
                                                    <p className="imc-type--title-3-ui" >
                                                        {exhibitor.contactInfo.primaryPhoneNo}
                                                    </p>
                                                </div>
                                            }
                                            {exhibitor.contactInfo.showroomPhoneNo &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('showroomPhone', 'Showroom Phone')}
                                                    </span>
                                                    <p className="imc-type--title-3-ui" >
                                                        {exhibitor.contactInfo.showroomPhoneNo}
                                                    </p>
                                                </div>
                                            }
                                             {exhibitor.contactInfo.tollFreePhoneNo &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('tollfreePhone', 'Toll Free Phone')}
                                                    </span>
                                                    <p className="imc-type--title-3-ui" >
                                                        {exhibitor.contactInfo.tollFreePhoneNo}
                                                    </p>
                                                </div>
                                            }
                                            {exhibitor.contactInfo.faxNumber &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('faxnumber', 'FAX Phone Number')}
                                                    </span>
                                                    <p className="imc-type--title-3-ui" >
                                                        {exhibitor.contactInfo.faxNumber}
                                                    </p>
                                                </div>
                                            }
                                            {exhibitor.contactInfo.smsNumber &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('smsnumber', 'SMS Phone Number')}
                                                    </span>
                                                    <p className="imc-type--title-3-ui" >
                                                        {exhibitor.contactInfo.smsNumber}
                                                    </p>
                                                </div>
                                            }
                                           
                                            {(exhibitor.contactInfo.directoryContactFirstName || exhibitor.contactInfo.directoryContactLastName || exhibitor.contactInfo.directoryContactEmail) &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('showroomContact', 'Showroom Contact')}
                                                    </span>
                                                    {(exhibitor.contactInfo.directoryContactFirstName || exhibitor.contactInfo.directoryContactLastName) &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {`${exhibitor.contactInfo.directoryContactFirstName} ${exhibitor.contactInfo.directoryContactLastName}`}
                                                        </p>
                                                    }
                                                    {exhibitor.contactInfo.directoryContactEmail &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {exhibitor.contactInfo.directoryContactEmail}
                                                        </p>
                                                    }
                                                </div>
                                            }
                                            {(exhibitor.contactInfo.companyEmail1 || exhibitor.contactInfo.companyEmail2 ) &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('companyEmail', 'Company Email')}
                                                    </span>
                                                   
                                                    {exhibitor.contactInfo.companyEmail1 &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {exhibitor.contactInfo.companyEmail1}
                                                        </p>
                                                    }
                                                     {exhibitor.contactInfo.companyEmail2 &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {exhibitor.contactInfo.companyEmail2}
                                                        </p>
                                                    }
                                                </div>
                                            }
                                            {exhibitor.contactInfo.address1 &&
                                                <div className="imc-vr--xlarge">
                                                    <span className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('mailingAddress', 'Mailing Address')}
                                                    </span>
                                                    {exhibitor.contactInfo.address1 &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {exhibitor.contactInfo.address1}
                                                        </p>
                                                    }
                                                    {exhibitor.contactInfo.address2 &&
                                                        <p className="imc-type--title-3-ui" >
                                                            {exhibitor.contactInfo.address2}
                                                        </p>
                                                    }
                                                    <p className="imc-type--title-3-ui" >
                                                        {`${this.produceCityLine(exhibitor.contactInfo.city, this.getStateAbbreviationCode(exhibitor.contactInfo), this.nvl(exhibitor.contactInfo.zip, ''))}`}
                                                    </p>
                                                    <p className="imc-type--title-3-ui" >
                                                        {this.getCountryName(exhibitor.contactInfo)}
                                                    </p>
                                                </div>
                                            }
                                            {this.hasSocialIcons(exhibitor.socialMedia) &&
                                                <div className="imc-vr--xlarge">
                                                    <p className="imc-type--title-3-ui imc-type--bold imc-vr--small" >
                                                        {getDictionaryValue('followUs', 'Follow Us')}
                                                    </p>
                                                    {this.renderSocialIcons(exhibitor.socialMedia)}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="imc-modal--close imc-button--modal-close imc-button--round" onClick={this.resetComponent}>
                        {getDictionaryValue('close', 'Close')}</button>
                    <Loading showLoading={loading} />
                    {this.renderIframe()}
                </ReactModal>
            </div>
        );
    }
}

ContactExhibitorModal.propTypes = propTypes;
ContactExhibitorModal.defaultProps = defaultProps;

