import React from 'react';
import {newComponent, richTextRendering} from '../../utils/buildRenderingParameters'
import RichTextComponent from '../Rich-Text-Component';
import TableWrapper from './components/Wrapper';

const TableComponent = (props: any) => {

    const model = props.fields.data.model;

    const renderCell = (cell: any, r: number, c: number, body: boolean) => {
        const El = body ? 'td' : 'th';
        const colspan = cell?.colspan?.value ? parseInt(cell.colspan.value) : 1;

        let cellStyles: Record<string, string> = {};
        if (cell?.backgroundColor?.value) cellStyles['backgroundColor'] = cell.backgroundColor.value;
        if (cell?.minWidth?.value) cellStyles['minWidth'] = cell.minWidth.value;

        if (cell?.richText?.value) {
            const rendering = newComponent(richTextRendering());
            rendering.fields.content.value = cell.richText.value;
            return (
                <El key={`cell-${r}-${c}`} style={cellStyles} colSpan={colspan}>
                    <RichTextComponent {...rendering} rendering={rendering} />
                </El>
            );
        } else {
            return null;
        }
    };

    const renderRowCells = (rowCells: any, r: number, body: boolean) => {
        const El = body ? 'td' : 'th';
        const cells = rowCells.value.split(/\r?\n/);
        return cells.map((cell: any, c: number) => {
            return (
                <El key={`cell-${r}-${c}`}>
                    {cell}
                </El>
            );
        });
    };

    const renderRows = (rows: any, body: boolean = true) => {
        const Wrapper = body ? 'tbody' : 'thead';
        
        return (<Wrapper key={`wrapper-${Wrapper}`}>
            {rows.map((row: any, r: number) => {
                const hasCells = row.cells && row.cells.length > 0;
                const hasRowCells = row?.rowCells?.value;

                let rowStyles: Record<string, string> = {};
                if (row?.backgroundColor?.value) rowStyles['backgroundColor'] = row.backgroundColor.value;
                if (row?.textColor?.value) rowStyles['color'] = row.textColor.value;

                return (
                    <tr key={`row-${r}`} style={rowStyles}>
                        {(hasCells) && row.cells.map((cell: any, c: number) => {
                            return renderCell(cell, r, c, body);
                        })}
                        {(!hasCells && hasRowCells) && 
                            renderRowCells(row.rowCells, r, body)
                        }
                    </tr>
                );
            })}
        </Wrapper>);
    }

    let rows: any[] = [];
    let headers: any[] = [];

    model.rows.forEach((row: any, r: number) => {
        let body = true;

        if (r == 0) {
            body = false;
        }
        if (!(row.cells && row.cells.length > 0) && !(row.rowCells && row.rowCells.value)) {
            return;
        }
        if (body) {
            rows.push(row);
        } else {
            headers.push(row);
        }
    });

    return (
        <TableWrapper model={model} tableClass={`imc-table`} outerWrapperClass={`imc-table--outer-wrapper`} wrapperClass={`imc-table--wrapper`}>
            {renderRows(headers, false)}
            {renderRows(rows)}
        </TableWrapper>
    );
};

export default TableComponent;
