import React from 'react';
import FlexGrid from '../Flex-Grid';
import ContentSectionComponent from '../Content-Section-Component';
import RichTextComponent from '../Rich-Text-Component';
import ImcCta from '../Imc-cta';
import StandaloneImageComponent from '../Standalone-Image-Component';
import StandaloneVideoComponent from '../Standalone-Video-Component';
import {contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'

// ---------------------- //
// define props interface //
// ---------------------- //
interface BasicSingleColumnComponentProps {
    fields?: any;
};
// ------------------------ //

// ------------------------ //
// Basic Two Column Section //
// ------------------------ //
const BasicSingleColumnComponent: React.FC<BasicSingleColumnComponentProps> = ({fields}) => {
    let headline = richTextRendering();
    let copy = richTextRendering();
    let section = contentSectionComponentRendering();
    let imageSection = contentSectionComponentRendering();
    let primaryCTA = imcCtaRendering();
    let secondaryCTA = imcCtaRendering();
    let image = standaloneImageRendering();
    let textSection = contentColumnSectionRendering(fields, false);
    let ctaSection = contentSectionComponentRendering();
    let backgroundColor = (fields?.backgroundColor?.value) ? fields?.backgroundColor?.value : '';
    let accentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'neutral-medium-light';
    let secondaryAccentColor = (fields?.accentColor?.value) ? fields?.accentColor?.value : 'secondary-light';
    let primaryImageClasses = fields.imageWrapper.map((f?: any) => {
        f.fields.className.value = f.fields.className.value.replace("{{backgroundColor}}",`${backgroundColor}`).replace("{{accentColor}}",`${accentColor}`).replace("{{secondaryAccentColor}}",`${secondaryAccentColor}`);
        return f;
    });
    // ---------------------- //
    // add classes to wrapper //
    // ---------------------- //
    section.fields.backgroundColor.value += `${backgroundColor}`;
    if (fields.extraClasses?.length > 0) {
        let extraClass = "" + section.fields.extraClass.fields.className.value;
        section.fields.extraClass.fields.className.value += fields.extraClasses.map((c?: any) => {
            if (c.fields?.className?.value) {
                return c.fields.className.value;
            }
        }).join(" ");
    }
    section.fields.styles = {};
    ctaSection.fields.styles = {};
    if (fields?.backgroundOverride?.value) {
        section.fields.styles["backgroundColor"] = `${fields.backgroundOverride.value}`;
        ctaSection.fields.styles["backgroundColor"] = `${fields.backgroundOverride.value}`;
    }
    // -------------------- //
    // build  image  column //
    // -------------------- //
    image.fields['image'] = fields.image;
    imageSection.placeholders['imc-jss-content-section'].push(image);
    // ---------------------- //
    // build wrapping section //
    // ---------------------- //
    if (section?.fields?.extraClass?.fields?.className?.value?.includes("bordered-sections") || section?.fields?.extraClass?.fields?.className?.value?.includes("thick-border-radius")) {
        if (backgroundColor == '') {
            backgroundColor = " imc-section--basic-white";
        }
    }
    if (section?.fields?.extraClass?.fields?.className?.value?.includes("bordered-sections")) {
        section.fields.backgroundColor.value += ` bordered-sections bordered-sections-${'neutral-medium-light'} `;
    }
    if (section?.fields?.extraClass?.fields?.className?.value?.includes("thick-border-radius")) {
        section.fields.backgroundColor.value += ` thick-border-radius `;
        primaryImageClasses.push({
            fields: {
                className: {
                    value: " inner-center "
                }
            }
        });
    }
    textSection.fields.paddingTop.value = 'xlarge';
    textSection.fields.paddingBottom.value = 'xlarge';
    if (backgroundColor) {
        ctaSection.fields.backgroundColor.value += section.fields.backgroundColor.value;
        textSection.fields.paddingTop.value = 'xlarge';
        textSection.fields.paddingBottom.value = 'xlarge';
        textSection.fields.paddingLeft.value = 'xlarge';
        textSection.fields.paddingRight.value = 'xlarge';
        ctaSection.fields.paddingTop.value = 'none';
        ctaSection.fields.paddingBottom.value = 'xlarge';
        ctaSection.fields.paddingLeft.value = 'xlarge';
        ctaSection.fields.paddingRight.value = 'xlarge';
    } else {
        if (!fields.image.value.src) {
            textSection.fields.paddingTop.value = 'none';
        }
        if (!fields.primaryCTA?.value?.href && !fields.secondaryCTA?.value?.href) {
            textSection.fields.paddingBottom.value = 'none';
        }
    }
    primaryImageClasses.forEach((c?: any) => {
        if (c?.fields?.className?.value.includes("am-wrapper") && c?.fields?.className?.value.includes("am-pare-offset")) {
            section.fields.backgroundColor.value += ` one-col-pare am-wrapper am-pare-offset`;
        }
    });
    if (fields.image.value.src) {
        section.placeholders['imc-jss-content-section'].push(returnMediaSection(fields.image, fields.videoFile, primaryImageClasses, fields.primaryCTA, fields.linkImage, fields.secondaryCTA, fields.linkImageSecondary, fields?.mute));
    }
    if (fields?.headline?.value || fields?.body?.value) {
        section.placeholders['imc-jss-content-section'].push(textSection);
    } else {
        ctaSection.fields.paddingTop.value = "xlarge";
    }
    // ---------------------- //
    // build wrapping section //
    // ---------------------- //
    if (fields.primaryCTA?.value?.href) {
        primaryCTA.fields.link = fields.primaryCTA;
        if (fields.primaryCTAclass?.length > 0) {
            primaryCTA.fields.ctaClass.fields.className.value += fields.primaryCTAclass.map((c?: any) => {
                if (c.fields?.className?.value) {
                    return c.fields.className.value;
                }
            }).join(" ");
        }
        ctaSection.placeholders['imc-jss-content-section'].push(primaryCTA);
    }
    if (fields.secondaryCTA?.value?.href) {
        secondaryCTA.fields.link = fields.secondaryCTA;
        secondaryCTA.fields.ctaClass.fields.className.value = 'imc-button imc-button--primary-inverted';
        if (fields.secondaryCTAclass?.length > 0) {
            secondaryCTA.fields.ctaClass.fields.className.value += fields.secondaryCTAclass.map((c?: any) => {
                if (c.fields?.className?.value) {
                    return c.fields.className.value;
                }
            }).join(" ");
        }
        ctaSection.placeholders['imc-jss-content-section'].push(secondaryCTA);
    }
    // ---------------------- //
    if (fields.primaryCTA?.value?.href || fields.secondaryCTA?.value?.href) {
        ctaSection.fields.extraClass.fields.className.value += " imc-section--cta-wrapper";
        ctaSection.fields.backgroundColor.value = section.fields.backgroundColor.value;
        ctaSection.fields.extraClass.fields.className.value += " " + textSection.fields.extraClass.fields.className.value;
        ctaSection.fields.extraClass.fields.className.value += " " + section.fields.extraClass.fields.className.value;
        return (
            <>
                <ContentSectionComponent {...section} rendering={section} />
                <ContentSectionComponent {...ctaSection} rendering={ctaSection} />
            </>
        );
    } else {
        return (
            <ContentSectionComponent {...section} rendering={section} />
        );

    }
};
// ------------------------ //

export default BasicSingleColumnComponent;