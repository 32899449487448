import React from 'react';
import Vendor from './Vendor';
 
const Floor = (floor: any, buildingName: string, buildingIndex: number, floorIndex: number) => {
    return (
        <div key={`floor-${buildingIndex}-${floorIndex}`} className={`imc-grid-column-${Math.min(3, floor.vendors.length)} imc-grid-column-${Math.min(2, floor.vendors.length)}--tablet imc-grid-column-${Math.min(1, floor.vendors.length)}--mobile imc-content--display-flex imc-content--display-flex-column`}>
            <div key={`floor-${floorIndex}`} className="section-wrapper">
                <section className="imc-section--padded-top-none imc-section--padded-bottom-medium imc-section--padded-left-medium imc-section--padded-right-medium">
                    <p>
                        <span className="imc-type--title-3"><span className="imc-type--color-neutral-heaviest">{floor.title.value}</span></span>
                    </p>
                </section>
            </div>
            <div className={`imc-content--flex-grow`}>
                <div className={`imc-grid imc-content--full-height imc-content--display-flex-gap-xlarge imc-grid-${Math.min(3, floor.vendors.length)} imc-grid-${Math.min(2, floor.vendors.length)}--tablet imc-grid-1--mobile`}>
                    {floor.vendors.map((vendor: any, vendorIndex: number) => {
                        return Vendor(vendor, buildingName, buildingIndex, floor.title.value, floorIndex, vendorIndex);
                    })}
                </div>
            </div>
        </div>
    )
};

export default Floor;