import { SET_PRODUCT_FILTERS, SET_PRODUCT_FILTERS_ERR, SET_FILTER_KEY } from '../actions/actionTypes';

const initialState = {
    filters: null,
    filtersCount: 0,
    mobileExpanded: false,
};

function preprocessFilters(filters) {
    let _filters = {};
    if (filters)
        Object.keys(filters).forEach((item) => {
            _filters[filters[item].name] = filters[item];
        });
    return _filters;
}

/**
 * Reducer for exhibitor information
 * @param {object} state State of the exhibitor information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_FILTERS:
            const _filters = preprocessFilters(action.filters);
            return {
                ...state,
                filters: _filters,
                filterErr: null,
            };
        case SET_PRODUCT_FILTERS_ERR:
            return {
                ...state,
                filters: initialState.filters,
                filterErr: action.status || 404,
            };
        case SET_FILTER_KEY:
            return {
                ...state,
                filterKey: action.filterKey,
            };
        case 'TOGGLE_MENU':
            return { ...state, mobileExpanded: !state.mobileExpanded };
        case 'OPEN_MENU':
            return { ...state, mobileExpanded: true };
        case 'CLOSE_MENU':
            return { ...state, mobileExpanded: false };
        case 'SET_FILTERS_COUNT':
            return { ...state, filtersCount: action.filtersCount };
        default: return state;
    }
}
