import { SwiperSlide } from 'swiper/react';
import React from 'react';
import Event from './models/Event';
import Link from "../Link";


const renderCarouselItem = (event: Event, fields: any) => {
    const startDate = event.formattedDate;
    const endDate = event.formattedEndDate;
    const eventDate = `${startDate}${
        startDate !== endDate ? ` - ${endDate}` : ""
    }`;
    const eventTime = `${event.time.startTime} - ${event.time.endTime}`;

    return (
        <SwiperSlide key={event.id}>
            <div className="event-slide">
                <div className={"carousel-image-section"}>
                    <Link href={event.destinationUrl}>
                        <img
                            src={event.smallImage ? event.smallImage : ""}
                            className="event-card--image"
                            alt={event.title}
                        />
                    </Link>
                </div>
                <div className="event-card--container event-card--info">
                    <div className="event-card--title--container">
                        <Link href={event.destinationUrl}>
                            <p
                                className="event-card--title"
                                data-xpath="featureevents.item.title"
                            >
                                {event.title}
                            </p>
                        </Link>
                    </div>
                    <p>{eventDate}</p>
                    <p>{eventTime}</p>

                    <div className="event-card--bottom-link-section">
                        <Link
                            href={event.destinationUrl}
                            className={"imc-type--body-1-link imc-link--caret-after"}
                            title={fields.moreInfoLabel.value}
                        >
                            {fields.moreInfoLabel.value}
                        </Link>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    );
}

export default renderCarouselItem;
